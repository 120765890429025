.imgBTN { 
    display: none;
    border-radius: 5px;
    line-height: 1.25;
    font-weight: 500;
    padding: 2px 3px;
    font-size: 12px;
    color:var(--text-color);
    background-color: var(--secondary-background);
    border: 1px solid var(--border-color);
    cursor: pointer;
    transition: background-color 0.2s ease, border-color 0.2s ease;
}

.imgBTN:hover { 
    background-color: var(--third-background);
}
 
.imgBTN svg {  
    height: 16px;
    width: 16px;
    margin-right: 3px;
    color: var(--secondary-text-color);
    fill: var(--secondary-text-color);
    transition: color 0.2s ease ;
}


table .imgBTN {
    display: flex;
}

table .imageBox {
    display: none;
}


.imageBox {
    max-width: 90px;
    min-width: 90px;
    max-height: 75px;
    min-height: 75px;  
}

.title {
    position: absolute;
    top: -15px;
    right: 15px;
    background: var(--primary-color);
    border-radius: 16px;
    font-size: 13px;
    font-feature-settings: "case" on;
    color: rgb(255 255 255);
    padding: 0px 10px;
}
 

.imgCar {
    width: 100%;
    height: 75px;
    overflow: hidden;
    border-radius: 0.375rem;
    background-color: var(--secondary-background);
    cursor: pointer;
}

.imgCar img {
    width: 100%;
    height: 75px;
    border-radius: 0.375rem;
    object-fit: cover;
    line-height: 1; 
}

.imgCar svg {
    opacity: 0.5;
    width: 100%;
    height: 75px; 
    fill:var(--secondary-text-color);
    color: var(--secondary-text-color);
}


.modalHead {
    padding: 1.5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
 
.modalHead h3 {
    display: flex;
    align-items: center;
    color: var(--secondary-text-color);
    padding: 0;
    margin: 0;
    font-weight: 300;
    font-size: 1.125rem; 
    font-feature-settings: "case" on; 
}
 
.modalHead h3 span{
    height: 30px;
    width: 30px;
    border-radius: 999px;
    color:var(--muted-text-color);
    background-color:var(--secondary-background);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}
 
.modalHead h3 span svg{
    height: 18px;
    width: 18px; 
}



.threeBox { 
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-right: -0.5rem;
    margin-left: -0.5rem;
}

.col {
    position: relative;
    flex-shrink: 0;
    flex: 0 0 auto;
    width: 33.33333333%;
    max-width: 100%;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}
 

.downBTN {
    border-radius: 5px;
    line-height: 1.25;
    padding: 5px 6px;
    z-index: 3;
    font-feature-settings: "case" on;
    font-size: 12px;
    font-weight: 600;
    right: 20px;
    bottom: 8px;
    position: absolute;
    z-index: 5;
    color: var(--secondary-text-color);
    background-color: #e1e1e1;
    cursor: pointer;
    display: flex;
    transition: background-color 0.2s ease, border-color 0.2s ease;
    align-items: center;
}
 

 

.downBTN svg{
   width: 15px;
   height: 15px;
   margin-right: 5px;
}
 

 

@media (max-width: 1325px) { 
        
    .imageBox {
        display: none;
    }


}