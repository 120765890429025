.removeBtnYes { 
    color: #dc2626;
    background-color: rgb(220 38 38 / 10%);
    border-radius: 99.375rem;
    padding: 8px 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 600;
    font-size: 13px;
    line-height: 1.25;
} 
 
.message {
    font-size: 14px;
    color: var(--text-color);
    line-height: 1.25;
    margin-bottom: 16px;
    margin-top: 10px;
    text-align: center;
}

.buttons { 
    line-height: 1.25;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
  
 

.btnContainerTop { 
    border: 1px solid;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    cursor: pointer;
    align-items: center;
    font-feature-settings: "case" on;
    line-height: 1.25;
    font-weight: 500;
    padding: 0px;
    font-size: 13px;
    overflow: hidden;
    width: 100%;
    color: var(--secondary-text-color);
    border-color: var(--border-color);
}

 
.btnContainerTop span.icon { 
    max-width: 28px;
    max-height: 28px;
    min-width: 28px;
    min-height: 28px;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    display: flex;
    color: var(--secondary-text-color);
    background-color: var(--secondary-background);
}

.btnContainerTop span.icon svg {  
    max-width: 18px;
    max-height: 18px;
    min-width: 18px;
    min-height: 18px; 
}
 
