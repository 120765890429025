.bgLight {
    background-color: var(--layout-background);
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.Page {  
    margin: 20px 0 60px 0;
    width: 100%;
    max-width: 488px;
    padding: 100px 75px;
    background: #fff;
    border-radius: 8px; 
    border: 1px solid var(--border-color);

}

.Boxed .Page {  
    max-width: 440px; 
}

.Boxed .Box {  
    background-color: #fff; 
    padding: 1.5rem 2.5rem;  
    border: 1px solid #E2E8F0; 
    border-radius: 0.3rem; 
    min-height: 325px; 
}

.h1 { 
    font-size: 22px;
    font-feature-settings: "case" on;
    font-weight: normal;
    text-align: center;
    margin-top: 55px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.textSm {
    margin-bottom: 30px;
    text-align: center;
    margin-top: 0;
    color: rgb(148 163 184);
    font-size: .825rem;
}

.Head {
    padding-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
}

.Head svg{
    height: 35px;
}

.link {
    font-size: 14px;
}

.mt1 {
    margin-top: 20px;
}

.LogoContainer {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
}

.LogoContainer svg{
    height: 47px;
    width: 64px;
    margin-right: 10px;
}
.LogoContainer span{
    background: #fff;
    border-radius: 0;
    color: var(--primary-color);
    font-size: 18px;
    font-weight: 900;
    line-height: 1;
    margin-left: -67px;
    margin-top: 5px;
    z-index: 2;
    padding: 0 0 0 1px;
    position: relative;
} 


.LogoContainer::after  {
    z-index: 1;
    content: "";
    height: 27px;
    left: 4px;
    position: absolute;
    top: 13px;
    width: 57px;
    background: #fff;
}
 
.AddBtn { 
    color: var(--text-color);
    border: 1px solid var(--border-color);
    background-color: #fff;
    border-radius: 5px;
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 13px;
    font-weight: 600; 
    font-feature-settings: "case" on;
}


.btnPrimary {
    width: 100%;
    color: #fff;
    border: 1px solid transparent;
    background-color: var(--primary-color);
    border-radius: 5px;
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 13px;
    font-weight: 700; 
    font-feature-settings: "case" on;
}

h3 {
    display: flex;
    align-items: center;
    color: var(--text-color); 
    padding: 0;
    margin: 0;
    font-weight: 700;
    font-size: 1.125rem;
    font-feature-settings: "case" on; 
} 



.groupInput {
    margin-bottom: 12px;
    position: relative;
}

 
.input {  
    line-height: 1.25rem;
    color: var(--text-color);
    font-size: 13px;
    padding: 0.5rem; 
    border: 1px solid var(--border-color);
    border-radius: 5px;
    width: 100%;
    height: 35px;
    background-color: rgb(255 255 255); 
    outline-color: transparent;
    outline-offset: 2px;
    outline-style: dashed;
    outline-width: 1px; 
    transition-duration: .3s; 
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}

.input::placeholder {
    color:  var(--muted-text-color);
    position: relative;
}
 
.groupInput .groupInputIcon {
    position: relative;
}

.groupInput .groupInputIcon .input { 
    padding-inline-start: 28px;
    padding-inline-end: 10px;
}

.groupInput .groupInputIcon .crmIcon {
    position: absolute;
    top: 0;
    color: var(--muted-text-color);
    display: flex; 
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 28px;
    transition-duration: .3s; 
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}
 
.groupInput .groupInputIcon .crmIcon svg { 
    display: inline-block;
    vertical-align: middle;
    height: 17px;
    width: 17px;
    color: var(--secondary-text-color);
    fill: var(--secondary-text-color); 
}
  
.inp2col { 
    display: flex;
    gap: 10px;
}

.inp2col > * {
    width: 100%;
}
  

.errorInput {
    border-color: rgb(225, 29, 72); 
}

.errorMsg {
    color: rgb(225, 29, 72); 
    font-size: 10px;
    position: absolute;
    bottom: -3px;
    background: #fff;
    right: 11px;
    line-height: 1; 
}
 

@media screen and (max-width: 535px) {
    .bgLight {
       padding: 20px;
    }
        
    .Page {  
        margin: 0;
        width: 100%;
        max-width: 100%;
        padding: 60px 30px;
        background: #fff;
        border-radius: 8px;
    }
}