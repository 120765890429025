
.btn { 
    border-radius: 5px;
    line-height: 1.25;
    font-weight: 500;
    padding: 2px 3px;
    display: flex;
    font-size: 12px;
    color: var(--text-color);
    background-color: var(--secondary-background);
    cursor: pointer;
    border: 1px solid var(--border-color);
    transition: background-color 0.2s ease, border-color 0.2s ease;
    align-items: center;
}

.btn:hover { 
    background-color:  var(--third-background);
}
 
.btn svg {  
    height: 16px;
    width: 16px;
    margin-right: 3px;
    color: var(--secondary-text-color);
    fill: var(--secondary-text-color); 
    transition: color 0.2s ease ;
}
 
 

.modalHead {
    padding: 1.5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
 
.modalHead h3 {
    display: flex;
    align-items: center;
    color:  var(--text-color); 
    padding: 0;
    margin: 0;
    font-weight: 300;
    font-size: 1.125rem; 
    font-feature-settings: "case" on; 
}
 
.modalHead h3 span{
    height: 30px;
    width: 30px;
    border-radius: 999px;
    color:var(--secondary-text-color);
    background-color: var(--secondary-background);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}
 
.modalHead h3 span svg{
    height: 18px;
    width: 18px; 
}


.colorpreview {
    display: flex;  
    align-items: center;  
}
   
.colorpreviewrectangle {
    width: 30px; 
    height: 20px;  
    margin-right: 10px;  
}
   
.colorpreview p {
    margin: 0;  
    font-size: 14px; 
}


.customtextarea {
    line-height: 1.25rem;
    color: var(--text-color);
    font-size: 13px;
    font-weight: 500;
    padding: 0.5rem;
    border: 1px solid var(--border-color);
    border-radius: 10px;
    width: 100%; 
    background-color: rgb(255 255 255);
    outline-color: transparent;
    outline-offset: 2px;
    outline-style: dashed;
    outline-width: 1px;
    transition-duration: .3s;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}



.btnPrimary {
    margin-top: 20px;
    display: block;
    text-align: center;
    padding:  7px 20px;
    cursor: pointer;
    line-height: 1.25rem;
    color: #fff;
    font-size: .875rem; 
    font-weight: 600;
    border: none;
    border-radius: 0.3rem;
    width: 100%;
    max-width: 200px;
    /* height: 2.5rem; */
    font-feature-settings: "case" on;
    background-color: var(--primary-color);
    transition-duration: .3s;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}

.RRDSD {
    margin-top: 20px;
    display: block;

}


.btntbl {
    padding: 0 10px !important;
    width: 25px !important; 
}

.id {
    width: 25px !important;
    text-align: center !important;
}
 
.btns {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
}
 

.form {
    margin-top: 25px;
    width: 50%;
    margin-left:auto;
}

.colorSelector {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 25px;
}

.removeBtn { 
    
    border-radius: 5px;
    line-height: 1.25;
    font-weight: 400;
    padding: 2px 3px;
    font-size: 12px;
    color: var(--text-color);
    display: flex;
    align-items: center;
    border: 1px solid var(--border-color);
    justify-content: center;
    background-color: var(--secondary-background);
    cursor: pointer;
    transition: background-color 0.2s ease, border-color 0.2s ease;
}

.removeBtn:hover {
    background-color: var(--third-background);
}
 
 
.removeBtn svg {  
    height: 16px;
    width: 16px;
    margin-right: 3px;
    color: var(--secondary-text-color);
    fill:var(--secondary-text-color);
    transition: color 0.2s ease ;
}