.head{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    align-content: center;
    gap: 10px;
    align-items: stretch;
    justify-content: space-between;
}

 
.headMain {
    display: flex;
     gap: 0.5rem; 
    position: relative;
    align-items: center;
    padding-top: 20px; 
    justify-content: space-between;
}

.headMain h1 { 
    color: var(--text-color);
    line-height: 1;
    font-weight: bold;
    font-size: 18px; 
    font-feature-settings: "case" on;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    margin-top: 0;
    padding-top: 0;
}
 
.switcher {  
    color: var(--secondary-text-color);
    background-color: rgb(255 255 255);
    border: 1px solid var(--border-color);
    border-radius: 5px;
    padding: 0 5px;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
}
 
.switcher svg {  
    width: 20px;
    height: 20px; 
}



.pad0 {
    padding: 0 !important;
}
 
.btntbl {
    padding: 0 10px !important;
    width: 25px !important; 
}

.id {
    width: 25px !important;
    text-align: center !important;
}
 
.btns {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
}


.headflex {
    display: flex;
    align-items: center;
}

.currenttab {
    width: 160px;
 }


 .exelBtn {  
    color: var(--secondary-text-color);
    border: 1px solid var(--border-color);
    background-color: #fff;
    border-radius: 5px;
    padding: 0 10px 0 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

 
.exelBtn span.icon {  
    max-width: 28px;
    max-height: 28px;
    min-width: 28px;
    min-height: 28px;
    align-items: center;
    justify-content: center;
    margin-right: 2px; 
    display: flex;
}

.exelBtn span.icon svg {  
    max-width: 18px;
    max-height: 18px;
    min-width: 18px;
    min-height: 18px; 
}


.table table tbody tr td b{     
    font-weight: 700;
    color: #585858; 
 }

 
.table table thead tr th {     
    font-weight: 600;
    font-size: 13px;
    color: var(--text-color);
    padding: 10px 7px;
    line-height: 1.3;
    background-color: #fff8d9 !important; 
    font-feature-settings: "case" off;
    border-right: 1px solid;
    border-bottom: 1px solid;
    border-color: var(--border-color-table) ;
    white-space: unset; 
}


.table table thead tr th input {     
    font-feature-settings: "case" off;
    font-weight: 600;
    font-size: 13px;
    color: var(--text-color);
    padding: 5px 7px;
    line-height: 1;
    background-color: transparent;  
    border: transparent;   
    padding: 0.5rem;
    width: 100%;
    min-width: 100px;
}

.table table thead tr th input::placeholder {      
    color: var(--text-color);
    font-feature-settings: "case" off;
}

@media (max-width: 1025px) {

    .headMain h1 {  
        font-size: 16px;  
    }
}


@media (max-width: 700px) {
   
    .exelBtn { 
        padding: 0px; 
    }
  
    .exelBtn span.icon {   
        margin-right: 0;
    }
    
    .exelBtn span.text { 
    display: none;
    }

}

@media (max-width: 426px) {
   
    .exelBtn { 
        display: none;
    } 

}

.AddBtn { 
    border: 1px solid;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    cursor: pointer;
    align-items: center;
    font-feature-settings: "case" on;
    line-height: 1.25;
    font-weight: 500;
    padding: 0px 7px 0px 0px;
    font-size: 13px;
    overflow: hidden;
    color: var(--secondary-text-color);
    border-color: var(--border-color);
}

 
.AddBtn span.icon { 
    max-width: 28px;
    max-height: 28px;
    min-width: 28px;
    min-height: 28px;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
    display: flex;
    color: var(--secondary-text-color);
    background-color: var(--secondary-background);
}

.AddBtn span.icon svg {  
    max-width: 18px;
    max-height: 18px;
    min-width: 18px;
    min-height: 18px; 
}

@media (max-width: 700px) { 
    .AddBtn { 
        padding: 0px; 
    }

    .AddBtn span.icon {  
        background-color: #fff;
        margin-right: 0;
    }
    
    .AddBtn span.text { 
    display: none;
    }
 
}
