
.modalHead {
  padding: 1.5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modalHead h3 {
  display: flex;
  align-items: center;
  color: rgb(100 116 139);
  padding: 0;
  margin: 0;
  font-weight: 300;
  font-size: 1.125rem; 
  font-feature-settings: "case" on; 
}

.modalHead h3 span{
  height: 30px;
  width: 30px;
  border-radius: 999px;
  color:rgb(100 116 139);
  background-color: rgb(241 245 249);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

.modalHead h3 span svg{
  height: 18px;
  width: 18px; 
}




 


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
  }
  
  .close-button {
    float: right;
    cursor: pointer;
  }






.id {
  width: 25px !important;
  text-align: center !important;
}