.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    color: var(--text-color)  ;
    display: inline-block  ;
    width: 1.5rem  ;
    line-height: 1.5rem  ;
    text-align: center  ;
    margin: 0.166rem  ;
    font-weight: 500  ;
}


.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
    border-color: var(--muted-text-color)  ;
    border-style: solid  ;
    border-width: 2px 2px 0 0  ;
    content: ""  ;
    display: block  ;
    height: 9px  ;
    position: absolute  ;
    top: 6px  ;
    width: 9px  ;
    transition: all 0.2s ease-in-out  ;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before { 
    border-bottom-color: #e2e8f0  ;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    border-bottom-color: var(--secondary-background)  ;
}


.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
    border-radius: 99px  ;
    background-color: #f0f0f0  ;
}


.react-datepicker {
    font-size: 0.8rem  ;
    background-color: #fff  ;
    color: #000  ;
    border: 1px solid var(--border-color)  ;
    border-radius: 8px  ;
    display: inline-block  ;
    position: relative  ;
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.2);
}


.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    border-radius: 99px  ;
    background-color: var(--third-background);
    color: var(--text-color)  ;
}

.react-datepicker__header {
    text-align: center  ;
    background-color: var(--secondary-background);
    border-bottom: 1px solid var(--border-color)  ;
    border-top-left-radius: 8px  ;
    padding: 12px 15px  ;
    position: relative  ;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    margin-top: 0 ; 
    font-feature-settings: "case" on;
    color: var(--text-color)  ;
    font-weight: bold;
    font-size: 0.944rem  ;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    border-radius: 100px;
    background-color: rgb(60 60 60) !important;
    color: #fff !important;
    font-weight: 700;
}


.react-datepicker__navigation:hover *::before {
    border-color: var(--text-color) ;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before { 
    border-top-color:  rgb(226 232 240); 
}


.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before { 
    border-bottom-color:  var(--border-color); 
 
}
 
.react-datepicker__month {
    margin: 15px 10px;
    text-align: center;
}

.react-datepicker__close-icon::after {
    cursor: pointer;
    background-color: var(--third-background);  
    height: 16px;
    width: 16px;
    padding: 2px;   
    display: table-cell;
    vertical-align: middle;
    content: "";
    background-repeat: no-repeat;
    background-position: center;
    background-size: 14px; 
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-tj5bde-Svg"><path fill="rgb(68 68 68 / 70%)" d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>');

}

.react-datepicker__navigation {
  
    top: 10px; 
}


.react-datepicker__day--outside-month {
    color: var(--muted-text-color);
}

.react-datepicker__day--disabled,
 .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
    cursor: default;
    color: #ccc;
}