.AddBtnCargo {  
    display: flex;
    cursor: pointer;
    align-items: center; 
    overflow: hidden;
    color: #fff;
    text-align: center;
    justify-content: center;
    gap: 5px;
}

.AddBtnCargo > span{ 
    border: 1px solid var(--secondary-text-color);
    background-color: #fff;
    border-radius: 99px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    font-size: 12px;
    text-align: center;
    height: 17px;
    width: 17px;
    color: var(--secondary-text-color);
}

.AddBtnCargo > span svg{ 
    min-height: 12px;
    min-width: 12px;
    max-height: 12px;
    max-width: 12px;
}
 
.AddBtnCargo > span.removeBtn {
    color: #dc2626;
    border-color: #dc2626;
    padding: auto;
}
 
.AddBtnCargo > span.removeBtn svg {
    min-height: 12px;
    min-width: 12px;
    max-height: 12px;
    max-width: 12px;
}

.groupInput { 
    flex: 1;
    position: relative;  
    box-sizing: border-box;
}

.groupInput > * {
    width: 100%;
}

  
.input {   
    line-height: 1.25rem;
    color: var(--text-color);
    font-size: 13px;
    padding: 0 5px 0 5px; 
    border: 1px solid var(--border-color);
    border-radius: 5px;
    width: 100%;
    height: 25px;
    background-color: rgb(255 255 255); 
    outline-color: transparent;
    outline-offset: 2px;
    outline-style: dashed;
    outline-width: 1px; 
    transition-duration: .3s; 
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}

.input::placeholder {
    color: var(--placeholder-text-color);
    position: relative;
}

.input[disabled] {
    cursor: not-allowed;
 }

.input:focus-within {  
    outline-color: none ;
}

.groupInput .groupInputIcon {
    position: relative;
}

.groupInput .groupInputIcon .input { 
    padding-inline-start: 28px;
    padding-inline-end: 10px;
}

.groupInput .groupInputIcon .crmIcon {
    position: absolute;
    top: 0;
    color: var(--text-color);
    display: flex; 
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 28px;
    transition-duration: .3s; 
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}

.groupInput .groupInputIcon:focus-within .crmIcon {
    --tw-text-opacity: 1;
    color: var(--text-color); 
}

.groupInput .groupInputIcon .crmIcon svg { 
    display: inline-block;
    vertical-align: middle;
    height: 17px;
    width: 17px;
    color: var(--text-color);
    fill: var(--text-color); 
}
  
.groupInput.dollar .groupInputIcon .input { 
    padding-inline-start: 16px;
    font-weight: bold; 
}

.groupInput.dollar .groupInputIcon .crmIcon {
    color: var(--text-color);
    display: flex;
    width: 17px;
    justify-content: flex-end;
}

  
 

.errorInput .input {
    border-color: rgb(225, 29, 72); 
}
 
.errorInput label {
    color: rgb(225, 29, 72); 
}

.errorMsg {
    color: rgb(225, 29, 72); 
    font-size: 10px;
    position: absolute;
    bottom: -3px;
    background: #fff;
    right: 11px;
    line-height: 1; 
} 

   


.cancelClient {
    position: relative; 
    height: 12px;
    width: 12px;
    border-radius: 99px;
    color: #fff;
    display: flex;
    background-color: #dc2626;
    align-items: center;
    justify-content: center;
    z-index: 99;
    cursor: pointer;
}

.cancelClient svg {
     width: 10px; 
     height: 10px; 
}

 

.groupInput.blue .groupInputIcon .crmIcon svg{
    color: #1254e7;
} 
.groupInput.blue .input{ 
    color: #1254e7;
    font-weight: bold;
    border-color: #1254e7;
    background-color: #f2f4fe;
} 

.groupInput.blue label { 
    color: #1254e7;    
    background-color: #f2f4fe;
} 

 
.searchClient {
    position: absolute;
    z-index: 1;
    background: #fff;
    border: 1px solid rgb(203 213 225);
    padding: 5px 0px;
    border-radius: 5px;
    width: 100%;
    top: 100%;
    left: 1px;
    max-width: 254px;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
    border-color: var(--border-color);
}

.searchClient > div { 
    cursor: pointer;
    padding: 8px 9px;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    line-height: 1;
    color: var(--text-color);
 }

.searchClient > div:last-child {  
    border-color: transparent;
 }

.searchClient > div:hover {   
    background-color: var(--secondary-background);
 }


