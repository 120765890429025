.pagiBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    padding-bottom: 10px;
}

.pagiBox .pagination {
    display: flex; 
}

.pagiBox .pagination li span {
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    background-color: #fff;
    color: var(--secondary-text-color);
    margin: 0 3px;
    border-radius: 0.375rem;
    min-width: 30px;
    height: 30px;
    border: 1px solid ;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-color: var(--border-color);
    padding: 0px 5px;
    transition-duration: .2s;
    transition-property: border-color;
    transition-timing-function: cubic-bezier(.4,0,.2,1);

}

.pagiBox .pagination li span:hover {
    color:  var(--primary-color); 
    border-color: var(--primary-color); 
}

.pagiBox .pagination li.active span {
     background-color: var(--primary-color); 
     color: #fff; 
     border-color: var(--primary-color); 

}

.perPage {
    display: flex;
    align-items: center;
    color: var(--secondary-text-color);
}

.perPage select {
    outline-color: transparent;
    outline-offset: 2px;
    outline-style: dashed;
    outline-width: 1px;
}

.perPage select {  
    display: flex;
    background-color: #fff;
    color: var(--secondary-text-color);
    margin-right: 5px;
    font-weight: 500;
    border-radius: 5px;
    width: 40px;
    height: 30px;
    border: 1px solid;
    border-color: var(--border-color);
    text-align: center;
}

.perPage span {
    margin-left: 5px;
    font-weight: 700;
    color: var(--primary-color);
}

.selectPage { 
    outline-color: transparent;
    outline-offset: 2px;
    outline-style: dashed;
    outline-width: 1px;
}

.selectPage {  
    display: none;
    background-color: #fff;
    color: var(--secondary-text-color);
    margin-right: 5px;
    font-weight: 500;
    border-radius: 5px;
    width: 40px;
    height: 30px;
    border: 1px solid;
    border-color: var(--border-color);
    text-align: center;
}
.childrenPagi {
    padding: 0 3px;
    width: 100%;
    display: flex;
}


@media (max-width: 1168px) {
    .pagiBox .pagination {
        display: none ;
    }

    .selectPage {
        display: block; 
    }

}