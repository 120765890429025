
.editBtn { 
    border-radius: 5px;
    line-height: 1.25;
    font-weight: 500;
    padding: 2px 3px;
    display: flex;
    font-size: 12px;
    color: var(--text-color);
    background-color: var(--secondary-background);
    border: 1px solid var(--border-color);
    cursor: pointer;
    transition: background-color 0.2s ease, border-color 0.2s ease;
    align-items: center;
}

.editBtn:hover { 
    background-color: var(--third-background);
}
 
.editBtn svg {  
    height: 16px;
    width: 16px;
    margin-right: 3px;
    color: var(--secondary-text-color);
    fill: var(--secondary-text-color);
    transition: color 0.2s ease ;
}




.modalHead {
    padding: 1.5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
 
.modalHead h3 {
    display: flex;
    align-items: center;
    color: var(--text-color);
    padding: 0;
    margin: 0;
    font-weight: 600;
    font-size: 18px; 
    font-feature-settings: "case" on; 
}
 
.modalHead h3 span{
    height: 30px;
    width: 30px;
    border-radius: 999px;
    color: var(--secondary-text-color);
    background-color: var(--secondary-background);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}
 
.modalHead h3 span svg{
    height: 18px;
    width: 18px; 
}

  
.input {  
    line-height: 1.25rem;
    color: var(--text-color);
    font-size: 12px;
    padding: 0.5rem; 
    border: 1px solid var(--border-color);
    border-radius: 5px;
    width: 100%;
    height: 35px;
    background-color: rgb(255 255 255); 
    outline-color: transparent;
    outline-offset: 2px;
    outline-style: dashed;
    outline-width: 1px; 
    transition-duration: .3s; 
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}

.input::placeholder {
    color: var(--placeholder-text-color);
    position: relative;
}

.input:focus-within {  
    outline-color: var(--border-color);
} 
 

.inp2col { 
    display: flex;
    gap: 10px;
}

.inp2col > * {
    width: 100%;
}
  
 
.groupInput {
    margin-bottom: 12px;
    position: relative;
}
 

.groupInput .input::placeholder {
    color: var(--placeholder-text-color);
    position: relative;
}

 

.groupInput label { 
    position: absolute;
    font-size: 11px;
    font-weight: 500;
    color: var(--muted-text-color);
    line-height: 1;
    margin-bottom: 4px;
    top: -6px;
    z-index: 1;
    left: 8px;
    padding: 0 3px;
    background: #fff;
    font-weight: 500;

}

 

.groupInput .groupInputIcon {
    position: relative;
}

.groupInput .groupInputIcon .input { 
    padding-inline-start: 28px;
    padding-inline-end: 10px;
}

.groupInput .groupInputIcon .crmIcon {
    position: absolute;
    top: 0;
    color: var(--secondary-text-color);
    display: flex; 
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 28px;
    transition-duration: .3s; 
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}

.groupInput .groupInputIcon:focus-within .crmIcon {
     color: var(--secondary-background); 
}

.groupInput .groupInputIcon .crmIcon svg { 
    display: inline-block;
    vertical-align: middle;
    height: 17px;
    width: 17px;
    color: var(--muted-text-color);
    fill: var(--muted-text-color); 
}
 




.groupPrice { 
    display: flex;  
    padding: 4px 6px;
    margin: 0 -6px;
    border-radius: 8px; 
    transition: background-color 0.2s ease ;
}
 

.groupPrice .leftLabel { 
    width:  70%;
    font-size: 13px;
    display: flex;
    font-weight: 500;
    color: var(--text-color);
    align-content: center;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 8px;
}


.groupPrice .rightInput {
    width: 30%;
}



  
.errorInput {
    border-color: rgb(225, 29, 72); 
}

.errorMsg {
    color: rgb(225, 29, 72); 
    font-size: 10px;
    position: absolute;
    bottom: -3px;
    background: #fff;
    right: 11px;
    line-height: 1; 
}


.btnPrimary {
    margin-top: 20px;
    margin-left: auto;
    display: block;
    text-align: center;
    padding:  7px 20px;
    cursor: pointer;
    line-height: 1.25rem;
    color: #fff;
    font-size: .875rem; 
    border: none;
    border-radius: 0.3rem;
    width: 100%;
    max-width: 200px;
    /* height: 2.5rem; */ 
    font-feature-settings: "case" on;
    background-color: var(--primary-color);
    transition-duration: .3s;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}