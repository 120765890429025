.navigation {
    margin: 0;
    padding: 0;
    display: flex; 
    height: 100%;
    top: 0;
    position: fixed;
    background-color: #fff; 
    z-index: 2;
}

.navSmall { 
    border-right: 1px solid  var(--border-color); 
    display: flex;
    width: 200px;
    background-color: #fff;
    margin: 0;
    padding: 0;  
    flex-direction: column;
    z-index: 2;
}
 
 
.mainNavButton {
    width: 100%;
    padding: 4px 10px;
}
 
.mainNavButton a {
    width: 100%;
    display: flex;
    align-items: center; 
    border-radius: 7px;
    color: var(--text-color);
    transition: color 0.2s , background-color 0.2s;
    font-weight: 500;
}


.mainNavButton a.active   {
    color: var(--text-color);
    background-color:  var(--secondary-background);
    font-weight: 600;
}

.mainNavButton a:hover   { 
    background-color:  var(--secondary-background);
}
  
.mainNavButton a span.iconmenu {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    color: var(--secondary-text-color);
}
  
.mainNavButton a.active span.iconmenu {
 
    color: var(--text-color);
}
 
  
.mainNavButton button.textmenu,
.mainNavButton a span.textmenu  { 
    padding: 5px;
    font-feature-settings: "case" on;
}

 
.mainNavButton button svg,
.mainNavButton a svg { 
    width: 1.25rem;
    height: 1.25rem;
    display: inline-block;
    vertical-align: middle;
}
 

.bottomNav {
    margin-top: auto;
    margin-bottom: 20px;
}

 

.subNav ul   { 
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-left: 15px;
    padding-right: 0;
    margin-top: 5px;
}

.subNav ul li {  
    width: 100%;
}

.subNav ul li a {
    color: var(--secondary-text-color);
    width: 100%;
    display: flex;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.25rem;
    justify-content: space-between;
    align-items: center;
    padding: 3px 5px 3px 7px;
}

.subNav ul li a svg {
    transform:  rotate(-90deg)  ;
    height: 12px;
    width: 12px;
}

.subNav ul li a.active,
.subNav ul li a:hover {
    color: var(--text-color);
}


.minSubnaBtn {
    display: inline-flex;
    align-items: center;
    cursor: pointer; 
    user-select: none;
    border-radius: 50%;
    color: rgb(99, 115, 129);
    top: 200px;
    position: absolute;
    left: calc(100% - 14px);
    border: 1px dashed var(--border-color);
    background-color: #fff;
    width: 24px;
    height: 24px;
    justify-content: center;
    padding: 0;
    transition: transform 250ms ;
    transform: rotate(90deg);
    z-index: 3;
}


.minSubnaBtn.rotated {
    transform: rotate(270deg);
}

.minSubnaBtn svg {
    width: 12px;
    height: 12px;
}


.headerMobile {
    display: none;
    width: 100%; 
    position: absolute;
    z-index: 1;
    background-color: #fff;
    padding: 0px 15px;
    min-height: 50px;
    border-bottom: 1px solid var(--border-color); 
    align-items: center;
    justify-content: space-between;
}

.barBTN {
    display: flex;
    gap: 9px;
    width: 35px;
    height: 35px;
    flex-direction: column;
    align-items: stretch;
    justify-content: center; 
    cursor: pointer; 
}

.barBTN .bar {
    border-radius: 52px;
    height: 0.16rem;
    background: var(--text-color);
    width: 100%; 
    transition: all 0.3s ease;
}


.barBTN.active .bar1 {  
    transform: rotate(-45deg)  translate(-5px, 4px);
}

.barBTN.active .bar2 {  
    transform: rotate(45deg) translate(-4px, -4px);
}


.logoMobile {
    height: 30px;
}

.logoMobile svg {
    height: 100%;
}

.LogoContainer {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
  }
  
  .LogoContainer svg{
    height: 33px;
    margin-right: 10px;
    width: 47px;
  }
  .LogoContainer span{
    background: #fff;
    border-radius: 0;
    color: var(--primary-color);
    font-size: 14px;
    font-weight: 900;
    line-height: 1;
    margin-left: -54px;
    margin-top: 5px;
    padding: 0 0 0 1px;
    position: relative;
    z-index: 2;
  } 
  
  
  .LogoContainer::after  {
    background: #fff;
    content: "";
    height: 21px;
    left: 4px;
    position: absolute;
    top: 9px;
    width: 38px;
    z-index: 1;
  }

  .overlayBG { 
    top: 0;
    left: 0;
    position: fixed; 
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / .7);
    z-index: -1;
    transition: opacity 0.2s;
    opacity: 0;
  }
 

@media (max-width: 1025px) {

    .navSmall { 
        overflow: auto;
    }
    .headerMobile {
        display: flex;
    }
  
    .navigation {
        height: 100vh;
        left: -200px;
        opacity: 0;
        transition: left 0.2s , opacity 0.3s;
    }
  
    .navigation.active {
        left: 0;
        opacity: 1;
    }
  
    .overlayBG.active {
        display: block;
        opacity: 1; 
        z-index: 1;
    
    }
 
}  
 

.bottomNavLinks {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .linkItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    margin-bottom: 5px;
    color: #fff;
    font-weight: bold;
    border-radius: 4px;
    text-decoration: none;
  }
  
  .linkItem span { 
    text-align: center;
  }