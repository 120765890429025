.btn { 
    border: 1px solid;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    cursor: pointer;
    align-items: center;
    font-feature-settings: "case" on;
    line-height: 1.25;
    font-weight: 600;
    padding: 0px 7px 0px 0px;
    font-size: 13px;
    overflow: hidden;
    color: var(--secondary-text-color);
    border-color: var(--border-color);
}

 
.btn span.icon { 
    max-width: 28px;
    max-height: 28px;
    min-width: 28px;
    min-height: 28px;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
    display: flex;
    color: var(--secondary-text-color);
    background-color: var(--secondary-background);
}

.btn span.icon svg {  
    max-width: 18px;
    max-height: 18px;
    min-width: 18px;
    min-height: 18px; 
}
 
 

.modalHead {
    padding: 1.5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
 
.modalHead h3 {
    display: flex;
    align-items: center;
    color: var(--text-color);
    padding: 0;
    margin: 0;
    font-weight: 600;
    font-size: 1.125rem; 
    font-feature-settings: "case" on; 
}
 
.modalHead h3 > span{
    height: 30px;
    width: 30px;
    border-radius: 999px;
    color:var(--secondary-text-color);
    background-color:var(--secondary-background);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}

.modalHead h3 span svg{
    height: 18px;
    width: 18px; 
}

 


.customtextarea {
    border: 1px solid var(--border-color);
    padding: 7px;
    width: 100%;
    color: var(--text-color);
    resize: vertical;
    border-radius: 5px;
    margin-bottom: 15px;
}



.btnPrimary {
    margin-top: 20px;
    margin-left: auto;
    display: block;
    text-align: center;
    padding: 7px 20px;
    cursor: pointer;
    line-height: 1.2;
    color: #fff;
    font-size: 14px; 
    font-feature-settings: "case" on;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    width: 100%;
    max-width: 200px; 
    background-color: var(--primary-color);
    transition-duration: .3s;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}

.bulkTitle {
    display: flex;
    line-height: 1.25;
    flex-direction: column;
}
.bulkTitle .userCount {
    font-size: 13px;
    font-feature-settings: "case" off;
     color: var(--secondary-text-color);
}
.bulkTitle .userCount span {
    font-size: 13px;
    font-weight: 700;
    font-feature-settings: "case" off;
     color: var(--text-color);
}