.AddBtn { 
    border: 1px solid;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    cursor: pointer;
    align-items: center;
    font-feature-settings: "case" on;
    line-height: 1.25;
    font-weight: 600;
    padding: 0px 7px 0px 0px;
    font-size: 13px;
    overflow: hidden;
    color: var(--secondary-text-color);
    border-color: var(--border-color);
}

 
.AddBtn span.icon { 
    max-width: 28px;
    max-height: 28px;
    min-width: 28px;
    min-height: 28px;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
    display: flex;
    color: var(--secondary-text-color);
    background-color: var(--secondary-background);
}

.AddBtn span.icon svg {  
    max-width: 18px;
    max-height: 18px;
    min-width: 18px;
    min-height: 18px; 
}

.DefaultAddBtn { 
    border: 1px solid;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    cursor: pointer;
    align-items: center;
    font-feature-settings: "case" on;
    line-height: 1.25;
    font-weight: 600;
    font-size: 13px;
    overflow: hidden;
    color: var(--secondary-text-color);
    border-color: var(--border-color);
}

 
.DefaultAddBtn span.icon { 
    max-width: 28px;
    max-height: 28px;
    min-width: 28px;
    min-height: 28px;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    display: flex;
    color: var(--secondary-text-color);
    background-color: var(--secondary-background);
}

.DefaultAddBtn span.icon svg {  
    max-width: 18px;
    max-height: 18px;
    min-width: 18px;
    min-height: 18px; 
}

 
.groupInput {
    margin-bottom: 12px;
    position: relative;
}

.input::placeholder {
    color: var(--placeholder-text-color);
    position: relative;
}

.groupInput .input::placeholder {
    color: var(--placeholder-text-color);
    position: relative;
}

.groupInput label { 
    color: var(--secondary-text-color);
    font-size: .825rem;
    position: absolute;
    font-size: 11px;
    font-weight: 500;
    color: var(--muted-text-color);
    line-height: 1;
    margin-bottom: 4px;
    top: -6px;
    z-index: 1;
    left: 8px;
    padding: 0 3px;
    background: #fff;
    font-weight: 500;
}

.input {  
    line-height: 1.25rem;
    color: var(--text-color);
    font-size: 12px;
    padding: 0.5rem; 
    border: 1px solid var(--border-color);
    border-radius: 5px;
    width: 100%;
    height: 35px;
    background-color: rgb(255 255 255); 
    outline-color: transparent;
    outline-offset: 2px;
    outline-style: dashed;
    outline-width: 1px; 
    transition-duration: .3s; 
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}

.input:focus-within {  
    outline-color: var(--border-color);
}

.groupInput .groupInputIcon {
    position: relative;
}

.groupInput .groupInputIcon .input { 
    padding-inline-start: 28px;
    padding-inline-end: 10px;
}

.groupInput .groupInputIcon .crmIcon {
    position: absolute;
    top: 0;
    color: var(--secondary-text-color);
    display: flex; 
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 28px;
    transition-duration: .3s; 
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}

.groupInput .groupInputIcon:focus-within .crmIcon {
    --tw-text-opacity: 1;
    color: var(--secondary-text-color); 
}

.groupInput .groupInputIcon .crmIcon svg { 
    display: inline-block;
    vertical-align: middle;
    height: 17px;
    width: 17px;
    color: var(--text-color);
    fill: var(--text-color); 
}
 
 

.inp2col { 
    display: flex;
    gap: 10px;
}

.inp2col > * {
    width: 100%;
}
 
.modalHead {
    padding: 1.5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
 
.modalHead h3 {
    display: flex;
    align-items: center;
    color: var(--text-color);
    padding: 0;
    margin: 0;
    font-weight: 500;
    font-size: 18px; 
    font-feature-settings: "case" on; 
}
 
.modalHead h3 span{
    height: 30px;
    width: 30px;
    border-radius: 999px;
    color: var(--secondary-text-color);
    background-color: var(--secondary-background);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}
 
.modalHead h3 span svg{
    height: 18px;
    width: 18px; 
}


.btnPrimary {
    margin-top: 20px;
    margin-left: auto;
    display: block;
    text-align: center;
    padding:  7px 20px;
    cursor: pointer;
    line-height: 1.25rem;
    color: #fff;
    font-size: .875rem; 
    border: none;
    border-radius: 0.3rem;
    width: 100%;
    max-width: 200px;
    /* height: 2.5rem; */ 
    font-feature-settings: "case" on;
    background-color: var(--primary-color);
    transition-duration: .3s;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}
 
.errorInput {
    border-color: rgb(225, 29, 72); 
}

.errorMsg {
    color: rgb(225, 29, 72); 
    font-size: 10px;
    position: absolute;
    bottom: -3px;
    background: #fff;
    right: 11px;
    line-height: 1; 
}

 
.groupPrice {      
    transition: background-color 0.2s ease ;
    margin-bottom: 10px;
}
 
.groupPriceLeft {   
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
 

.groupPrice .leftLabel { 
    display: block; 
    font-size: 13px; 
    color: var(--text-color);
    font-weight: 500;
    margin-bottom: 3px; 
    font-size: 12px;
    font-weight: 600;
    color: #6b6b6b;
}


.groupPrice .rightInput {
    width: 120px;
}




.formField {  
    width: 100%; 
    background-color: #fff;  
    position: relative; 
    height: 100%;
    margin-bottom: 20px;
    margin-top: 6px;
    padding: 0;
}

.formField legend { 
    position: absolute;
    font-size: 11px;
    font-weight: 500;
    color: var(--muted-text-color);
    line-height: 1;
    margin-bottom: 4px;
    top: -6px;
    z-index: 1;
    left: 8px;
    padding: 0 3px;
    background: #fff;
    font-weight: 500;

}



 
.errorInput .input {
    border-color: rgb(225, 29, 72); 
}
 
.errorInput label {
    color: rgb(225, 29, 72); 
}

.dataContainer {
    width: 100%;
}

.dataContainer .input {
    padding-inline-start: 26px;
    padding-inline-end: 10px;
    background-repeat: no-repeat;
    background-size: 14px;
    background-position: 6px center;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="28" height="32" viewBox="0 0 14 16"><path fillRule="evenodd" d="M13 2h-1v1.5c0 .28-.22.5-.5.5h-2c-.28 0-.5-.22-.5-.5V2H6v1.5c0 .28-.22.5-.5.5h-2c-.28 0-.5-.22-.5-.5V2H2c-.55 0-1 .45-1 1v11c0 .55.45 1 1 1h11c.55 0 1-.45 1-1V3c0-.55-.45-1-1-1zm0 12H2V5h11v9zM5 3H4V1h1v2zm6 0h-1V1h1v2zM6 7H5V6h1v1zm2 0H7V6h1v1zm2 0H9V6h1v1zm2 0h-1V6h1v1zM4 9H3V8h1v1zm2 0H5V8h1v1zm2 0H7V8h1v1zm2 0H9V8h1v1zm2 0h-1V8h1v1zm-8 2H3v-1h1v1zm2 0H5v-1h1v1zm2 0H7v-1h1v1zm2 0H9v-1h1v1zm2 0h-1v-1h1v1zm-8 2H3v-1h1v1zm2 0H5v-1h1v1zm2 0H7v-1h1v1zm2 0H9v-1h1v1z" fill="rgb(68 68 68)"/></svg>');
}

.dataContainer .errorVal .input {
    border-color: #d80228;
}
 