  
.btn { 
    border-radius: 5px;
    line-height: 1.25;
    font-weight: 500;
    padding: 2px 3px;
    display: flex;
    font-size: 12px;
    color: var(--text-color);
    background-color: var(--secondary-background);
    border: 1px solid var(--border-color);
    cursor: pointer;
    transition: background-color 0.2s ease, border-color 0.2s ease;
    align-items: center;
}

.btn:hover { 
    background-color: var(--third-background);
}
 
.btn svg {  
    height: 16px;
    width: 16px;
    margin-right: 3px;
    color: var(--secondary-text-color);
    fill: var(--secondary-text-color);
    transition: color 0.2s ease ;
}


.header {
    height: 200px ;
    background-color: #000;
}


.invoiceLink {
    color: var(--text-color); 
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 8px;
}
 

.invoiceLink svg{
    height: 18px;
    width: 18px;
}