.head{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    align-content: center;
    gap: 10px;
    align-items: stretch;
    justify-content: space-between;
}

 
.headMain {
    display: flex;
    height: 4rem;
    gap: 0.5rem;
    position: relative;
    align-items: center;
    justify-content: space-between;
}

.headMain h1 {
   color: rgb(30 41 59);
   line-height: 1.5;
   font-weight: 300;
   font-size: 18px; 
   font-feature-settings: "case" on;
}
 
.pad0 {
    padding: 0 !important;
}

.btntbl {
    padding: 0 10px !important;
    width: 25px !important; 
}

.id {
    width: 25px !important;
    text-align: center !important;
}
 
.btns {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
}