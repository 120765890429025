 
.modalHead {
    padding: 1.5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
 
.modalHead h3 {
    display: flex;
    align-items: center;
    color: var(--text-color);
    padding: 0;
    margin: 0;
    font-weight: 700;
    font-size: 1.125rem;
    font-feature-settings: "case" on;
}
 
.modalHead h3 span {
    height: 34px;
    width: 34px;
    border-radius: 999px;
    color: var(--secondary-text-color);
    border: 1px solid  var(--border-color);
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}
 
.modalHead h3 span svg{
    height: 18px;
    width: 18px; 
}
 

.textHead {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 1;
    font-weight: 500;
}

.textHead .vinHead {
   font-weight: 700;
} 

 
.container {
    width: 100%;
}

.bodyPage {
    width: 100%;
    max-width: 800px;
    background-color: white;
    padding: 20px; 
    border: 1px solid var(--border-color);
    border-radius: 5px;
    display: inline-block;
    margin-bottom: 20px;
    position: relative; 
}


.box {
    width: 100%;
    background-color: white;
    padding: 20px; 
    border: 1px solid var(--border-color);
    border-radius: 5px;
    display: inline-block;
    margin-bottom: 20px;
    position: relative; 
}


 
.row {
    --bs-gutter-x: 10px;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y)* -1);
    margin-right: calc(var(--bs-gutter-x)* -.5);
    margin-left: calc(var(--bs-gutter-x)* -.5);
}

.row>* {
    --bs-gutter-x: 10px;
    --bs-gutter-y: 0;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x)* .5);
    padding-left: calc(var(--bs-gutter-x)* .5);
    margin-top: var(--bs-gutter-y);
}


.col1 {
    flex: 0 0 auto;
    width: 100%;
}

.col2 {
    flex: 0 0 auto;
    width: 50%;
}
 
 
.col3 {
    flex: 0 0 auto;
    width: 33.33333333%;
}

.col4 {
    flex: 0 0 auto;
    width: 25%;
}

.col5 {
    flex: 0 0 auto;
    width: 20%;
}

.col5 {
    flex: 0 0 auto;
    width: 20%;
}

.col6 {
    flex: 0 0 auto;
    width: 16.6666666666666666%;
}

.colAuto {
    flex: 0 0 auto;
    width: auto;
}

.btween {
    justify-content: space-between;
}

.h3 { 
    border-bottom: 1px solid var(--border-color);
    padding: 0;
    margin: 0;
    margin-top: 0;
    padding-bottom: 13px;
    margin-bottom: 18px;
    font-weight: 500;
    font-size: 1.125rem;
    font-feature-settings: "case" on;
}

  
.groupInput {
    margin-bottom: 15px;
    margin-bottom: 15px;
    position: relative;
}
  
.groupInput .input::placeholder {
    color: var(--placeholder-text-color);
    position: relative;
}

.groupInput label {  
    color: var(--text-color);
    font-size: 13px; 
}
 
.textarea {  
    line-height: 1.25rem;
    color: var(--text-color);
    font-size: 13px;
    padding: 5px 10px; 
    border: 1px solid var(--border-color);
    border-radius: 5px;
    width: 100%; 
    max-width: 500px; 
    background-color: rgb(255 255 255); 
    outline-color: transparent;
    outline-offset: 2px;
    outline-style: dashed;
    outline-width: 1px;  
    min-height: 100px;
}
 
.input {  
    line-height: 1.25rem;
    color: var(--text-color);
    font-size: 13px;
    padding: 0 10px; 
    border: 1px solid var(--border-color);
    border-radius: 5px;
    width: 100%;
    height: 35px;
    background-color: rgb(255 255 255); 
    outline-color: transparent;
    outline-offset: 2px;
    outline-style: dashed;
    outline-width: 1px; 
    transition-duration: .3s; 
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}

.input::placeholder {
    color: var(--placeholder-text-color);
    position: relative;
}

.input:focus-within {  
    outline-color: none; 
} 
 



.formCheck   { 
    margin-top: 1px; 
    margin-bottom: 1px; 
  }
  
  
  .formCheck input { 
    display: none; 
  }
  
  .formCheck label {
    position: relative;
    cursor: pointer; 
    color: var(--secondary-text-color);
    font-size: 13px; 
  }
  
  
  .formCheck label span{
    font-weight: bold; 
  }
  
  .formCheck label:before {
    content: '';
    background-color: var(--secondary-background);
    border: 1px solid var(--border-color);
    box-shadow: none;
    padding: 8px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 2px;
  }
  
  .formCheck input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 6px;
    width: 6px;
    height: 12px;
    border: solid var(--secondary-text-color);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  .flexforCheck { 
    display: flex;
    gap: 20px;
    margin-top: 3px;  
  }

  .question {
    font-weight: 600; 
    gap: 10px;
  }



.btnPrimary { 
    margin-left: 0;
    display: block;
    text-align: center;
    padding: 7px 20px;
    cursor: pointer;
    line-height: 1.2;
    color: #fff;
    font-size: 14px; 
    font-feature-settings: "case" on;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    width: 100%;
    max-width: 200px; 
    background-color: var(--primary-color);
    transition-duration: .3s;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}


.errorMsg {
    font-weight: 600;
    color: rgb(225, 29, 72); 
}
