.groupInput {
    margin-bottom: 12px;
    position: relative;
}

.groupInput .input::placeholder {
    color: var(--placeholder-text-color);
    color: var(--muted-text-color);
    position: relative;
}

.groupInput label { 
    color: var(--muted-text-color);
    font-size: .825rem;
}

.input {  
    line-height: 1.25rem;
    color: var(--text-color);
    font-size: .875rem;
    padding: 0.5rem; 
    border: 1px solid var(--border-color);
    border-radius: 0.3rem;
    width: 100%;
    height: 2.5rem;
    background-color: rgb(255 255 255); 
    outline-color: transparent;
    outline-offset: 2px;
    outline-style: dashed;
    outline-width: 1px; 
    transition-duration: .3s; 
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}

.input:focus-within {  
    outline-color: var(--border-color); 
}

.groupInputIcon {
    position: relative;
}

.groupInputIcon .input { 
    padding-inline-start: 2.25rem;
    padding-inline-end: 1rem;
}

.crmIcon {
    position: absolute;
    top: 0;
    color: var(--placeholder-text-color);
    display: flex; 
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    width: 2.5rem;
    transition-duration: .3s; 
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}

.groupInput:focus-within .crmIcon {
    --tw-text-opacity: 1;
    color: var(--primary-color); 
}

.crmIcon svg { 
    display: inline-block;
    vertical-align: middle;
    height: 1.15rem;
    width: 1.15rem;
}

.btnPrimary {
    cursor: pointer;
    line-height: 1.25rem;
    color: #fff;
    font-size: .875rem;
    padding-bottom: 10px;
    padding-top: 10px;
    border: none;
    border-radius: 0.3rem;
    width: 100%;
    /* height: 2.5rem; */ 
    font-feature-settings: "case" on;
    background-color: var(--primary-color);
    transition-duration: .3s;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}



.priceInput {
    display: flex; 
    line-height: 1.25rem;
    color:  var(--text-color);
    font-size: 11px;
    padding: 0;
    border: 1px solid var(--border-color);
    border-radius: 0.3rem;
    width: 100%;
    height: 30px;
    background-color: rgb(255 255 255);
    outline-color: transparent;
    outline-offset: 2px;
    outline-style: dashed;
    outline-width: 1px;
    transition-duration: .3s;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    align-items: center;
    flex-direction: row;
    overflow: hidden;

}

.priceInput .priceCurrency {  
    font-size: 11px;
    font-weight: 600;
    min-width: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--muted-text-color);
    background-color: var(--secondary-background);
    height: 100%;
}

.priceInput .input {
    background-color: transparent;
    border-color: transparent;
    width: 100%;
    height: 100%;
    text-align: center;
    outline: none !important;
    border-radius: 0;
    padding: 0;
    font-weight: 600;
    border-left: 1px solid  var(--border-color);
}


.priceInput .input:visited,
.priceInput .input:active,
.priceInput .input:focus,
.priceInput .input:focus-within {  
    outline: 0; 
    
}

.priceInput .butPlusMinus { 
    width: 70px;
    height: 100%;
    display: flex;
    border-radius: 0;
    border-left: 1px solid  var(--border-color);
    flex-direction: column;
    align-items: stretch;
}

.priceInput .butPlusMinus .button { 
    cursor: pointer;
    height: 50%;
    display: flex;
    background-color: rgb(241 245 249);
    color: var(--secondary-text-color);
    align-items: center;
    justify-content: center;
}

.priceInput .butPlusMinus .button:first-child { 
    border-bottom: 1px solid  var(--border-color);
}

.priceInput .hundred { 
    width: 28px;
    padding: 3px 5px;
    height: 100%;
    display: flex;
    border-radius: 0;
    border-left: 1px solid var(--border-color);
    background-color: var(--secondary-background);
    color: var(--muted-text-color);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}
 