.table {     
    border-radius: 4px;
    overflow-x: auto;
    overflow-y: auto;
    border: 1px solid var(--border-color-table); 
    background-color: #fff;
    max-height: calc(100vh - 130px);
}

.table table {    
    border-collapse: collapse;
    border-color: inherit;
    text-indent: 0;
    min-width: 100%;
    table-layout: fixed; 
}

.table table thead {    
    position: sticky;
    top: 0;  
    z-index: 2;
}

.table table thead tr th {     
    font-weight: 600;
    font-size: 13px;
    color: var(--text-color);
    padding: 10px 7px;
    line-height: 1;
    background-color: var(--secondary-background); 
    font-feature-settings: "case" on;
    border-right: 1px solid;
    border-bottom: 1px solid;
    border-color: var(--border-color-table) ;
    white-space: nowrap;
    text-align: start; 

}

.table table thead tr th input {     
    font-weight: 600;
    font-size: 13px;
    color: var(--text-color);
    padding: 5px 7px;
    line-height: 1;
    background-color: transparent;  
    border: transparent;   
    padding: 0.5rem;
    width: 100%;
    min-width: 100px;
}

.table table thead tr th input::placeholder {      
    color: var(--text-color);
    font-feature-settings: "case" on;
}

.table table thead tr th input:focus,
.table table thead tr th input:focus-within {
    outline-color: none !important;
    border-color: none !important;
    outline: none !important;
    box-shadow: none !important;
}  
  
 

.table table tbody tr td {    
    padding: 5px 7px;
    font-weight: 600;
    color: var(--secondary-text-color);
    font-size: 13px;
    line-height: 1.5;
    border-right: 1px solid;
    border-bottom: 1px solid;
    border-color: var(--border-color-table);
    white-space: nowrap;
}

 

.table table tbody tr:nth-child(2n) {
    /* 
    background-color: var(--secondary-background);
     */ 
}


.table table tbody tr:last-child td {     
       border-bottom: 0px solid transparent;  
   
}


.table table thead th:first-child, 
.table table tbody td:first-child {     
   border-left: 0;
}

.table table thead th:last-child, 
.table table tbody td:last-child {     
   border-right: 0;
}



.table table tbody tr {  
    background-color: rgb(255 255 255); 
}

.table table tbody .active {
    background-color: #e7e9ef;
}

.table table tbody tr:hover {  
    background-color: #ffe6774b; 
}

 
.table::-webkit-scrollbar {
    height: 7px;
    width: 7px;
    border: 1px solid #000;
}
  
.table::-webkit-scrollbar-track {
    background: var(--secondary-background); 
}

.table::-webkit-scrollbar-thumb {
    background:  #909090; 
    border-radius: 1px;
    position: fixed;
    cursor: pointer;
    border-radius: 3px;
} 
 

.table::-webkit-scrollbar-thumb:hover {
    background:  #909090; 
}


@media (max-width: 1025px) {
    .table {     
        max-height: calc(100vh - 260px);
    }
}  
 