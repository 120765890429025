.removeBtn {   
    display: flex;
    font-size: 12px;
    color: var(--text-color); 
    cursor: pointer;
    align-items: center;
}
 
.removeBtn svg {  
    height: 16px;
    width: 16px; 
    color: var(--secondary-text-color);
    fill: var(--secondary-text-color);
    transition: color 0.2s ease ;
}


.removeBtnYes { 
    color: #dc2626;
    background-color: rgb(220 38 38 / 10%);
    border-radius: 99.375rem;
    padding: 8px 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 600;
    font-size: 13px;
    line-height: 1.25;
}
 
 
 
.message {
    font-size: 14px;
    color: var(--secondary-text-color) ;
    line-height: 1.25;
    margin-bottom: 10px;
    text-align: center;
}

.buttons { 
    line-height: 1.25; 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
 
 