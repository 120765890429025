.container {
    width: 100%;
    background-color: white;
    padding: 20px; 
    border: 1px solid var(--border-color);
    border-radius: 5px;
    display: inline-block;
    margin-bottom: 20px;
    position: relative;
    z-index: 99;
}
 
.row {
    --bs-gutter-x: 10px;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y)* -1);
    margin-right: calc(var(--bs-gutter-x)* -.5);
    margin-left: calc(var(--bs-gutter-x)* -.5);
}

.row>* {
    --bs-gutter-x: 10px;
    --bs-gutter-y: 0;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x)* .5);
    padding-left: calc(var(--bs-gutter-x)* .5);
    margin-top: var(--bs-gutter-y);
}

.col {
    flex: 1 0 0%;
}

.col2 {
    flex: 2 0 0%;
}
.colSmall {
    flex: 0 0;
}

.groupInput {
    position: relative;
}
 

.groupInput .input::placeholder {
    color: var(--placeholder-text-color);
    position: relative;
}

.groupInput label { 
    position: absolute;
    top: -8px;
    left: 8px;
    color: var(--muted-text-color);
    background-color: #fff;
    font-size: 12px;
    line-height: 1;
    padding: 0px 3px;
    z-index: 9;
}
 
.input {  
    line-height: 1.25rem;
    color: var(--text-color);
    font-size: 13px;
    padding: 0 10px; 
    border: 1px solid var(--border-color);
    border-radius: 5px;
    width: 100%;
    height: 35px;
    background-color: rgb(255 255 255); 
    outline-color: transparent;
    outline-offset: 2px;
    outline-style: dashed;
    outline-width: 1px; 
    transition-duration: .3s; 
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}

.input::placeholder {
    color: var(--placeholder-text-color);
    position: relative;
}

.input:focus-within {  
    outline-color: none; 
}
 
.btnPrimary { 
    margin-left: auto;
    display: block;
    text-align: center;
    padding: 9px 20px;
    cursor: pointer;
    line-height: 1.2;
    color: #fff;
    font-size: 14px;
    font-feature-settings: "case" on;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    width: 100%;
    background-color: var(--primary-color);
    transition-duration: .3s;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}
 
.errorInput input {
    border-color: rgb(225, 29, 72); 
}
 
.errorInput label {
    color: rgb(225, 29, 72); 
}

.errorMsg {
    color: rgb(225, 29, 72); 
    font-size: 10px;
    position: absolute;
    bottom: -3px;
    background: #fff;
    right: 11px;
    line-height: 1; 
}
 



.dataContainer  { 
    display: block;
    position: relative;
}

.dataContainer .input {
    padding-left: 30px;
    padding-right: 10px;
    background-repeat: no-repeat;
    background-size: 17px;
    background-position: 6px center;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="28" height="32" viewBox="0 0 14 16"><path fillRule="evenodd" fill="rgb(139 142 143)" d="M13 2h-1v1.5c0 .28-.22.5-.5.5h-2c-.28 0-.5-.22-.5-.5V2H6v1.5c0 .28-.22.5-.5.5h-2c-.28 0-.5-.22-.5-.5V2H2c-.55 0-1 .45-1 1v11c0 .55.45 1 1 1h11c.55 0 1-.45 1-1V3c0-.55-.45-1-1-1zm0 12H2V5h11v9zM5 3H4V1h1v2zm6 0h-1V1h1v2zM6 7H5V6h1v1zm2 0H7V6h1v1zm2 0H9V6h1v1zm2 0h-1V6h1v1zM4 9H3V8h1v1zm2 0H5V8h1v1zm2 0H7V8h1v1zm2 0H9V8h1v1zm2 0h-1V8h1v1zm-8 2H3v-1h1v1zm2 0H5v-1h1v1zm2 0H7v-1h1v1zm2 0H9v-1h1v1zm2 0h-1v-1h1v1zm-8 2H3v-1h1v1zm2 0H5v-1h1v1zm2 0H7v-1h1v1zm2 0H9v-1h1v1z"  /></svg>');
}

.dataContainer .errorVal .input {
    border-color: #d80228;
}

.dataContainer .reciveAnput {  
    padding-right: 5px;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="rgb(139 142 143)" d="m20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8l8-8z"/></svg>') !important;   
}

.dataContainer .arriveAnput {  
    padding-right: 5px; 
    background-image:  url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="rgb(139 142 143)" d="M6.4 18L5 16.6L14.6 7H6V5h12v12h-2V8.4L6.4 18Z"/></svg>') !important;   
}

 