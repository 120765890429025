.head{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    align-content: center;
    gap: 10px;
    align-items: stretch;
    justify-content: space-between;
}
 
.headMain {
    display: flex; 
    gap: 0.5rem;
    position: relative;
    align-items: center;
    justify-content: space-between;
}

.headMain h1 { 
    color: var(--text-color);
    line-height: 1;
    font-weight: bold;
    font-size: 22px; 
    font-feature-settings: "case" on;
    display: flex;
    align-items: center;
}
 
.AddBtn { 
    margin-left: 15px;
    border: 1px solid;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    cursor: pointer;
    align-items: center;
    font-feature-settings: "case" on;
    line-height: 1.25;
    font-weight: 500;
    padding: 0px 7px 0px 0px;
    font-size: 13px;
    overflow: hidden;
    color: var(--secondary-text-color);
    border-color: var(--border-color);
}

 
.AddBtn span.icon { 
    max-width: 28px;
    max-height: 28px;
    min-width: 28px;
    min-height: 28px;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
    display: flex;
    color: var(--secondary-text-color);
    background-color: var(--secondary-background);
}

.AddBtn span.icon svg {  
    max-width: 18px;
    max-height: 18px;
    min-width: 18px;
    min-height: 18px; 
}



.AddBtnCargo {  
    display: flex;
    cursor: pointer;
    align-items: center; 
    overflow: hidden;
    color: #fff;
    text-align: center;
    justify-content: center;
    gap: 5px;
}

.AddBtnCargo > span{ 
    border: 1px solid var(--secondary-text-color);
    background-color: #fff;
    border-radius: 99px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    font-size: 12px;
    text-align: center;
    height: 17px;
    width: 17px;
    color: var(--secondary-text-color);
}

.AddBtnCargo > span svg{  
    min-height: 15px;
    min-width: 15px;
    max-height: 15px;
    max-width: 15px;
}
 
.AddBtnCargo > span.removeBtn {
    color: #dc2626;
    border-color: #dc2626;
    padding: auto;
}

.AddBtnCargo > span.removeBtn svg {
    min-height: 12px;
    min-width: 12px;
    max-height: 12px;
    max-width: 12px;

}


.pad0 {
    padding: 0 !important;
}
.btntbl {
    padding: 0 10px !important;
    width: 25px !important; 
}

.id {
    width: 25px !important;
    text-align: center !important;
}
 
.btns {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
}

.containerBox {
    padding: 0 0 20px 0;
    background-color: #fff; 
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid var(--border-color);
    min-height: unset;
    position: relative;
}

.created_at { 
    position: absolute;
    bottom: 5px;
    left: 16px;
    font-size: 11px;
    color: #555;
    font-style: italic;
}

.containerItem {  
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 0px 15px;
}

.containerDetail.containerNum {
    cursor: pointer;
    position: relative;
    line-height: 1.25rem;
    color: var(--primary-color);
    font-size: 13px;
    font-weight: bold;
    padding: 0 6px;
    border: 1px solid var(--primary-color);
    background-color: rgb(21 109 231 / 7%);
    align-items: center;
}
 

.containerDetail.containerNum > svg {
    margin-right: 5px;
    height: 25px;
    width: 25px;
}
 


.containerFlex {
    flex: 1;

    width: 11.111111111%;
    position: relative;  
    box-sizing: border-box;
}
 


.containerDetail {
    display: flex;
    line-height: 1.25rem;
    color: var(--text-color);
    font-size: 12px;
    padding: 0 10px 0 5px;
    border-radius: 5px;  
    height: 30px;
    border: 1px solid var(--border-color);
    flex: 1;
    position: relative;
    box-sizing: border-box;
    align-items: center; 
}

.containerDetail > svg {
    margin-right: 5px;
    min-height: 16px;
    min-width: 16px;
    max-height: 16px;
    max-width: 16px;
    color: #444;
    fill: #444;
}

.containerDetail .containerName {
    position: absolute;
    top: -10px;
    left: 4px;
    color: var(--muted-text-color);
    background-color: #fff;
    font-size: 11px;
    font-weight: 600;
    line-height: 1.2;
    padding: 0px 2px;
    text-overflow: ellipsis;
    overflow: hidden;
    border-radius: 99px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.containerDetail:hover .containerName {
    opacity: 1;
}

.containerDetail .containerValue {
 font-size: 13px;
 text-overflow: ellipsis;
 overflow: hidden;
 display: -webkit-box;
 -webkit-line-clamp: 1;
 -webkit-box-orient: vertical;
}

.containerDetail .containerValueStatus  {
    display: flex;
    gap: 5px;
    flex-direction: row;
    align-items: center;
}

.containerDetail .containerValueStatus > span {
    min-width: 12px;
    min-height: 12px;
    max-width: 12px;
    max-height: 12px;
    border-radius: 90px;
}

.containerDetail img {
    margin-right: 5px;
    height: 16px;
    width: 16px;
}


  
.containerDetail.containerdistance { 
    max-width: 130px;
    padding: 0 5px 0 5px;
}
  
.containerDetail.containerdistance  svg { 
    margin-right: 0;
}
  
.containerDetail.containerdistance .containerValue { 
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;    
}
  
  
.containerDetail.containerdistance .containerName { 
    left: calc(50% - 34px);
}
  
.containerDetail.containerdistance .containerValue span {
    text-transform: uppercase; 
    display: flex;
    align-items: center;
    gap: 5px;
}
  
.containerDetail.containerdistance .containerValue span img { 
    height: 20px;
    width:  20px;  
}


  

.containerDetail.yellow { 
    color: #df8805;
    font-weight: bold;
    border-color: #df8805;
    background-color: #fdfbf8;
} 

.containerDetail.yellow .containerName { 
    color: #df8805; 
    background-color: #fdfbf8;
} 
  

.containerDetail.red { 
    color: #d80228;
    font-weight: bold;
    border-color: #d80228;
    background-color: #fef9f9;
} 

.containerDetail.red svg { 
    color: #d80228; 
} 

.containerDetail.red .containerName { 
    color: #d80228;    
    background-color: #fef9f9;
} 

.containerDetail.green { 
    color: #009688;
    font-weight: bold;
    border-color: #009688;
    background-color: #e8f3f2;
} 

.containerDetail.green .containerName { 
    color: #009688;    
    background-color: #e8f3f2;
} 

.containerDetail.green svg { 
    color: #009688;    
} 
.containerDetail.blue { 
    color: #1254e7;
    font-weight: bold;
    border-color: #1254e7;
    background-color: #1255e729;
} 

.containerDetail.blue .containerName { 
    color: #1254e7;    
    background-color: #1255e729;
}

.containerDetail.blue svg { 
    color: #1254e7;    
} 

.dropdownContent { 
    position: absolute;
    top: 29px;
    left: 0;
    min-width: 151px; 
    border: 1px solid var(--border-color);
    z-index: 101;
    border-radius: 5px;
    background: #fff; 
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 0.1875rem;
    box-shadow: 0 0.25rem 0.5rem rgba(0,0,0,.1);

  }
 

.dropdownContent > span { 
    padding: 7px 5px;
    display: flex;
    border-radius: 0;
    font-size: 12px;
    font-weight: 600;
    font-style: inherit;
    background: #fff;
    text-align: center;
    color: var(--text-color);
    font-feature-settings: "case" on;
    cursor: pointer;
    /* border-bottom: 1px solid var(--border-color);*/
    transition: all 200ms;
    align-items: center;
}
.dropdownContent > span:last-child {   
    border-bottom: none; 
}

.dropdownContent > span > svg {  
    width: 16px;
    height: 16px;
    margin-left: 4px;
    margin-right: 5px;
    fill: var(--secondary-text-color);
    color: var(--secondary-text-color);
 
}

.dropdownContent > span:first-child { 
    border-top: none ; 
}

.dropdownContent > span:hover {  
    background-color: #f5f5f5;
}
 
 
.table {
    min-height: unset;
}
 
 
.cargoPrice {
    color: #1254e7;
    font-weight: bold;
}

 
 
.companyPrice {
    color: '#df8805';
    font-weight: bold;
}


.cargoPrice.red { 
    color: #d80228;   
} 
 

.cargoPrice.green { 
    color: #009688; 
} 


.groupInputPrice { 
    box-sizing: border-box;
    flex: 2;
    flex-grow: 2;
    position: relative;  
    display: flex;
    gap: 5px;
    align-items: center;
}


.containerDetail.dollar { 
    padding-left: 0px;  
}


.containerDetail.dollar .containerValue { 
    padding-left: 0px;
    padding-inline-start: 0px;
    font-weight: 600;  
 
}

.containerDetail.dollar svg { 
    margin-right: 0;
    width: 16px;
    justify-content: flex-end;
    height: 16px;
}



.table table thead{
    position: relative;
    z-index: 0;
 }

 .table table thead tr th {
    padding: 2px 5px;
    white-space: nowrap;
    text-align: start;
 }

 .table table tbody tr td {
    padding: 3px 5px;
 }

 .EditBtn { 
    color:  var(--text-color);   
    cursor: pointer; 
    display: flex;
}
 
 
.EditBtn svg {   
    height: 16px;
    width: 16px;
    color: var(--secondary-text-color);
    fill: var(--secondary-text-color);
} 

 

.table tbody tr.checkedCargo {  
    background-color: #ffe6774b; 
}




.formCheck   { 
    margin-top: 1px; 
    margin-bottom: 1px; 
  }
  
  
  .formCheck input { 
    display: none; 
  }
  
  .formCheck label {
    position: relative;
    cursor: pointer; 
    color: var(--secondary-text-color);
    font-size: 13px; 
  }
  
  
  .formCheck label span{
    font-weight: bold; 
  }
  
  .formCheck label:before {
    content: '';
    background-color: var(--secondary-background);
    border: 1px solid var(--border-color);
    box-shadow: none;
    padding: 5px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 3px;
    margin-top: -2px;
    border-radius: 2px;
    border-radius: 55px;

  }
  
  .formCheck input:checked + label:before {
    background-color: var(--primary-color);
    border-color: transparent;
  }
  
  .formCheck input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: 4px;
    width: 4px;
    height: 4px;
    border-radius: 55px;
    background: #fff;
  }

 
 
td.redCompany .RedistributeBTN ,
span.RedistributeBTN { 
    border: 1px solid;
    background-color: #fff !important;
    border-radius: 5px;
    display: flex;
    cursor: pointer;
    align-items: center;
    font-feature-settings: "case" on;
    line-height: 1.25;
    font-weight: 500;
    padding: 2px 7px;
    font-size: 13px;
    overflow: hidden;
    text-align: center;
    color: var(--secondary-text-color);
    border-color: var(--border-color); 
    user-select: none;
}
 
.RedistributeBTN span.icon { 
    max-width: 28px;
    max-height: 28px;
    min-width: 28px;
    min-height: 28px;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
    display: flex;
    color: var(--secondary-text-color) !important;
    background-color: var(--secondary-background) !important
}

.RedistributeBTN span.icon svg {  
    max-width: 18px;
    max-height: 18px;
    min-width: 18px;
    min-height: 18px; 
}

.YesBtn {
    background-color: #d1e7dd;
    color: rgba(25,135,84);
    padding: 3px 5px;
    border-radius: 5px;
    cursor: pointer;
}



.btnPrimary {  
    display: block;
    text-align: center;
    padding:  5px 5px;
    cursor: pointer;
    line-height: 1.25rem;
    color: #fff;  
    font-size: 14px; 
    font-weight: 600; 
    border: none;
    border-radius: 5px;
    width: 100%;
    max-width: 200px; 
    font-feature-settings: "case" on;
    background-color: var(--primary-color); 
    width: 100%;
}

 


.btnContainerTop { 
    border: 1px solid;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    cursor: pointer;
    align-items: center;
    font-feature-settings: "case" on;
    line-height: 1.25;
    font-weight: 500;
    padding: 0px 7px 0px 0px;
    font-size: 13px;
    overflow: hidden;
    color: var(--secondary-text-color);
    border-color: var(--border-color);
}

 
.btnContainerTop span.icon { 
    max-width: 28px;
    max-height: 28px;
    min-width: 28px;
    min-height: 28px;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
    display: flex;
    color: var(--secondary-text-color);
    background-color: var(--secondary-background);
}

.btnContainerTop span.icon svg {  
    max-width: 18px;
    max-height: 18px;
    min-width: 18px;
    min-height: 18px; 
}

 

.redTable > td{ 
    /* color: rgb(183, 29, 24) !important;  */
    background-color: rgba(255, 86, 48, 0.2) !important;
    /* border-color: rgba(255, 86, 48, 0.4) !important;  */
}


.greenTable > td{ 
    background-color: #d1e7dd !important;
    border-color: #a3cfbb !important;
    color: #0a3622 !important;
}

td.redCompany {
    background-color: #d80228 !important;
    color: #fff !important;
}
 
td.redCompany .companyPrice , 
td.redCompany .cargoPrice { 
    color: rgb(255 255 255 / 90%) !important;
}


.archive_box {
    position: absolute;
    top: 0;
    right: 0;
    width: 94px;
    height: 73px;
    font-size: 19px;
    overflow: hidden;
    z-index: 11;
}

.archive_box > div {
    position: absolute;
    font-feature-settings: "case" on;
    top: 14px;
    right: -34px;
    width: 126px;
    font-size: 18px;
    text-align: center;
    padding: 0px 5px;
    overflow: hidden;
    color: #fff;
    background-color: #dc3646;
    z-index: 1;
    transform: rotate(42deg);
}



.exelBtn {  
    color: var(--secondary-text-color);
    border: 1px solid var(--border-color);
    background-color: #fff;
    border-radius: 5px;
    padding: 0 10px 0 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  
  .exelBtn span.icon {  
    max-width: 28px;
    max-height: 28px;
    min-width: 28px;
    min-height: 28px;
    align-items: center;
    justify-content: center;
    margin-right: 2px; 
    display: flex;
  }
  
  .exelBtn span.icon svg {  
    max-width: 18px;
    max-height: 18px;
    min-width: 18px;
    min-height: 18px; 
  }
  
   

@media (max-width: 1025px) {

    .containerItem {  
        flex-direction: row;
        flex-wrap: wrap;
    }

    .containerFlex {
        flex: unset;
        width: calc(50% - 5px);
    }

    .containerBox .table {
        overflow: scroll !important;
    }

}