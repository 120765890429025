.dealerBtn {
  font-weight: 700 ;
  cursor: pointer;
  color: var(--secondary-text-color);
}
.modalHead {
  padding: 1.5rem 0 1rem 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}

.modalHead h3 {
  display: flex;
  align-items: center;
  color: var(--secondary-text-color);
  padding: 0;
  margin: 0;
  font-weight: 300;
  font-size: 1.125rem; 
  font-feature-settings: "case" on; 
}

.modalHead h3 span{
  height: 30px;
  width: 30px;
  border-radius: 999px;
  color: var(--secondary-text-color);
  background-color: rgb(241 245 249);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

.modalHead h3 span svg{
  height: 18px;
  width: 18px; 
}


.balance{
  margin-top: 10px;
  font-weight: 500;
  color: var(--secondary-text-color);
  font-size: 14px;
}


.balance span{
  margin-top: 10px;
  font-weight: bold;
  color: var(--secondary-text-color);
  font-size: 16px;
}


.id {
  width: 25px !important;
  text-align: center !important;
}

.addForm {
  display: flex;
  gap: 10px;
}

.addForm .btnsend{ 
  display: block;
  text-align: center;
  padding: 7px 20px;
  cursor: pointer;
  line-height: 1.25rem;
  color: #fff;
  font-size: .875rem;
  border: none;
  border-radius: 0.3rem;
  width: 100%;
  max-width: 200px;
  height: 35px;  
  font-feature-settings: "case" on;
  background-color: var(--primary-color);
}

.input {
  display: flex;
}