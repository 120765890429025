.boxUpload { 
    position: relative;
    min-height: 440px;
    border-radius: 5px;
    background-color: var(--layout-background);
    width: 100%;
    height: 100%;
    padding: 15px 15px;
}

.title {
    font-feature-settings: "case" on;
    color: var(--secondary-text-color);
    font-size: 13px;
    padding: 0;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 7px;
    margin-bottom: 10px;
    position: relative;
    z-index: 4;
}
 
.clickDiv {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; 
    cursor: pointer;
}


.message {
    position: absolute;
    top: calc(50% - 18px);
    z-index: 1;
    color: var(--muted-text-color);
    text-align: center;
    font-size: 16px; 
    font-style: italic;
    opacity: .5;
    width: 100%;
    cursor: pointer;

}

.container {   
    width: 100%;
    gap: 8px;
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

 
.container .image{ 
    width: 148px;
    height: 110px;
    position: relative;
    display: block;
    z-index: 2;
}
 
.container  .image img{ 
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.container .image .dlt{  
    background-color: #d63130;
    display: none;
    width: 20px;
    position: absolute;
    height: 20px;
    cursor: pointer;
    top: -5px;
    right: -5px;
    z-index: 3;
    border-radius: 32px;
    background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" fill="%23fff" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1792 1792" style="enable-background:new 0 0 1792 1792;" xml:space="preserve"><path d="M1082.2,896.6l410.2-410c51.5-51.5,51.5-134.6,0-186.1s-134.6-51.5-186.1,0l-410.2,410L486,300.4c-51.5-51.5-134.6-51.5-186.1,0s-51.5,134.6,0,186.1l410.2,410l-410.2,410c-51.5,51.5-51.5,134.6,0,186.1c51.6,51.5,135,51.5,186.1,0l410.2-410l410.2,410c51.5,51.5,134.6,51.5,186.1,0c51.1-51.5,51.1-134.6-0.5-186.2L1082.2,896.6z"/></svg>');
    background-position: center;
    background-size: 10px;
    background-repeat: no-repeat;
}

.container .image:hover .dlt{  
    display: block;
}

.loadingoverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure the overlay is on top of everything */
  }
  
  .loadingspinner {
    border: 4px solid rgba(255, 255, 255, 0.3); /* White border with transparency for the spinner */
    border-top: 4px solid #3498db; /* Blue border for the spinner */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite; /* Animation for the spinner */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }





.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .modalContent {
    position: relative;  
    text-align: center;
  }
  
  .modalImage {
    max-width: 90vw;
    max-height: 90vh;
  }
  
  .modalClose {
    position: absolute;
    top: 20px;
    right: 20px; 
    border-radius: 50px;  
    cursor: pointer; 
    display: flex;
    align-items: center;
    justify-content: center;
  }

    .modalClose svg {
        color: #fff;
        height: 28px;
        opacity: .6;
        width: 28px;
        transition: opacity 0.2s ease;
    }

    .modalClose:hover svg { 
        opacity: 1; 
    }

    .modalNav {
        position: absolute;
        top: 50%;
        background-color: transparent;
        transform: translateY(-50%);
        cursor: pointer;
        color: #fff;
        width: 65px;
        display: flex;
        padding: 0;
        height: 100%;
        align-items: center;
    }

    .modalNav svg {
         color: #fff;
         width: 50px;
         height: 50px;
         filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .3));
    }

    .modalNavPrev {
        left: 0;
    }

    .modalNavNext {
        right: 0;
 
    }
 

    .modalNavPrev svg {
        margin-left: 20px;  

    }

    .modalNavNext svg {
        margin-right: 20px; 
        transform: rotate(180deg);

    }