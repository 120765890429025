.groupPrice { 
    display: flex;  
    padding: 2px 0px;
    margin: 0;
    border-radius: 8px; 
    transition: background-color 0.2s ease ;
}

.groupPrice:focus-within,
.groupPrice:hover {  
    background-color: var(--secondary-background);
}
  
.groupPrice .leftLabel { 
    width: 63%;
    display: flex;
    font-size: 13px;
    font-weight: 500;
    color: var(--text-color);
    align-content: center;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 8px;
}


.groupPrice .rightInput {
    width: 37%;
} 


.TotalPrice {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 1px;
    color: var(--text-color);
}

.TotalPrice .paramTotal {
    font-weight: bold;
    padding-right: 10px;
}

.TotalPrice .priceCredit {
    font-weight: bold;
    color: var(--text-color);
    font-size: 22px;
}

.TotalPrice .priceCredit .curr {
    font-weight: bold;
    color: var(--text-color);
    font-size: 12px;
    margin-right: 10px;
}