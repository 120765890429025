.row {
    gap: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-end;
}
 
.input {  
    line-height: 1.25rem;
    color: var(--text-color);
    font-size: 14px;
    padding: 0.5rem; 
    border: 1px solid var(--border-color);
    border-radius: 0.3rem;
    width: 100%;
    height: 32px;
    background-color: rgb(255 255 255); 
    outline-color: transparent;
    outline-offset: 2px;
    outline-style: dashed;
    outline-width: 1px;  
}

.input:focus-within {  
    outline-color: rgb(203 213 225/1); 
}

 

.input::placeholder {
    color: var(--placeholder-color);
    position: relative;
}



.priceInput {
    display: flex; 
    line-height: 1.25rem;
    color:  var(--text-color);
    font-size: 11px;
    padding: 0;
    border: 1px solid var(--border-color);
    border-radius: 0.3rem;
    width: 100%;
    height: 30px;
    background-color: rgb(255 255 255);
    outline-color: transparent;
    outline-offset: 2px;
    outline-style: dashed;
    outline-width: 1px;
    transition-duration: .3s;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    align-items: center;
    flex-direction: row;
    overflow: hidden;

}

.priceInput .priceCurrency {  
    font-size: 11px;
    font-weight: 600;
    min-width: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--muted-text-color);
    background-color: var(--secondary-background);
    height: 100%;
}

.priceInput .input {
    background-color: transparent;
    border-color: transparent;
    width: 100%;
    height: 100%;
    text-align: center;
    outline: none !important;
    border-radius: 0;
    font-weight: 600;
    border-left: 1px solid  var(--border-color);
}


.priceInput .input:visited,
.priceInput .input:active,
.priceInput .input:focus,
.priceInput .input:focus-within {  
    outline: 0; 
    
}
 

.priceInput .hundred { 
    width: 28px;
    padding: 3px 5px;
    height: 100%;
    display: flex;
    border-radius: 0;
    border-left: 1px solid var(--border-color);
    background-color: var(--secondary-background);
    color: var(--muted-text-color);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}
 


.uploadDiv {
    cursor: pointer;
    color: var(--text-color);
    border: 1px solid var(--border-color);
    border-radius: 0.3rem;
    width: 100%;
    height: 32px;
    background-color: var(--secondary-background);
}


.uploadDiv input {
    cursor: pointer;
    color: var(--text-color);
    width: 100%;
    height: 100%;
    padding: 4px;
}

.uploadDiv input::file-selector-button:hover {
    background-color: var(--secondary-background);
  }
  
.uploadDiv input::file-selector-button:active {
    background-color: var(--secondary-background);
  }
   

.uploadDiv svg {
    opacity: 0.5;
    width: 100%;
    height: 100%;
    fill: var(--secondary-text-color);
    color: var(--secondary-text-color);
}

.addBox {
    display: flex;
    margin-top: 7px;
    justify-content: flex-end;
}

.addBox .addBtn { 
    cursor: pointer;
    line-height: 1;
    color: #fff;
    font-size: 13px;
    text-align: center;
    min-width: 140px;
    padding: 7px 8px;
    border: none;
    border-radius:  5px;
    font-feature-settings: "case" on;
    background-color: var(--primary-color);
    transition-duration: .3s;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}
 


.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
}

.table td, 
.table th {
    padding: 4px 5px !important;
    vertical-align: center;
    font-weight: normal;
}

.table thead th {
    padding: 7px 5px !important;
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}
 
 


.formField {  
    width: 100%;
    max-width: 330px;
    padding: 17px 14px 17px 13px;
    border: 1px solid;
    border-color: var(--border-color);
    background-color: #fff;
    border-radius: 8px;
    position: relative;
    height: 100%;
    margin: 0;
    margin-top: 30px;
    margin-left: auto;
}


.formField legend {
    position: absolute;
    top: -14px;
    left: 10px ; 
    border-radius: 17px;
    line-height: 1.25;
    font-weight: 600;
    padding: 4px 10px 4px 10px;
    font-feature-settings: "case" on;
    font-size: 13px;
    color: var(--text-color);
    display: flex;
    background-color: #f2f2f2;
    border: 0px solid var(--border-color);
}

 
.table td.btntbl {
    padding: 0px 5px  !important;
    width: 25px !important; 
}

.id {
    width: 25px !important;
    text-align: center !important;
}
 
.btns {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
}
 

 
.removeBtn { 
    
    border-radius: 5px;
    line-height: 1.25;
    font-weight: 400;
    padding: 2px 3px;
    font-size: 12px;
    color: var(--text-color);
    display: flex;
    align-items: center;
    border: 1px solid var(--border-color);
    justify-content: center;
    background-color: var(--secondary-background);
    cursor: pointer;
    transition: background-color 0.2s ease, border-color 0.2s ease;
}

.removeBtn:hover {
    background-color: var(--third-background);
}
 
 
.removeBtn svg {  
    height: 16px;
    width: 16px;
    margin-right: 3px;
    color: var(--secondary-text-color);
    fill:var(--secondary-text-color);
    transition: color 0.2s ease ;
}


.pricein {  
    color: var(--text-color);
    font-size: 13px;
    font-weight: bold;
}

.modalHead {
    padding: 1.5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.modalHead h3 {
    display: flex;
    align-items: center;
    color: var(--secondary-text-color);
    padding: 0;
    margin: 0;
    font-weight: 300;
    font-size: 1.125rem; 
    font-feature-settings: "case" on; 
}
 
.modalHead h3 span{
    height: 30px;
    width: 30px;
    border-radius: 999px;
    color:var(--secondary-text-color);
    background-color: var(--secondary-background);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}
 
.modalHead h3 span svg{
    height: 18px;
    width: 18px; 
}





 