.flex {
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    gap: 15px;
    flex-direction: row;
    flex-wrap: wrap;
}

.box4 {   
    flex: 1 1 calc(25% - 15px); 
} 

.box2 {   
    flex: 1 1 calc(50% - 15px); 
}


.boxWhite { 
    height: 100%;
    background-color: white;
    padding: 10px 15px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
}

.boxWhite h1 {
    color: var(--text-color);
    font-size: 16px;
    padding: 0;
    margin: 0;
    font-weight: 600;
    font-feature-settings: "case" on;

    display: block;
    width: 100%;
}

.boxWhite span {
    font-size: 34px;
    font-weight: bold;
    color: var(--primary-color);
    line-height: 1;
    display: block;
    width: 100%;
}

.chartBG {
    width: 100%;
    background-color: white;
    padding: 20px; 
    border: 1px solid var(--border-color);
    border-radius: 5px;
    display: inline-block;
}

.chartBG  h1 {
    color: var(--text-color);
    font-size: 16px;
    padding: 0;
    margin: 0;
    font-weight: 600;
    font-feature-settings: "case" on;
}

.mb15 {
    margin-bottom: 15px;
}

.mt15 {
    margin-top: 15px;
}


@media (max-width: 1025px) {
    
    .box4 {   
        flex: 1 1 calc(50% - 15px);
    }

    .box2 {   
        flex: 1 1 calc(100% - 15px);
    }
}  
 