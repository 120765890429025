.AddBtn { 
    border: 1px solid;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    cursor: pointer;
    align-items: center;
    font-feature-settings: "case" on;
    line-height: 1.25;
    font-weight: 600;
    padding: 0px 7px 0px 0px;
    font-size: 13px;
    overflow: hidden;
    color: var(--secondary-text-color);
    border-color: var(--border-color);
}

 
.AddBtn span.icon { 
    max-width: 28px;
    max-height: 28px;
    min-width: 28px;
    min-height: 28px;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
    display: flex;
    color: var(--secondary-text-color);
    background-color: var(--secondary-background);
}

.AddBtn span.icon svg {  
    max-width: 18px;
    max-height: 18px;
    min-width: 18px;
    min-height: 18px; 
}
 
  
 
.modalHead {
    padding: 1.5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
 
.modalHead h3 {
    display: flex;
    align-items: center;
    color: var(--text-color);
    padding: 0;
    margin: 0;
    font-weight: 600;
    font-size: 1.125rem; 
    font-feature-settings: "case" on; 
}
 
.modalHead h3 span{
    height: 30px;
    width: 30px;
    border-radius: 999px;
    color:var(--secondary-text-color);
    background-color:var(--secondary-background);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}
 
.modalHead h3 span svg{
    height: 18px;
    width: 18px; 
}
 


 

.groupInput {
    margin-bottom: 15px;
    position: relative;
}
 

.groupInput .input::placeholder {
    color: var(--placeholder-text-color);
    position: relative;
}

.groupInput label { 
    position: absolute;
    top: -8px;
    left: 8px;
    color: var(--muted-text-color);
    background-color: #fff;
    font-size: 12px;
    line-height: 1;
    padding: 0px 3px;
}
 

 
.input {  
    line-height: 1.25rem;
    color: var(--text-color);
    font-size: 13px;
    padding: 0 10px; 
    border: 1px solid var(--border-color);
    border-radius: 5px;
    width: 100%;
    height: 35px;
    background-color: rgb(255 255 255); 
    outline-color: transparent;
    outline-offset: 2px;
    outline-style: dashed;
    outline-width: 1px; 
    transition-duration: .3s; 
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}


.input::placeholder {
    color: var(--placeholder-text-color);
    position: relative;
}

.input:focus-within {  
    outline-color: var(--border-color);; 
}
 

.btnPrimary {
    margin-top: 20px;
    margin-left: auto;
    display: block;
    text-align: center;
    padding:  7px 20px;
    cursor: pointer;
    line-height: 1.25rem;
    color: #fff;
    font-size: .875rem; 
    border: none;
    border-radius: 0.3rem;
    width: 100%;
    max-width: 200px;
    /* height: 2.5rem; */ 
    font-feature-settings: "case" on;
    background-color: var(--primary-color);
    transition-duration: .3s;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}


.roles {
    position: relative;
    display: flex;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    padding: 8px;
    padding-top: 13px;
    gap: 8px;
    flex-direction: row;
    flex-wrap: wrap;
}

.roles li { 
    border-radius: 50px;
    line-height: 1.25;
    font-weight: 400;
    padding: 2px 3px;
    font-size: 12px;
    color: rgb(100 116 139); 
    background-color: rgb(241 245 249);
    cursor: pointer;
    transition: background-color 0.2s ease, border-color 0.2s ease;
}

.roles li:hover { 
    background-color: rgb(226 232 240);
}







.rolesSelect {
    position: relative; 
    margin-top: 25px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    padding: 8px;
    padding-top: 13px;  

}

.rolesSelect ul {  
    position: relative;
    display: flex;   
    gap: 8px;
    flex-direction: row;
    flex-wrap: wrap;
}

.rolesSelect ul li { 
    border-radius: 5px;
    line-height: 1.25;
    font-weight: 500;
    width: 100%;
    padding: 5px 5px;
    font-size: 13px;
    border: 1px solid var(--border-color);
    color: var(--text-color);
    background-color: var(--secondary-background);
    cursor: pointer;
    transition: background-color 0.2s ease, border-color 0.2s ease;
}

.rolesSelect ul  li.selected:hover,  
.rolesSelect ul  li.selected {  
    color: rgb(0, 150, 136);
    border-color: rgb(0, 150, 136);
    background: rgb(211, 233, 230);
    font-weight: 600;
}

.rolesSelect ul li:hover { 
    background-color: var(--third-background);
}



.rolesSelect label { 
    position: absolute;
    top: -6px;
    left: 7px;
    color: var(--muted-text-color);
    background-color: #fff;
    font-size: 12px;
    line-height: 1;
    padding: 0px 5px;
}

.errorInput {
    border-color: rgb(225, 29, 72); 
}

.errorMsg {
    color: rgb(225, 29, 72); 
    font-size: 10px;
    position: absolute;
    bottom: -3px;
    background: #fff;
    right: 11px;
    line-height: 1; 
}
