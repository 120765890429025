 .totalBox {
    display: flex;
    gap: 10px;
    overflow-x: auto;   
    scrollbar-width: none;  
 
}

.totalBox::-webkit-scrollbar { 
    display: none; 
    
}

 .financeBox { 
    position: relative;
    border-radius: 5px;
    color: var(--text-color);
    display: flex;
    background-color: rgb(255 255 255);
    border: 1px solid var(--border-color);
    align-items: center;
    cursor: pointer;  
    justify-content: space-between; 
}

.financeDesc {   
    font-feature-settings: "case" on;
    line-height: 1.25;
    font-weight: 600;
    padding: 4px 5px;
    font-size: 13px;
    color: var(--text-color);
    display: flex;
    position: relative;
    white-space: nowrap;
}

.financeDesc b {  
    margin-left: 5px;
}

.financeDesc.icon {  
    padding-left: 23px;
}

.financeDesc.icon svg {  
    position: absolute;
    top: 0;
    left: 4px;
    height: 17px;
    width: 17px;
}

.financeValue {  
    border-radius: 3px;
    line-height: 1.25;
    margin-right: 2px;
    font-weight: 700;
    padding: 2px 4px;
    font-size: 13px;
    color: rgb(255 255 255);
    display: flex;
    background-color: var(--bg-third-color);
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;

}

  

.financeBox.blueFin {
    color: #1254e7; 
    border-color: #1254e7; 
} 
.financeBox.blueFin .financeDesc {
    color: #1254e7; 
    border-color: #1254e7; 
} 
.financeBox.blueFin .icon svg{
    color: #1254e7; 
} 
.financeBox.blueFin .financeValue {
    background-color: #1254e7; 
}
 
.blueFin.iconHead {
    color: #1254e7; 
    background-color: rgb(18 84 231 / 15%); 
    border-color: #1254e7; 
}



.financeBox.greenFin {
    color: #009688; 
    border-color: #009688; 
} 
.financeBox.greenFin .financeDesc {
    color: #009688; 
    border-color: #009688; 
} 
.financeBox.greenFin .icon svg{
    color: #009688; 
} 
.financeBox.greenFin .financeValue {
    background-color: #009688; 
}

 
.greenFin.iconHead {
    color: #009688; 
    background-color: #d5ebe7; 
    border-color: #009688; 
}


 
.financeBox.redFin {
    color: #d80228; 
    border-color: #d80228; 
} 
.financeBox.redFin .financeDesc {
    color: #d80228; 
    border-color: #d32f2f; 
} 
.financeBox.redFin .icon svg{
    color: #d80228; 
} 
.financeBox.redFin .financeValue {
    background-color: #d80228; 
}
 
.redFin.iconHead {
    color: #d80228; 
    background-color: rgb(18 84 231 / 15%); 
    border-color: #d80228; 
}


.financeBox.yellFin {
    color: #df8805; 
    border-color: #df8805; 
} 
.financeBox.yellFin .financeDesc {
    color: #df8805; 
    border-color: #df8805; 
} 
.financeBox.yellFin .icon svg{
    color: #df8805; 
} 
.financeBox.yellFin .financeValue {
    background-color: #df8805; 
}


 

@media (max-width: 1025px) {
    .totalBox {   
        gap: 5px;
        overflow-x: auto; 
    }
}