.head{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    align-content: center;
    gap: 10px;
    align-items: stretch;
    justify-content: space-between;
}

.headMain {
    padding-top: 8px;
    padding-bottom: 6px;
    display: flex; 
    gap: 0.5rem;
    position: relative;
    align-items: center;
    justify-content: space-between;
}

.headMain h1 {
    color: var(--text-color);
    line-height: 1;
    font-weight: bold;
    font-size: 22px;
    font-feature-settings: "case" on;
    display: flex;
    align-items: center;
}
 
.pad0 {
    padding: 0 !important;
}


.id {
    width: 25px !important;
    text-align: center !important;
} 

.btntbl {
    padding: 0 10px !important;
    width: 25px !important; 
}

.btns {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
}