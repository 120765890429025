.containerBox {
    padding: 25px 15px 15px 15px;
    background-color: #fff; 
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid var(--border-color);
    min-height: unset;
}

.containerItem {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 0px 15px;
}
  
 
.groupInput { 
    flex: 1;
    position: relative;  
    box-sizing: border-box;
}

.groupInput > * {
    width: 100%;
}

 

.groupInput .input::placeholder {
    color: var(--placeholder-text-color);
    position: relative;
}

.groupInput label {  
    border-radius: 99px;
    width: auto;
    position: absolute;
    top: -9px;
    left: 5px;
    color: var(--muted-text-color);
    font-size: 11px;
    font-weight: 600;
    background-color: #fff;
    padding: 0px 2px;
    line-height: 1.2; 
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}


.groupInputPrice { 
    box-sizing: border-box;
    flex: 2;
    flex-grow: 2;
    position: relative;  
    display: flex;
    gap: 5px;
    align-items: center;
}

.input {   
    line-height: 1.25rem;
    color: var(--text-color);
    font-size: 13px;
    padding: 0 5px 0 5px; 
    border: 1px solid var(--border-color);
    border-radius: 5px;
    width: 100%;
    height: 30px;
    background-color: rgb(255 255 255); 
    outline-color: transparent;
    outline-offset: 2px;
    outline-style: dashed;
    outline-width: 1px; 
    transition-duration: .3s; 
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}

.groupBorder {   
    line-height: 1.25rem;
    color: var(--text-color);
    font-size: 13px;
    padding: 0 5px 0 5px;
    border: 1px solid var(--border-color);
    border-radius: 5px; 
    height: 30px;
    display: flex;
    background-color: rgb(255 255 255);
    align-items: center;
}

.input::placeholder {
    color: var(--placeholder-text-color);
    position: relative;
}

.input[disabled] {
    cursor: not-allowed;
 }

.input:focus-within {  
    border-color: var(--border-color); 
}

.groupInput .groupInputIcon {
    position: relative;
}

.groupInput .groupInputIcon .input { 
    padding-inline-start: 26px;
    padding-inline-end: 5px;
}

 
.groupInput .groupInputIcon .crmIcon {
    position: absolute;
    top: 0;
    color: #444;
    display: flex; 
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 28px;
    transition-duration: .3s; 
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}


.groupInput.dollar .groupInputIcon .input { 
    padding-inline-start: 17px;
    font-weight: 600; 
}

.groupInput.dollar .groupInputIcon .crmIcon {
    color: var(--text-color);
    display: flex;
    width: 17px;
    justify-content: flex-end;
}
 

.groupInput .groupInputIcon .crmIcon svg { 
    display: inline-block;
    vertical-align: middle;
    margin-left: px;
    height: 16px;
    width: 16px;
}
   
.groupInput.red { 
    color: #d80228;
    font-weight: bold;
    border-color: #d80228;
    background-color: #fef9f9;
} 

.groupInput.red .containerName { 
    color: #d80228;    
    background-color: #fef9f9;
} 

 

.groupInput.yellow .groupInputIcon .crmIcon svg{
    color: #df8805;
} 
.groupInput.yellow .groupInputIcon input{ 
    color: #df8805;
    font-weight: bold;
    border-color: #df8805;
    background-color: #fdfbf8;
} 

.groupInput.yellow label { 
    color: #df8805;    
    background-color: #fdfbf8;
} 

.groupInput.blue .groupInputIcon .crmIcon svg{
    color: #1254e7;
} 
.groupInput.blue .groupInputIcon input{ 
    color: #1254e7;
    font-weight: bold;
    border-color: #1254e7;
    background-color: #f2f4fe;
} 

.groupInput.blue label { 
    color: #1254e7;    
    background-color: #f2f4fe;
} 



.groupInput.red .groupInputIcon .crmIcon svg{
    color: #d80228;
} 
.groupInput.red .groupInputIcon input{ 
    color: #d80228;
    font-weight: bold;
    border-color: #d80228;
    background-color: #fef9f9;
} 

.groupInput.red label { 
    color: #d80228;    
    background-color: #fef9f9;
} 


.groupInput.yellow .groupInputIcon .crmIcon svg{
    color: #df8805;
} 
.groupInput.yellow .groupInputIcon input{ 
    color: #df8805;
    font-weight: bold;
    border-color: #df8805;
    background-color: #fdfbf8;
} 

.groupInput.yellow label { 
    color: #df8805; 
    background-color: #fdfbf8;
} 

 
 

.btnPrimary {  
    display: block;
    text-align: center;
    padding:  5px 5px;
    cursor: pointer;
    line-height: 1.25rem;
    color: #fff;  
    font-size: 14px; 
    font-weight: 600; 
    border: none;
    border-radius: 5px;
    width: 100%;
    max-width: 200px; 
    font-feature-settings: "case" on;
    background-color: var(--primary-color); 
}
 

.YesBtn {
    display: block;
    text-align: center;
    padding:  5px 5px;
    cursor: pointer;
    line-height: 1.25rem; 
    font-size: 14px; 
    font-weight: 600; 
    border: none;
    border-radius: 5px;
    width: 100%;
    max-width: 200px; 
    font-feature-settings: "case" on;
 
    background-color: #d1e7dd;
    color: rgba(25,135,84);   
    cursor: pointer;
}


.CancelBtn {
    display: block;
    text-align: center;
    padding:  5px 5px;
    cursor: pointer;
    line-height: 1.25rem; 
    font-size: 14px; 
    font-weight: 600; 
    border: none;
    border-radius: 5px;
    width: 100%;
    max-width: 200px; 
    font-feature-settings: "case" on;
    color: rgb(216, 2, 40);
    background: rgb(255, 223, 216);
    cursor: pointer;
}


 
.errorInput .input {
    border-color: rgb(225, 29, 72); 
}
 
.errorInput label {
    color: rgb(225, 29, 72); 
}

.errorMsg {
    color: rgb(225, 29, 72); 
    font-size: 10px;
    position: absolute;
    bottom: -3px;
    background: #fff;
    right: 11px;
    line-height: 1; 
}
 

.groupPrice { 
    display: flex;  
    padding: 4px 6px;
    margin: 0 -6px;
    border-radius: 8px; 
    transition: background-color 0.2s ease ;
}
 

.groupPrice .leftLabel { 
    width:  70%;
    font-size: .825rem;
    display: flex;
    color: rgb(100 116 139);
    align-content: center;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 8px;
}


.groupPrice .rightInput {
    width: 30%;
}
 
.dataContainer {
    width: 100%;
}

.dataContainer .input {
    padding-inline-start: 26px;
    padding-inline-end: 10px;
    background-repeat: no-repeat;
    background-size: 14px;
    background-position: 6px center;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="28" height="32" viewBox="0 0 14 16"><path fillRule="evenodd" d="M13 2h-1v1.5c0 .28-.22.5-.5.5h-2c-.28 0-.5-.22-.5-.5V2H6v1.5c0 .28-.22.5-.5.5h-2c-.28 0-.5-.22-.5-.5V2H2c-.55 0-1 .45-1 1v11c0 .55.45 1 1 1h11c.55 0 1-.45 1-1V3c0-.55-.45-1-1-1zm0 12H2V5h11v9zM5 3H4V1h1v2zm6 0h-1V1h1v2zM6 7H5V6h1v1zm2 0H7V6h1v1zm2 0H9V6h1v1zm2 0h-1V6h1v1zM4 9H3V8h1v1zm2 0H5V8h1v1zm2 0H7V8h1v1zm2 0H9V8h1v1zm2 0h-1V8h1v1zm-8 2H3v-1h1v1zm2 0H5v-1h1v1zm2 0H7v-1h1v1zm2 0H9v-1h1v1zm2 0h-1v-1h1v1zm-8 2H3v-1h1v1zm2 0H5v-1h1v1zm2 0H7v-1h1v1zm2 0H9v-1h1v1z" fill="rgb(68 68 68)"/></svg>');
}

.dataContainer .errorVal .input {
    border-color: #d80228;
}

.dataContainer .reciveAnput {  
    padding-inline-end: 5px;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="rgb(68 68 68)" d="m20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8l8-8z"/></svg>') !important;   

}

.dataContainer .arriveAnput {  
    padding-inline-end: 5px; 
    background-image:  url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="rgb(68 68 68)" d="M6.4 18L5 16.6L14.6 7H6V5h12v12h-2V8.4L6.4 18Z"/></svg>') !important;   
}
 
  

.AddBtnCargo {  
    display: flex;
    cursor: pointer;
    align-items: center; 
    overflow: hidden;
    color: #fff;
    text-align: center;
    justify-content: center;
    gap: 5px;
}

.AddBtnCargo > span{ 
    border: 1px solid var(--secondary-text-color);
    background-color: #fff;
    border-radius: 99px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    font-size: 12px;
    text-align: center;
    height: 17px;
    width: 17px;
    color: var(--secondary-text-color);
}

.AddBtnCargo > span svg{  
    min-height: 15px;
    min-width: 15px;
    max-height: 15px;
    max-width: 15px;
}
 
.AddBtnCargo > span.removeBtn {
    color: #dc2626;
    border-color: #dc2626;
    padding: auto;
}

.AddBtnCargo > span.removeBtn svg {
    min-height: 12px;
    min-width: 12px;
    max-height: 12px;
    max-width: 12px;

}
 
.cancelClient {
    position: absolute;
    left: -9px;
    top: 8px;
    height: 12px;
    width: 12px;
    border-radius: 99px;
    color: #fff;
    display: flex;
    background-color: #dc2626;
    align-items: center;
    justify-content: center;
    z-index: 99;
    cursor: pointer;
}

.cancelClient svg {
     width: 10px; 
     height: 10px; 
}

 
.searchClient {
    position: absolute;
    z-index: 1;
    background: #fff;
    border: 1px solid rgb(203 213 225);
    padding: 5px 0px;
    border-radius: 5px;
    width: 100%;
    top: 100%;
    left: 1px;
    max-width: 254px;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
    border-color: var(--border-color);
}

.searchClient > div { 
    cursor: pointer;
    padding: 8px 9px;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    line-height: 1;
    color: var(--text-color);
 }

.searchClient > div:last-child {  
    border-color: transparent;
 }

.searchClient > div:hover {   
    background-color: var(--secondary-background);
 }
 

 .table table thead{
    position: relative;
    z-index: 0;
 }

 .table table thead tr th {
    padding: 2px 5px;
    white-space: nowrap;
    text-align: start;
 }

 .table table tbody tr td {
    padding: 2px 2px;
 }

 .table table tbody tr td .input {
    height: 25px;
 }



  

@media (max-width: 1025px) {

    .containerItem {  
        flex-direction: row;
        flex-wrap: wrap;
    }

    .containerFlex {
        flex: unset;
        width: calc(50% - 5px);
    }

    .containerBox .table {
        overflow: scroll !important;
    }
    .groupInput {
        flex: unset;
        width: calc(50% - 5px);
    }

}
