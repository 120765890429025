.AddBtn { 
    border: 1px solid;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    cursor: pointer;
    align-items: center;
    font-feature-settings: "case" on;
    line-height: 1.25;
    font-weight: 600;
    padding: 0px 7px 0px 0px;
    font-size: 13px;
    overflow: hidden;
    color: var(--secondary-text-color);
    border-color: var(--border-color);
}

 
.AddBtn span.icon { 
    max-width: 28px;
    max-height: 28px;
    min-width: 28px;
    min-height: 28px;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
    display: flex;
    color: var(--secondary-text-color);
    background-color: var(--secondary-background);
}

.AddBtn span.icon svg {  
    max-width: 18px;
    max-height: 18px;
    min-width: 18px;
    min-height: 18px; 
}



 
.modalHead {
    padding: 1.5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
 
.modalHead h3 {
    display: flex;
    align-items: center;
    color: var(--text-color);
    padding: 0;
    margin: 0;
    font-weight: 600;
    font-size: 1.125rem; 
    font-feature-settings: "case" on; 
}
 
.modalHead h3 span{
    height: 30px;
    width: 30px;
    border-radius: 999px;
    color:var(--secondary-text-color);
    background-color:var(--secondary-background);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}
 
.modalHead h3 span svg{
    height: 18px;
    width: 18px; 
}
 



  



.groupInput {
    margin-bottom: 15px;
    position: relative;
}




.input {  
    line-height: 1.25rem;
    color: var(--text-color);
    font-size: 13px;
    padding: 0 10px; 
    border: 1px solid var(--border-color);
    border-radius: 5px;
    width: 100%;
    height: 35px;
    background-color: rgb(255 255 255); 
    outline-color: transparent;
    outline-offset: 2px;
    outline-style: dashed;
    outline-width: 1px; 
    transition-duration: .3s; 
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}

.input::placeholder {
    color: var(--placeholder-text-color);
    position: relative;
}
  
.input:focus-within {  
    outline-color: var(--border-color);; 
}
 
   
.btnPrimary {
    margin-top: 20px;
    margin-left: auto;
    display: block;
    text-align: center;
    padding:  7px 20px;
    cursor: pointer;
    line-height: 1.25rem;
    color: #fff;
    font-size: .875rem; 
    font-weight: 600; 
    border: none;
    border-radius: 0.3rem;
    width: 100%;
    max-width: 200px;
    /* height: 2.5rem; */ 
    font-feature-settings: "case" on;
    background-color: var(--primary-color);
    transition-duration: .3s;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}
 
.errorInput {
    border-color: rgb(225, 29, 72); 
}

.errorMsg {
    color: rgb(225, 29, 72); 
    font-size: 10px;
    position: absolute;
    bottom: -3px;
    background: #fff;
    right: 11px;
    line-height: 1; 
}
  

.formField {  
    width: 100%; 
    background-color: #fff;  
    position: relative; 
    height: 100%;
    margin-bottom: 20px;
    margin-top: 6px;
    padding: 0;
}

.formField legend { 
    position: absolute;
    font-size: 11px;
    font-weight: 500;
    color: var(--muted-text-color);
    line-height: 1;
    margin-bottom: 4px;
    top: -6px;
    z-index: 1;
    left: 8px;
    padding: 0 3px;
    background: #fff;
    font-weight: 500; 
}
 

.uploadDiv {
    cursor: pointer;
    color: var(--text-color);
    border: 1px solid var(--border-color);
    border-radius: 0.3rem;
    width: 100%;
    height: 32px;
    background-color: var(--secondary-background);
}


.uploadDiv input {
    cursor: pointer;
    color: var(--text-color);
    width: 100%;
    height: 100%;
    padding: 4px;
}

.uploadDiv input::file-selector-button:hover {
    background-color: var(--secondary-background);
}
  
.uploadDiv input::file-selector-button:active {
    background-color: var(--secondary-background);
}
  