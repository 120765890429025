.SearchBg {
    inset: 0;
    position: fixed;
    padding: 1rem;
    padding-top: 5rem;
    display: flex; 
    justify-content: center;
    align-items: flex-start!important;
    min-height: 100%;  
    z-index: 999;
}

.closeBG {
    position: absolute;
    background-color: rgb(30 41 59/.7);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.SearchModal { 
    box-shadow:  0 0 #0000, 0 0 #0000,0 20px 25px -5px rgba(0,0,0,.1),0 8px 10px -6px rgba(0,0,0,.1); 
    background-color: #fff;
    max-width: 28rem;
    width: 100%;
    padding: 24px;
    min-height: 180px;   
    transform: translateY(-20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    border-radius: 0.75rem;
}

.searchLabel { 
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.searchLabel .commandKey {
    opacity: .8;
    font-size: .75rem;
    line-height: 1rem;
    color: rgb(148, 163, 184);
}

.SearchModal.show, 
.show {
    opacity: 1; 
    transform: translateY(0);
}

.resultBox {
    margin: 20px 0;
    margin-bottom: 0;
}

.resultBox ul {
    list-style: none;
    margin: 0px -12px;
    padding: 0;
}

.resultBox ul li a { 
    padding: 0.75rem;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    font-size: .875rem;
    line-height: 1.25rem;
    color: rgb(71 85 105 );
}

.resultBox ul li a:hover {
    color: rgb(71 85 105 );
    background-color: rgb(248 250 252);
}

.resultBox .resultHead {
    font-size: .65rem;
    font-feature-settings: "case" on;
    text-transform: uppercase;
    color: rgb(100 116 139);
}