.head{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    align-content: center;
    gap: 10px;
    align-items: stretch;
    justify-content: space-between;
}

.headMain {
    padding-top: 8px;
    padding-bottom: 0;
    display: flex; 
    gap: 0.5rem;
    position: relative;
    align-items: center;
    justify-content: space-between;
}

.headMain h1 {
    color: var(--text-color);
    line-height: 1;
    font-weight: bold;
    font-size: 22px;
    font-feature-settings: "case" on;
    display: flex;
    align-items: center;
}
 
 
.pad0 {
    padding: 0 !important;
}
.btntbl {
    padding: 0 10px !important;
    width: 25px !important; 
}

.id {
    width: 25px !important;
    text-align: center !important;
}
 
.btns {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
}


.btns .btn { 
    border-radius: 5px;
    line-height: 1.25;
    font-weight: 500;
    padding: 2px 3px;
    display: flex;
    font-size: 12px;
    color: var(--text-color);
    background-color: var(--secondary-background);
    border: 1px solid var(--border-color);
    cursor: pointer;
    transition: background-color 0.2s ease, border-color 0.2s ease;
    align-items: center;
}

.btns .btn:hover { 
    background-color: var(--third-background);
}
 
.btns .btn svg {  
    height: 16px;
    width: 16px;
    margin-right: 3px;
    color: var(--secondary-text-color);
    fill: var(--secondary-text-color);
    transition: color 0.2s ease ;
}



.online {
    display: flex;
    align-items: center;
    border-radius: 5px;
    gap: 5px;
    font-weight: 600;
    background-color: rgb(211, 233, 230);
    color: rgb(0, 150, 136);
    justify-content: center;
}

.online::before { 
    content: '';
    height: 8px;
    width: 8px;
    border-radius: 55px;
    background-color: rgb(0, 150, 136);
}


@media (max-width: 1025px) { 
    .headMain h1 {  
        font-size: 16px;  
    }
}