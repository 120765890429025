.head{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    align-content: center;
    gap: 10px;
    align-items: stretch;
    justify-content: space-between;
}

.headMain {
    padding-top: 8px;
    padding-bottom: 6px;
    display: flex; 
    gap: 0.5rem;
    position: relative;
    align-items: center;
    justify-content: space-between;
}

.headMain h1 {
    color: var(--text-color);
    line-height: 1;
    font-weight: bold;
    font-size: 22px;
    font-feature-settings: "case" on;
    display: flex;
    align-items: center;}
 
  
.redTable > td{ 
    /* color: rgb(183, 29, 24) !important;  */
     background-color: rgba(255, 86, 48, 0.16) !important;
     border-color: rgba(255, 86, 48, 0.35) !important;
 }