.bgLight {
    background-color: var(--layout-background);
    min-height: 100vh; 
}

.main {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-inline-start: 200px;
    max-width: calc(100% - 200px); 
    width: 100%;
    position: relative; 
  

}

.main.containerBig {  
    margin-inline-start: 200px;
    max-width: calc(100% - 200px);
    
}

.inner {
   /* max-width: 80rem;*/
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.width100 {
    max-width: 100%; 
}

.head {
    display: flex;
    height: 4rem;
    gap: 0.5rem;
    position: relative; 
    align-items: center;
}

.head h1 {
   color: rgb(30 41 59);
   line-height: 1.5;
   font-weight: 300;
   font-size: 1.5rem; 
   font-feature-settings: "case" on;
}

.head .marginAuto{ 
    margin-inline-start: auto;
}
 
.headNav ul  { 
    display: flex;
    gap: 0.5rem;
}
.headNav ul li a{ 
    transition-duration: .3s;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    border: 1px solid rgb(226 232 240);
    border-radius: 9999px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.25rem;
    height: 2.25rem;
    background: rgb(255 255 255);
}


.headNav ul li a svg{ 
    color: rgb(148 163 184);
    width: 1.25rem;
    height: 1.25rem;
}




@media (max-width: 1025px) { 
    .main {  
        margin-inline-start: 0;
        max-width: 100%; 
        width: 100%;   
        padding-top: 45px; 
        padding-left: 15px;
        padding-right: 15px;
    }  
}  
 