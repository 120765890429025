.head{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    align-content: center;
    gap: 10px;
    align-items: stretch;
    justify-content: space-between;
}

.headMain {
    padding-top: 8px;
    padding-bottom: 6px;
    display: flex; 
    gap: 0.5rem;
    position: relative;
    align-items: center;
  /*  justify-content: space-between; */
}

.headMain h1 {
    color: var(--text-color);
    line-height: 1;
    font-weight: bold;
    font-size: 22px;
    font-feature-settings: "case" on;
    display: flex;
    align-items: center;
}


 
.row {
    --bs-gutter-x: 10px;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y)* -1);
    margin-right: calc(var(--bs-gutter-x)* -.5);
    margin-left: calc(var(--bs-gutter-x)* -.5);
}

.row>* {
    --bs-gutter-x: 10px;
    --bs-gutter-y: 0;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x)* .5);
    padding-left: calc(var(--bs-gutter-x)* .5);
    margin-top: var(--bs-gutter-y);
}

.col {
    flex: 0 0 auto;
    width: 33.333333%;
}

.taskBody {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    flex-direction: column;
}

.taskBox {
    background-color: #fff;
    border: 1px solid var(--border-color);
    border-left: 3px solid rgb(0 0 0 / 40%);
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 15px 10px 10px 10px;
    min-height: 136px;
}

.statusDone { 
    border-left: 3px solid #37b437; 
}

.taskTitle {    
   
    display: flex;
    position: relative;
    white-space: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.taskTitle h1 {   
    font-feature-settings: "case" on;
    line-height: 1;
    font-weight: 600;
    font-size: 16px;
    color: var(--text-color); 
    white-space: nowrap; 
    margin: 0;
}
 
.taskContent { 
    font-size: 13px;
    color: var(--text-color);
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
 

 
.btns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    flex-direction: row;
}





.badge { 
    border-radius: 50px;
    line-height: 1;
    font-weight: 500;
    padding: 2px 10px 2px 5px;
    display: flex;
    font-size: 12px;
    color: var(--text-color);
    background-color: #fff;
    border: 1px solid var(--border-color-table); 
    align-items: center;
}
 
 
.badge .cricle {  
    height: 10px;
    width: 10px;
    margin-right: 3px;
    color: var(--secondary-text-color);
    fill: var(--secondary-text-color);
    transition: color 0.2s ease ;
    border-radius: 16px;
}
 
.badge .icon {   
    margin-right: 3px; 
} 

.badge .icon svg {  
    height: 16px;
    width: 16px; 
    color: #333;
    fill: #333; 
}
 
.badge .icon.done svg {    
    color: #37b437;
    fill: #37b437; 
}
  


@media (max-width: 1425px) { 


    .col {
        flex: 0 0 auto;
        width: 50%;
    }
}


@media (max-width: 1025px) { 


    .col {
        flex: 0 0 auto;
        width: 100%;
    }
}