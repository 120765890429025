.AddBtn { 
    border: 1px solid;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    cursor: pointer;
    align-items: center;
    font-feature-settings: "case" on;
    line-height: 1.25;
    font-weight: 500;
    padding: 0px 7px 0px 0px;
    font-size: 13px;
    overflow: hidden;
    color: var(--secondary-text-color);
    border-color: var(--border-color);
}

 
.AddBtn span.icon { 
    max-width: 28px;
    max-height: 28px;
    min-width: 28px;
    min-height: 28px;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
    display: flex;
    color: var(--secondary-text-color);
    background-color: var(--secondary-background);
}

.AddBtn span.icon svg {  
    max-width: 18px;
    max-height: 18px;
    min-width: 18px;
    min-height: 18px; 
}

.upcase {
    text-transform: lowercase;
}


  
.editBtn { 
    border-radius: 5px;
    line-height: 1.25;
    font-weight: 500;
    padding: 2px 3px;
    display: flex;
    font-size: 12px;
    color: var(--text-color);
    background-color: var(--secondary-background);
    border: 1px solid var(--border-color);
    cursor: pointer;
    transition: background-color 0.2s ease, border-color 0.2s ease;
    align-items: center;
}

.editBtn:hover { 
    background-color: var(--third-background);
}
 
.editBtn svg {  
    height: 16px;
    width: 16px;
    margin-right: 3px;
    color: var(--secondary-text-color);
    fill: var(--secondary-text-color);
    transition: color 0.2s ease ;
}
 

.container { display: flex; flex-wrap: wrap; 
     flex: 0 0 30%; justify-content:space-between;} 
.box {background: #efefef;
     width: 30%; border:1px solid #fff; height: 150px; }


.head3item {   
    position: relative; 
    display: flex;
    flex-wrap: wrap;
    margin-right: -0.5rem;
    margin-left: -0.5rem;
}

.head3item .cols {  
    flex-shrink: 0;  
    flex: 0 0 auto; 
    width: 33.33333333%; 
    max-width: 100%;   
    padding-right: 0.5rem;
    padding-left: 0.5rem;

}

.formField {  
    width: 100%;
    padding: 20px 13px 2px 13px;
    border: 1px solid;
    border-color: var(--border-color);
    background-color: #fff;
    border-radius: 8px;
    position: relative;
    height: 100%;
    margin: 0;
}


.formField legend {
    position: absolute;
    top: -14px;
    left: 10px ; 
    border-radius: 17px;
    line-height: 1.25;
    font-weight: 600;
    padding: 4px 10px 4px 10px;
    font-feature-settings: "case" on;
    font-size: 13px;
    color: var(--text-color);
    display: flex;
    background-color: #f2f2f2;
    border: 0px solid var(--border-color);
}



.input {  
    line-height: 1.25rem;
    color: var(--text-color); 
    font-size: 13px;
    font-weight: 500;
    padding: 0.5rem; 
    border: 1px solid var(--border-color);
    border-radius: 5px;
    width: 100%;
    height: 35px;
    background-color: rgb(255 255 255); 
    outline-color: transparent;
    outline-offset: 2px;
    outline-style: dashed;
    outline-width: 1px; 
    transition-duration: .3s; 
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}


.input::placeholder {
    color: var(--placeholder-text-color);
    position: relative;
}

.input:focus-within {  
    outline-color: rgb(203 213 225/1); 
}


.groupInput {
    margin-bottom: 12px;
    position: relative;
}
 

.groupInput .input::placeholder {
    color: var(--placeholder-text-color);
    position: relative;
}

.groupInput label { 
    color: var(--text-color);
    font-size: .825rem;
}
 

.groupInput .groupInputIcon {
    position: relative;
}

.groupInput .groupInputIcon .input { 
    padding-inline-start: 28px;
    padding-inline-end: 10px;
}

.groupInput .groupInputIcon .crmIcon {
    position: absolute;
    top: 0;
    color: var(--secondary-text-color);
    display: flex; 
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 28px;
    transition-duration: .3s; 
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}

.groupInput .groupInputIcon:focus-within .crmIcon {
     color: #235e82; 
}

.groupInput .groupInputIcon .crmIcon svg { 
    display: inline-block;
    vertical-align: middle;
    height: 17px;
    width: 17px;
    color: var(--muted-text-color);
    fill: var(--muted-text-color); 
}
 

.inp2colCar {
    margin-bottom: 12px;
    display: flex;
    gap: 10px;
}

.inp2colCar > * {
    width: 100%;
}


.inp2col { 
    display: flex;
    gap: 10px;
}

.inp2col > * {
    width: 100%;
}
 
.modalHead {
    padding: 1.5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
 
.modalHead h3 {
    display: flex;
    align-items: center;
    color: var(--text-color);
    padding: 0;
    margin: 0;
    font-weight: 700;
    font-size: 1.125rem;
    font-feature-settings: "case" on;
}
 
.modalHead h3 span{
    height: 30px;
    width: 30px;
    border-radius: 999px;
    color: var(--secondary-text-color);
    background-color: var(--secondary-background);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}
 
.modalHead h3 span svg{
    height: 18px;
    width: 18px; 
}

.comTitle {
    display: flex;
    width: 100%;
    height: 100%;
    font-size: 13px;
    font-weight: 500;
    color: var(--text-color);
    align-items: center;;
}

 

.dataContainer {
    width: 100%;
}

.dataContainer .input {
    padding-inline-start: 30px;
    padding-inline-end: 10px;
    background-repeat: no-repeat;
    background-size: 17px;
    background-position: 6px center;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="28" height="32" viewBox="0 0 14 16"><path fillRule="evenodd" fill="rgb(139 142 143)" d="M13 2h-1v1.5c0 .28-.22.5-.5.5h-2c-.28 0-.5-.22-.5-.5V2H6v1.5c0 .28-.22.5-.5.5h-2c-.28 0-.5-.22-.5-.5V2H2c-.55 0-1 .45-1 1v11c0 .55.45 1 1 1h11c.55 0 1-.45 1-1V3c0-.55-.45-1-1-1zm0 12H2V5h11v9zM5 3H4V1h1v2zm6 0h-1V1h1v2zM6 7H5V6h1v1zm2 0H7V6h1v1zm2 0H9V6h1v1zm2 0h-1V6h1v1zM4 9H3V8h1v1zm2 0H5V8h1v1zm2 0H7V8h1v1zm2 0H9V8h1v1zm2 0h-1V8h1v1zm-8 2H3v-1h1v1zm2 0H5v-1h1v1zm2 0H7v-1h1v1zm2 0H9v-1h1v1zm2 0h-1v-1h1v1zm-8 2H3v-1h1v1zm2 0H5v-1h1v1zm2 0H7v-1h1v1zm2 0H9v-1h1v1z"  /></svg>');
}

.dataContainer .errorVal .input {
    border-color: #d80228;
}

.dataContainer .reciveAnput {  
    padding-inline-end: 5px;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="rgb(139 142 143)" d="m20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8l8-8z"/></svg>') !important;   

}

.dataContainer .arriveAnput {  
    padding-inline-end: 5px; 
    background-image:  url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="rgb(139 142 143)" d="M6.4 18L5 16.6L14.6 7H6V5h12v12h-2V8.4L6.4 18Z"/></svg>') !important;   
}

 

.btnPrimary {
    margin-top: 20px;
    display: block;
    text-align: center;
    padding:  7px 20px;
    cursor: pointer;
    line-height: 1.25rem;
    color: #fff;
    font-size: .875rem; 
    font-weight: 600; 
    border: none;
    border-radius: 0.3rem;
    width: 100%;
    max-width: 200px;
    /* height: 2.5rem; */
    font-feature-settings: "case" on;
    background-color: var(--primary-color);
    transition-duration: .3s;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}

 
.searchClient {
    position: absolute;
    z-index: 99;
    background: #fff;
    border: 1px solid var(--border-color);
    padding: 5px 0px;
    border-radius: 5px;
    width: 100%; 
    box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;   
    border-color: rgb(226 232 240); 
}

.searchClient > div { 
    cursor: pointer;
    padding: 8px 9px;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    font-size: .875rem;
    line-height: 1;
    color: var(--text-color); 
 }

.searchClient > div:last-child {  
    border-color: transparent;
 }

.searchClient > div:hover {   
    background-color: rgb(241 245 249);
 }
 
.selectedClientReset {
    cursor: pointer;
    top: -8px;
    right: -8px;
    position: absolute;
    height: 20px;
    width: 20px;
    border-radius: 999px;
    color: #d80228;
    background-color: #fbd8da;
    display: flex;
    align-items: center;
    justify-content: center;

}
 
.selectedClientReset svg { 
    width: 13px;
    height: 13px;
}
 
.errorBox {
    position: fixed;
    right: 20px;
    top: 20px;
}
 
.errorBox .errorClass {  
    background-color: #fbd8da;
}


.border-danger-500 {
    --tw-border-opacity: 1!important;
    border-color: #f43f5e!important;
    border-color: rgb(244 63 94/var(--tw-border-opacity))!important;
}

.errorMsg {
    color: rgb(225, 29, 72); 
    font-size: 10px;
    position: absolute;
    bottom: -3px;
    background: #fff;
    right: 11px;
    line-height: 1;

}

.errorInput {
    border-color: rgb(225, 29, 72); 
}


.groupPrice { 
    display: flex;  
    padding: 2px 0px;
    margin: 0 -6px;
    border-radius: 8px; 
    transition: background-color 0.2s ease ;
}

.groupPrice:focus-within,
.groupPrice:hover {  
    background-color: #f1f5f9;
}
  
.groupPrice .leftLabel { 
    width:  63%;
    font-size: .825rem;
    display: flex;
    font-size: 12px;
    color: var(--secondary-text-color);
    align-content: center;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 8px;
}


.groupPrice .rightInput {
    width: 37%;
} 



.inputDownPort {  
    line-height: 1.2;
    color: var(--text-color); 
    font-size: 13px;
    font-weight: 500;
    padding: 0.5rem; 
    border: 1px solid var(--border-color);
    border-radius: 5px;
    width: 100%;
    height: 35px;
    background-color: rgb(255 255 255); 
    outline-color: transparent;
    outline-offset: 2px;
    outline-style: dashed;
    outline-width: 1px; 
    transition-duration: .3s; 
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}

.bigtran {
    position: relative;
}

.horTitle { 
    position: absolute;
    font-size: 11px;
    font-weight: 500;
    color: var(--muted-text-color);
    line-height: 1;
    margin-bottom: 4px;
    top: -6px;
    z-index: 1;
    left: 8px;
    padding: 0 3px;
    background: #fff;
}


@media (max-width: 700px) { 
    .AddBtn { 
        padding: 0px; 
    }

    .AddBtn span.icon {  
        background-color: #fff;
        margin-right: 0;
    }
    
    .AddBtn span.text { 
    display: none;
    }
 
}


@media (max-width: 1025px) {
   
   
    .head3item .cols {   
        width: 50%;   
        margin-bottom: 25px;
    }
 
}


@media (max-width: 673px) { 
   
    .head3item .cols {
        width: 100%;   
        margin-bottom: 25px;
    }
 
}