 
.item{  
    border: 1px solid var(--border-color);  
    background-color: #fff;
    border-radius: 0.375rem;
    margin-bottom: 10px;
    display: flex;
    align-items: stretch;
    flex-direction: row;
    flex-wrap: wrap; 
    transition: all 200ms ease;
}
 
.item:hover{  
    /*    
        border: 1px solid var(--primary-color);
        box-shadow: 0 1px 2px 0 rgba(0,0,0,.1), 0 0 1px rgba(0,0,0,.15);
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); 
    */
}


.salerFinance {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 28%;
    max-width: 28%;
    min-width: 28%;
    padding: 0px 0px;
    gap: 0px;
    position: relative;
    justify-content: space-between;
}

.upcase {
    text-transform: uppercase;
}
.Car {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 29%;
    max-width: 29%;
    min-width: 29%;
    padding: 7px;
    position: relative;
    justify-content: space-between;
}

.Car::after { 
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 1px;
    height: calc(100% + 0px);
    background-color: var(--border-color);
} 

.carDesc {
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    max-height: 74px;
    overflow: hidden;
    width: 100%;
}

 

.carName {   
    font-size: 14px;
    font-weight: 700;
    color: var(--text-color);
    line-height: 1.25;
    margin-bottom: 6px;
    margin-top: 2px;
    text-transform: uppercase;
    font-feature-settings: "case" on;
    overflow: hidden;
    display: -webkit-box;
    min-height: 15px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
 
 
.carDetail {   
    display: flex;
    align-items: stretch;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 4px 5px;
    overflow: hidden;
    max-height: 50px;
}
  
 
.carDetail ul {   
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px 5px;
}
  
.carDetail ul li {   
    border-radius: 5px;
    line-height: 1.25;
    font-weight: 500;
    padding: 2px 5px;
    font-size: 13px;
    color: var(--text-color);
    display: flex;
    background-color: var(--secondary-background);
    border: 1px solid rgba(0, 0, 0, 0.05);
    transition: background-color 0.3s ease;
}
  
.carDetail ul li:hover {    
    background-color: var(--third-background);
}
 
.carDetail ul li img {
    height: 16px;
    width: 16px;
    margin-right: 5px; 
}
 
 
.carDetail ul li svg {
    height: 16px;
    width: 16px;
    margin-right: 5px;
    color: var(--secondary-text-color);
    fill: var(--secondary-text-color);
}


.dNone {
    display: none !important;
}

 
.client {
    padding: 7px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 24%;
    max-width: 24%;
    min-width: 24%;
    position: relative;
}

.client::after { 
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 1px;
    height: calc(100% + 0px);
    background-color: var(--border-color);
}


.clientName {   
    font-size: 14px;
    font-weight: 700;
    color: var(--text-color);
    line-height: 1.25;
    margin-bottom: 6px;
    margin-top: 2px;
    text-transform: uppercase;
    font-feature-settings: "case" on;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.clientDetail {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 4px 5px;
    overflow: hidden;
    max-height: 50px;
}
  
.clientDesc {
    width: 100%; 
}

.clientDesc ul {   
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px 5px;
}
  
.clientDesc ul li {   
    border-radius: 5px;
    line-height: 1.25;
    font-weight: 500;
    padding: 2px 5px;
    font-size: 13px;
    color: var(--text-color);
    display: flex;
    background-color: var(--secondary-background);
    border: 1px solid rgba(0, 0, 0, 0.05);
    transition: background-color 0.3s ease;
}
 
  
 
.clientDesc ul li img {
    height: 16px;
    width: 16px;
    margin-right: 3px; 
}
 
.clientDesc ul li svg {
    height: 16px;
    width: 16px;
    margin-right: 5px;
    color: var(--secondary-text-color);
    fill: var(--secondary-text-color);}
 

.greenBg {
    background-color: #14b8a633 !important;
    border-color:  #14b8a6 !important; 
    color: #14b8a6 !important;
}
 

.greenBg svg {
    color: #14b8a6 !important;
    fill: #14b8a6 !important; 
}

.tranBox {
    width: 19%;
    max-width: 19%;
    min-width: 19%;
    display: flex;
    flex-direction: column;
    position: relative;
}


.tranBox::after { 
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 1px;
    height: calc(100% + 0px);
    background-color: var(--border-color);
} 

 

.transport {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    padding: 7px;
    border-bottom: 1px solid var(--border-color);
}

.locationSender { 
    display: flex;
    flex-direction: row;
    align-items: center;
}

.locationSender img {
    width: 30px;
    height: 30px;
}




.portNameSender  {
    font-size: 14px;
    color: rgb(100 116 139);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    line-height: 1.25;
}

.portCountry {
    color: var(--text-color);
    font-size: 13px;
    line-height: 1;
}

.portCity {
    font-size: 12px;
    color: rgb(100 116 139); 
}

 
.locationReciever {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.locationReciever img { 
    width: 30px;
    height: 30px;
}
  
.portNameReciever  {
    font-size: 14px;
    color: rgb(100 116 139);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}


.tranDesc {
    flex-grow: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
}


.tranDesc ul {   
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 3px;
    min-height: 13px;
    justify-content: center;
}
  
.tranDesc ul li {   
    border-radius: 5px;
    line-height: 1.25;
    font-weight: 400;
    padding: 0 5px 0 2px;
    font-size: 13px;
    color: var(--text-color);
    display: flex;
    background-color: var(--secondary-background);
    border: 1px solid rgba(0, 0, 0, 0.05);
}
 
.tranDesc ul li img {
    height: 16px;
    width: 16px;
    margin-right: 3px; 
}
 
 
.tranDesc ul li svg {
    height: 16px;
    width: 16px;
    margin-right: 3px;
    color: var(--secondary-text-color);
    fill: var(--secondary-text-color);
}

.arrauvul {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: space-between;
    width: 100%;
}

.arrauvul li:last-child,
.arrauvul li:first-child {
    padding: 0 5px !important;
    background-color: #fff !important;
    border-color: white !important;
}

.USD {
    padding: 0 !important;
    line-height: 1;
    font-size: 14px !important;
    background-color: #fff !important;
    border-color: white !important;}

.USD svg {
    margin-right: 0 !important;
}

.line {
    width: 100%;
    border: 1px dashed rgb(0 0 0 / 35%); 
    margin-bottom: 0;
    margin-top: 3px;
}

.billingBox {
    display: flex;
    padding: 0 5px;
    font-size: 13px;
    color: var(--text-color);
    line-height: 1.25;
    height: 35px;
    gap: 10px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.btnDesc {
    border-radius: 50px;
    line-height: 1.25;
    font-weight: 400;
    padding: 2px 10px 2px 10px;
    font-size: 13px;
    color: var(--text-color);;
    display: flex;
    background-color: rgb(241 245 249);
    border: 1px solid var(--border-color);
}
 

.btnStatus {
    border-radius: 50px;
    line-height: 1.25;
    font-weight: 400;
    padding: 2px 10px 2px 10px;
    font-size: 13px;
    color: rgb(255 255 255);
    display: flex;
    background-color: rgb(20 184 166);
    border: 1px solid rgb(20 184 166);
}
  
 

.Finances { 
    width: 100%;
    border-top: 1px solid var(--border-color);
    padding: 0;
    display: flex;
    gap: 7px;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}


.financeBox { 
    position: static;
    border-radius: 5px;
    color: var(--text-color);
    display: flex;
    background-color: #fff;
    border: 1px solid var(--border-color-30);
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    width: 100%;
    height: 20px;
}

.financeDesc {   
    font-feature-settings: "case" on;
    line-height: 1;
    font-weight: 500;
    padding: 0 0 0 5px;
    font-size: 13px;
    color: var(--text-color);
    display: flex;
    overflow: hidden;
    max-width: 100%;
    position: relative;
    white-space: nowrap;
}

.financeDesc b {  
    margin-left: 5px;
}

.financeDesc.icon {  
    padding-left: 23px;
}

.financeDesc.icon svg {  
    position: absolute;
    top: 0;
    left: 4px;
    height: 17px;
    width: 17px;
}

.financeValue {   
    border-radius: 5px;
    line-height: .9242;
    font-weight: 700;
    margin: 2px;
    padding: 2px 4px;
    font-size: 13px;
    color: rgb(255 255 255);
    display: flex;
    background-color: var(--bg-third-color);
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;

}


table .financeBox,
table .financeBox .financeValue {
    background-color: transparent; 
    color: var(--secondary-text-color);
    font-weight: 700; 
    border-color: transparent !important; 
    background-color: transparent !important; 
} 
  

  
.financeBox.blueFin .icon svg{
    color: #1254e7; 
} 
.financeBox.blueFin .financeValue {
    background-color: rgb(18 85 231 / 20%); 
    color: #1254e7; 
}
  
table .financeBox.blueFin,
table .financeBox.blueFin .financeValue {
    background-color: transparent; 
    color: #1254e7; 
    font-weight: bold; 
    border-color: transparent !important; 
    background-color: transparent !important; 
} 
 
.blueFin.iconHead {
    color: #1254e7; 
    background-color: rgb(18 84 231 / 15%); 
    border-color: #1254e7; 
}


 
.financeBox.greenFin .icon svg{
    color: #009688; 
} 
.financeBox.greenFin .financeValue {
    color: #009688; 
    background-color: #00968720; 
}


table .financeBox.greenFin,
table .financeBox.greenFin .financeValue {
    background-color: transparent; 
    color: #009688; 
    font-weight: bold; 
    border-color: transparent !important; 
    background-color: transparent !important; 
} 
 
 
.greenFin.iconHead {
    color: #009688; 
    background-color: #d5ebe7; 
    border-color: #009688; 
}

 
.financeBox.redFin .icon svg{
    color: #d80228; 
} 
.financeBox.redFin .financeValue {
    background-color: rgb(255, 223, 216); 
    color: #d80228; 

}
 

table .financeBox.redFin,
table .financeBox.redFin .financeValue {
    background-color: transparent; 
    color: #d80228; 
    font-weight: bold; 
    border-color: transparent !important; 
    background-color: transparent !important; 
} 
 
.financeBox.yellFin .icon svg{
    color: #df8805; 
} 
.financeBox.yellFin .financeValue {
    background-color: #df880539; 
    color: #df8805; 
}
  
table .financeBox.yellFin,
table .financeBox.yellFin .financeValue {
    background-color: transparent; 
    color: #df8805; 
    font-weight: bold; 
    border-color: transparent !important; 
    background-color: transparent !important; 
} 
 
.yellFin.iconHead {
    color: #df8805; 
    background-color: rgb(223 136 5 / 20%); 
    border-color: #df8805; 
}



.financeBox.violetFin {
    color: #6900C6; 
    border-color: #6900C6; 
} 
.financeBox.violetFin .financeDesc {
    color: #6900C6; 
    border-color: #6900C6; 
} 
.financeBox.violetFin .icon svg{
    color: #6900C6; 
} 
.financeBox.violetFin .financeValue {
    background-color: #6900C6; 
}

table .financeBox.violetFin,
table .financeBox.violetFin .financeValue {
    background-color: transparent; 
    color: #6900C6; 
    font-weight: bold; 
    border-color: transparent !important; 
    background-color: transparent !important; 
} 
 
 
.violetFin.iconHead {
    color: #6900C6; 
    background-color: rgb(105 0 198 / 10%); 
    border-color: #6900C6; 
}
 
.modalHead.violetFin h3 {
     
    color: #6900C6;  
}

 


.groupPrice { 
    display: flex;  
    padding: 4px 6px;
    margin: 0 -6px;
    border-radius: 8px; 
    transition: background-color 0.2s ease ;
}

.groupPrice:focus-within,
.groupPrice:hover {  
    background-color: var(--secondary-background);
}
 

.groupPrice .leftLabel { 
    width:  70%;
    font-size: .825rem;
    font-weight: 600;
    display: flex;
    color: var(--text-color);
    align-content: center;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 8px;
}


.groupPrice .rightInput {
    width: 30%;
}


.TotalPrice {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 15px;
    color: rgb(148 163 184);
}

.TotalPrice .paramTotal {
    font-weight: bold;
    padding-right: 10px;
}

.TotalPrice .priceCredit {
    font-weight: bold;
    color: rgb(49 57 69);
    font-size: 22px;
}

.TotalPrice .priceCredit .curr {
    font-weight: bold;
    color: rgb(49 57 69);
    font-size: 12px;
    margin-right: 10px;
}

.flexend {
    display: flex;
    justify-content: flex-end;
}

.btnPrimary {
    cursor: pointer;
    line-height: 1.25rem;
    color: #fff;
    font-size: 13px;
    font-weight: 700;
    padding-bottom: 7px;
    padding-top: 7px;
    border: none;
    border-radius: 0.3rem;
    width: 100%;
    max-width: 200px;
    /* height: 2.5rem; */ 
    font-feature-settings: "case" on;
    background-color: var(--primary-color);
    transition-duration: .3s;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}


.incar {
    display: flex;
    width: 100%;
}

.priceValueB {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    gap: 3px;
    position: relative;
    padding: 0px 10px;
}

 
.centerBorder::before { 
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 1px;
    height: calc(100% + 0px);
    background-color: var(--border-color);
}


 
.pad0 {
    padding: 0 !important;
}
 
.btntbl {
    padding: 0 10px !important;
    width: 25px !important; 
}

.id {
    width: 25px !important;
    text-align: center !important;
}
 
.btns {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
}

 


.redTable > td{ 
    /* color: rgb(183, 29, 24) !important;  */
    background-color: rgba(255, 86, 48, 0.2) !important;
    /* border-color: rgba(255, 86, 48, 0.4) !important;  */
}

.containerDiv {
    max-width: 100px;
}

.containerDiv input{
    width: 100%;
    border: 1px solid  var(--border-color) ;
    border-radius: 2px;
    text-align: center;
    font-weight: 600;
    color: var(--secondary-text-color);
}

.containerDiv input:focus,
.containerDiv input:focus-visible {
    outline: none !important;
    box-shadow: none !important;
}


.transitDiv {
    max-width: 100px;
}

.transitDiv input{
    width: 100%;
    border: 1px solid  var(--border-color) ;
    border-radius: 2px;
    text-align: center;
    font-weight: 600;
    color: var(--secondary-text-color);
}

.transitDiv input:focus,
.transitDiv input:focus-visible {
    outline: none !important;
    box-shadow: none !important;
}




.formCheck input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.formCheck label {
    position: relative;
    cursor: pointer; 
    color: var(--secondary-text-color);
    font-size: 13px; 
}


.formCheck label span{
    font-weight: bold; 
}

.formCheck label:before {
    content: '';
    background-color: var(--secondary-background);
    border: 1px solid var(--border-color);
    box-shadow: none;
    padding: 8px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 0;
    border-radius: 2px;
}

.formCheck input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 6px;
    width: 6px;
    height: 12px;
    border: solid var(--secondary-text-color);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.activeTR {
    background-color: var(--third-background) !important;
}


.uploadDiv {
    cursor: pointer;
    color: var(--text-color);
    border: 1px solid var(--border-color);
    border-radius: 0.3rem;
    width: 100%;
    height: 32px;
    background-color: var(--secondary-background);
}


.uploadDiv input {
    cursor: pointer;
    color: var(--text-color);
    width: 100%;
    height: 100%;
    padding: 4px;
}

.uploadDiv input::file-selector-button:hover {
    background-color: var(--secondary-background);
  }
  
.uploadDiv input::file-selector-button:active {
    background-color: var(--secondary-background);
  }
  

.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}
.inputfile + label {
    font-size: 1.25em;
    font-weight: 700;
    color: white;
    background-color: black;
    display: inline-block;
    cursor: pointer;
}

.inputfile:focus + label,
.inputfile + label:hover {
    background-color: red;
}

.inputfile:focus + label {
	outline: 1px dotted #000;
	outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
	pointer-events: none;
}


.uploadDiv svg {
    opacity: 0.5;
    width: 100%;
    height: 100%;
    fill: rgb(100 116 139);
    color: rgb(100 116 139);
}


.sticky-col {
    position: -webkit-sticky;
    position: sticky;
    background-color: white;
  }
  
  .first-col {
    width: 100px;
    min-width: 100px;
    max-width: 100px;
    left: 0px;
  }
  
  .second-col {
    width: 150px;
    min-width: 150px;
    max-width: 150px;
    left: 100px;
  }


table tr td.stickyCol { 
    position: sticky;  
    left: 0;
    background-color: rgb(242 242 242) !important;
    border-color: var(--border-color-table)!important; 
    z-index: 1; 
}

table tr td.stickyColSecond b,
table tr td.stickyCol b {
    font-weight: 700 !important;
    color: #707478 !important;
}

table tr td.stickyColSecond { 
    position: sticky;  
    left: 33px !important;
    background-color: rgb(242 242 242) !important;
    border-color: var(--border-color-table)!important; 
    z-index: 1; 
}



table tr td.stickyColLast { 
    position: sticky;  
    right: 0 !important;
    top: 0 !important;  
    z-index: 1; 
    background-color: rgb(242 242 242) !important;
    border-color: var(--border-color-table) !important;
    border-left: 1px solid ;
}

  

table tr td.stickyColSecond.savedRow { 
    color: rgb(0, 150, 136) !important;
    background: rgb(211, 233, 230) !important;
}



.navItemBox {
    display: flex;
    position: relative;
}


.navItemBtn {
    border-radius: 5px;
    line-height: 1.25;
    font-weight: 500;
    font-feature-settings: "case" on;
    padding: 2px 5px;
    display: flex;
    font-size: 13px;
    color: var(--text-color);
    background-color: var(--secondary-background);
    border: 1px solid var(--border-color);
    cursor: pointer;
    transition: background-color 0.2s ease, border-color 0.2s ease;
    align-items: center;
}
 

.navItemBtn > svg {
    margin-right: 3px;
    height: 16px;
    width: 16px;
}


.dropdownContent { 
    position: absolute;
    top: 0px;
    left: calc(100% + 8px);
    min-width: 151px;
    /* z-index: 101; */
    background: #fff;
    border: 1px solid  var(--primary-color);
    border-radius: 5px;
    box-shadow: 0 0.25rem 0.5rem rgba(0,0,0,.1);
}

 

.dropdownContent::before {
     
    border-color: var(--primary-color) transparent;
    border-top-width: 0px !important;
    left: -8px;
    top: 9px;
    border-width: 5px;
    display: block;
    height: 0px;
    position: absolute;
    width: 0px;
    transform: rotate(270deg);
}

.dropdownContent::after {
    content: "";
    border-color: var(--primary-color) transparent;
    border-top-width: 0px !important;
    border-width: 5px;
    display: block;
    height: 0px;
    position: absolute;
    width: 0px;
    top: 9px;
    left: -8px;
    transform: rotate(270deg);
}

.dropdownContent.dropdownContentInverse { 
    left: -163px;
}
 
.dropdownContent.dropdownContentInverse::after { 
    left: unset;
    right: -8px;
    transform: rotate(90deg);
}
 
 
.dropdownContent.dropdownContentInverse::before { 
    left: unset;
    right: -8px;
    transform: rotate(90deg);
}
 
.dropdownContent > a,
.dropdownContent > span { 
    padding: 7px 5px;
    display: flex;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 600;
    font-style: inherit;
    background: transparent;
    text-align: center;
    color: var(--text-color);
    font-feature-settings: "case" on;
    cursor: pointer;
    border: none;
    transition: all 200ms;
    align-items: center;
}

.dropdownContent > a:last-child,
.dropdownContent > span:last-child {   
    border-bottom: none; 
}
 
.dropdownContent > a:last-child,
.dropdownContent > span > svg {  
    width: 16px;
    height: 16px;
    margin-left: 4px;
    margin-right: 5px;
    fill: var(--secondary-text-color);
    color: var(--secondary-text-color);
} 
  
.dropdownContent > a:hover,
.dropdownContent > span:hover {  
    background-color: #f5f5f5;
}
 

.invoiceLink {
    color: var(--text-color); 
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 8px;
}
 

.invoiceLink svg{
    height: 18px;
    width: 18px;
}


  
.editBtn { 
    border-radius: 5px;
    line-height: 1.25;
    font-weight: 500;
    padding: 2px 3px;
    display: flex;
    font-size: 12px;
    color: var(--text-color);
    background-color: var(--secondary-background);
    border: 1px solid var(--border-color);
    cursor: pointer;
    transition: background-color 0.2s ease, border-color 0.2s ease;
    align-items: center;
}

.editBtn:hover { 
    background-color: var(--third-background);
}
 
.editBtn svg {  
    height: 16px;
    width: 16px;
    margin-right: 3px;
    color: var(--secondary-text-color);
    fill: var(--secondary-text-color);
    transition: color 0.2s ease ;
}


.footerbtnio { 
    gap: 5px;
    display: flex; 
    padding: 5px 5px;
}


@media (max-width: 1025px) { 
        
    .Car { 
        width: 50%;
        max-width: 50%;
        min-width: 50%; 
        border-bottom:1px solid var(--border-color); ;
    }



    .transport {
   
        padding: 10px; 
    }



    .client {
        width: 50%;
        max-width: 50%;
        min-width: 50%; 
        border-bottom:1px solid var(--border-color); ;

    }

    .client::after { 
        display: none;
    }

    .tranBox {
        width: 100%;
        max-width: 100%;
        min-width: 100%; 
    }

    .salerFinance {
        width: 100%;
        max-width: 100%;
        min-width: 100%; 
        padding: 0;
    }

        
    table tr td.stickyColLast { 
        position: static  !important;    
        right: unset  !important;  
        z-index: unset  !important;  
        top: unset  !important;   
        border-color: var(--border-color-table) !important;
        border-left: 0 solid ;

    }


    .billingBox { 
        padding: 20px 10px;
        border-bottom: 1px solid  var(--border-color);
    }

    .tranBox::after { 
    display: none;
    } 


    .footerbtnio { 
        gap: 5px;
        display: flex;
        overflow-x: auto;
        scrollbar-width: none;
        padding: 0;
    }
  
    .Finances { 
        padding: 7px 10px 7px 10px; 
    }


    .priceValueB { 
        padding: 10px 10px;
    }

}

 
.pwinMinusColot { 
    background-color: #6a00c64a !important; 

}

.pwinMinusColot > td > b { 
  /*  color: #ffffff9b !important;  */

}


table .pwinMinusColot  > td .financeBox,
table .pwinMinusColot  > td .financeBox   .financeValue { 
  /*  color: #ffffff9b !important;  */
} 
  
