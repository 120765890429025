.modalBox {
    inset: 0;
    position: fixed;
    padding: 1rem;
    padding-top: 5rem;
    display: flex; 
    justify-content: center;
    align-items: flex-start!important;
    min-height: 100%;  
    z-index: 999;
    overflow-y: auto;
    background-color: rgb(0 0 0/.7);
}

.modalCrm {
    box-shadow:  0 0 #0000, 0 0 #0000,0 20px 25px -5px rgba(0,0,0,.1),0 8px 10px -6px rgba(0,0,0,.1); 
    background-color: #fff;
    max-width: 28rem;
    width: 100%; 
    min-height: 180px;
    transition: opacity 0.3s ease, transform 0.3s ease;
    border-radius: 0.75rem;
    position: relative;
}

.modalBox.small .modalCrm {
    max-width: 28rem;
}

.modalBox.large .modalCrm {
    max-width: 68rem;
}

.modalBox.medium .modalCrm {
    max-width: 38rem;
}

.modalBG {
    position: absolute; 
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
} 
 
.modalHead {
    padding: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.closeBtn { 
    cursor: pointer;
    display: flex;
    position: absolute;
    top: 24px;
    right: 24px;
    align-items: center;
    justify-content: center;
    z-index: 33;
    gap: 5px;
    font-weight: 600;
    font-size: 14px;
    color: #444444;
}

.closeBtn span {
    background-color: var(--secondary-background); 
    cursor: pointer;
    display: flex;
    position: relative;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center; 
    transition: all .2s;
} 

.closeBtn:hover span {
    transform: scale3d(1.1, 1.1, 1.1);
    background-color: var(--third-background);  
}

.closeBtn svg{
  width: 20px;
  height: 20px;
  opacity: .6;
  color: var(--secondary-text-color);
}



.modalHead {
    padding: 1.5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
 
.modalHead h3 {
    display: flex;
    align-items: center;
    color: var(--text-color);
    padding: 0;
    margin: 0;
    font-weight: 500;
    font-size: 1.125rem; 
    font-feature-settings: "case" on; 
}
 
.modalHead h3 span{
    height: 30px;
    width: 30px;
    border-radius: 999px;
    color: var(--text-color);
    background-color: rgb(241 245 249);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}
 
.modalHead h3 span svg{
    height: 18px;
    width: 18px; 
}


 
.modalBody {
    padding: 0 24px 18px 24px;
}
