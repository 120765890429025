/*
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Georgian:wght@400;500;600;700&display=swap');
 
*/

 

@font-face {
  font-family: "Helvetica Neue LT GEO";
  src: url(assets/Fonts/HelveticaNeueLTGEOW82-55Rm.eot);
  src: url(assets/Fonts/HelveticaNeueLTGEOW82-55Rm.eot?#iefix) format("embedded-opentype"),
       url(assets/Fonts/HelveticaNeueLTGEOW82-55Rm.woff2) format("woff2"), 
       url(assets/Fonts/HelveticaNeueLTGEOW82-55Rm.woff) format("woff"), 
       url(assets/Fonts/HelveticaNeueLTGEOW82-55Rm.ttf) format("truetype"), 
       url(assets/Fonts/HelveticaNeueLTGEOW82-55Rm.svg#HelveticaNeueLTGEOW82-55Rm) format("svg");
  font-weight: 500;
  font-style: normal
}

@font-face {
  font-family: "Helvetica Neue LT GEO";
  src: url(assets/Fonts/HelveticaNeueLTGEOW82-65Md.eot);
  src: url(assets/Fonts/HelveticaNeueLTGEOW82-65Md.eot?#iefix) format("embedded-opentype"),
       url(assets/Fonts/HelveticaNeueLTGEOW82-65Md.woff2) format("woff2"), 
       url(assets/Fonts/HelveticaNeueLTGEOW82-65Md.woff) format("woff"), 
       url(assets/Fonts/HelveticaNeueLTGEOW82-65Md.ttf) format("truetype"), 
       url(assets/Fonts/HelveticaNeueLTGEOW82-65Md.svg#HelveticaNeueLTGEOW82-65Md) format("svg");
  font-weight: 600;
  font-style: normal
}

@font-face {
  font-family: "Helvetica Neue LT GEO";
  src: url(assets/Fonts/HelveticaNeueLTGEOW82-75Bd.eot);
  src: url(assets/Fonts/HelveticaNeueLTGEOW82-75Bd.eot?#iefix) format("embedded-opentype"),
       url(assets/Fonts/HelveticaNeueLTGEOW82-75Bd.woff2) format("woff2"), 
       url(assets/Fonts/HelveticaNeueLTGEOW82-75Bd.woff) format("woff"), 
       url(assets/Fonts/HelveticaNeueLTGEOW82-75Bd.ttf) format("truetype"), 
       url(assets/Fonts/HelveticaNeueLTGEOW82-75Bd.svg#HelveticaNeueLTGEOW82-75Bd) format("svg");
  font-weight: 700;
  font-style: normal
}

@font-face {
   font-family: "Helvetica Neue LT GEO";
   src: url(assets/Fonts/HelveticaNeueLTGEOW82-85Hw.woff2) format("woff2"),
   url(assets/Fonts/HelveticaNeueLTGEOW82-85Hw.woff) format("woff");
   font-weight: 800;
   font-style: normal 
}


@font-face {
   font-family: "Helvetica Neue LT GEO";
   src: url(assets/Fonts/HelveticaNeueLTGEOW82-85Bl.woff2) format("woff2"),
   url(assets/Fonts/HelveticaNeueLTGEOW82-85Bl.woff) format("woff");
   font-weight: 900;
   font-style: normal 
}


*,:after,:before {
  border: 0 solid #e5e7eb;
  box-sizing: border-box
}

html {
  -webkit-text-size-adjust: 100%;
  font-feature-settings: normal;
  font-family: Inter,sans-serif;
  font-variation-settings: normal;
  line-height: 1.5;
  tab-size: 4
}

body {
  margin: 0;
  font-size: 13px;
  font-family: 'Helvetica Neue LT GEO', -apple-system, sans-serif; 
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: inherit;
  margin: 0;
  scroll-behavior: smooth;
}

input,
button {
  font-family: 'Helvetica Neue LT GEO', -apple-system, sans-serif; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: var(--primary-color);
  text-decoration: inherit;
}

menu, ol, ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
  
.nonintable  .nonetable{
  display: none; 
} 


.twomonth .react-datepicker__input-container { 
     position: unset !important;
}

.centreddate .react-datepicker { 

  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.twomonth .react-datepicker-popper[data-placement^=bottom] {
  min-width: 473px !important;
}

.withtime .react-datepicker-popper[data-placement^=bottom] {
  min-width: 325px !important;
}

.react-international-phone-country-selector-button__dropdown-arrow {
  display: none !important ;
}

.react-international-phone-country-selector-button__flag-emoji {
  margin: 0 3px  !important ; 
}

.react-international-phone-flag-emoji {
  width: 18px !important ;
  height:18px !important ;
}

.react-international-phone-input-container .react-international-phone-input {
  height: 35px !important; 
  padding: 0 0 0 4px !important;
  border: 1px solid var(--border-color) !important;
  border-radius:   5px !important; 
  border-bottom-left-radius: 0 !important; 
  border-top-left-radius: 0 !important; 
  background-color: #fff !important;
  color: var(--text-color) !important; 
  font-size:  13px !important;
  width: 100%; 
 }


 .react-international-phone-input-container .react-international-phone-country-selector-button {
  border-radius:  5px !important;
  margin-right: -1px;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-right: none;
  height: 35px !important;
}
 
.errorInput .react-international-phone-input-container .react-international-phone-country-selector-button,
.errorInput .react-international-phone-input-container .react-international-phone-input { 
  border-color:   rgb(225, 29, 72) !important; 
 }


.react-international-phone-country-selector-dropdown { 
  z-index: 2 !important; 
}

.react-datepicker-popper {
  z-index: 1050 !important; 
}

.themeCRM[data-theme=lightdim] {
  --layout-background: #f1f5f9;  
  --secondary-background: #f2f2f2;   
  --active-background: #f1f5f9;   
  --border-color: #e2e8f0;
  --bg-color-color:  rgb(30 41 59);
  --text-color:  rgb(30 41 59);
  --secondary-text-color: rgb(100 116 139);
  --muted-text-color:rgb(148 163 184);
  --placeholder-text-color: rgb(203 213 225);
}

.themeCRM[data-theme=light] {
  --layout-background: #f6f7f7;  
  --secondary-background: #f2f2f2;  
  --third-background: #e2e2e2e8;  
  --active-background: #f6f7f7; 
  --bg-color: #0f0f0f;
  --bg-secondary-color: #4c4c4c; 
  --bg-third-color: #696b6c; 
  --border-color: rgba(0, 0, 0, 0.15);
  --border-color-30: rgba(0, 0, 0, 0.30);
  --border-color-table: rgba(0, 0, 0, 0.20);
  --text-color: #0f0f0f;
  --secondary-text-color: #444;
  --muted-text-color: #8b8e8f; 
  --placeholder-text-color: #8b8e8fa9;
}
 


.themeCRM[data-company=automoby] {
  --primary-color: #235e82; 
  --primary-color-inverse: #f1f5f9; 
  --active-background: #f1f5f9; 
}

.themeCRM[data-company=aig] { 
  --primary-color: #da0429; 
  --primary-color-inverse: #ffeaea; 
  --active-background: #ffeaea; 

}

.themeCRM[data-company=ilogistics] {
  --primary-color: #166DE7; 
  --primary-color-inverse: #f1f5f9; 
  --active-background: #f1f5f9; 
}
