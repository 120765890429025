.groupPrice { 
    display: flex;  
    padding: 2px 0px;
    margin: 0 ;
    border-radius: 8px; 
    transition: background-color 0.2s ease ;
}

.groupPrice:focus-within,
.groupPrice:hover {  
    background-color: var(--secondary-background);
}
  
.groupPrice .leftLabel { 
    width: 63%;
    display: flex;
    font-size: 13px;
    font-weight: 500;
    color: var(--text-color);
    align-content: center;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 8px;
}


.groupPrice .rightInput {
    width: 37%;
} 


.TotalPrice {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 1px;
    color: rgb(148 163 184);
}

.TotalPrice .paramTotal {
    font-weight: bold;
    padding-right: 10px;
}

.TotalPrice .priceCredit {
    font-weight: bold;
    color: var(--text-color);
    font-size: 22px;
}

.TotalPrice .priceCredit .curr {
    font-weight: bold;
    color: var(--text-color);
    font-size: 12px;
    margin-right: 10px;
}



.formCheck input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.formCheck label {
    position: relative;
    cursor: pointer; 
    color: var(--secondary-text-color);
    font-size: 13px; 
}


.formCheck label span{
    font-weight: bold; 
}

.formCheck label:before {
    content: '';
    background-color: var(--secondary-background);
    border: 1px solid var(--border-color);
    box-shadow: none;
    padding: 8px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 0;
    border-radius: 2px;
}

.formCheck input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 6px;
    width: 6px;
    height: 12px;
    border: solid var(--secondary-text-color);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}