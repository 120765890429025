.logoDiv {
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 20px; 
  padding: 10px 10px;
  z-index: 10;
}

.companyLogo {
  width: 100%;
  display: flex; 
}

.companyLogo>div {
  display: flex;
  height: 45px;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  border-radius: 7px;
  cursor: pointer;
  padding: 0px 10px;
  width: 100%;
}
 
.automoby {
  background: var(--secondary-background);
}

.automoby svg {
  height: 30px;
  fill: var(--primary-color);
}
 
.AIG {
  background: var(--secondary-background);
}

.AIG svg {
  width: 75px;
  height: 35px;
}
 
.containers {
  background: var(--secondary-background);
  color: var(--primary-color);
}

.containers svg {
  width: 33px; 
  height: 30px;
}

.dropdownContent span.containersLogo > svg {
  max-width: 43px; 
}


.dropdownContent { 
  position: absolute;
  top: 55px;
  left: 10px;
  right: 0;
  width: 180px;
  overflow: auto;
  box-shadow: 0px 1px 13px 4px rgb(0 0 0 / 10%);
  border: 1px solid var(--border-color);
  z-index: 14;
  border-radius: 5px;
  background: #fff;
}

.dropdownContent a { 
  padding: 9px 8px;
  display: flex;
  font-weight: 600;
  font-size: 14px;
  font-style: inherit;
  text-align: center;
  color: var(--primary-color);
  cursor: pointer;
  border-top: 1px solid var(--border-color);
  transition: all 200ms;
  align-items: center;
  justify-content: center;
}

.dropdownContent a:first-child { 
  border-top: none ; 
}

.dropdownContent a:hover {  
  background-color: var(--secondary-background);
}

.dropdownContent a svg {
  color: black;
  height: 30px;
  max-width: 100px;
}
  


.LogoContainer {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.LogoContainer svg{
  height: 33px;
  margin-right: 10px;
  width: 47px;
}
.LogoContainer span{
  background: #f2f2f2;
  border-radius: 0;
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 900;
  line-height: 1;
  margin-left: -54px;
  margin-top: 5px;
  padding: 0 0 0 1px;
  position: relative;
  z-index: 2;
} 


.LogoContainer::after  {
  background: #f2f2f2;
  content: "";
  height: 21px;
  left: 4px;
  position: absolute;
  top: 9px;
  width: 38px;
  z-index: 1;
}