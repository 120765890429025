 
 
.row {
    --bs-gutter-x: 10px;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y)* -1);
    margin-right: calc(var(--bs-gutter-x)* -.5);
    margin-left: calc(var(--bs-gutter-x)* -.5);
}

.row>* {
    --bs-gutter-x: 10px;
    --bs-gutter-y: 0;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x)* .5);
    padding-left: calc(var(--bs-gutter-x)* .5);
    margin-top: var(--bs-gutter-y);
}

.col {
    flex: 1 0 0%;
}

.col2 {
    flex: 2 0 0%;
}

.colSmall {
    flex: 0 0;
}

 



.AddBtn { 
    border: 1px solid;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    cursor: pointer;
    align-items: center;
    font-feature-settings: "case" on;
    line-height: 1.25;
    font-weight: 600;
    padding: 0px 7px 0px 0px;
    font-size: 13px;
    overflow: hidden;
    color: var(--secondary-text-color);
    border-color: var(--border-color);
}

 
.AddBtn span.icon { 
    max-width: 28px;
    max-height: 28px;
    min-width: 28px;
    min-height: 28px;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
    display: flex;
    color: var(--secondary-text-color);
    background-color: var(--secondary-background);
}

.AddBtn span.icon svg {  
    max-width: 18px;
    max-height: 18px;
    min-width: 18px;
    min-height: 18px; 
}


.editBtn { 
    border-radius: 5px;
    line-height: 1.25;
    font-weight: 500;
    padding: 2px 3px;
    display: flex;
    width: 100%;
    font-size: 12px;
    color: var(--text-color);
    background-color: var(--secondary-background);
    border: 1px solid var(--border-color);
    cursor: pointer;
    transition: background-color 0.2s ease, border-color 0.2s ease;
    align-items: center;
}

.editBtn:hover { 
    background-color: var(--third-background);
}
 
.editBtn svg {  
    height: 16px;
    width: 16px;
    margin-right: 3px;
    color: var(--secondary-text-color);
    fill: var(--secondary-text-color);
    transition: color 0.2s ease ;
}

 
 
 
.modalHead {
    padding: 1.5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
 
.modalHead h3 {
    display: flex;
    align-items: center;
    color: var(--text-color);
    padding: 0;
    margin: 0;
    font-weight: 600;
    font-size: 1.125rem; 
    font-feature-settings: "case" on; 
    width: 100%;
}
  
 
.modalHead h3 span{
    height: 30px;
    width: 30px;
    border-radius: 999px;
    color:var(--secondary-text-color);
    background-color:var(--secondary-background);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}

.modalHead h3 span svg{
    height: 18px;
    width: 18px; 
}
  
.groupInput {
    margin-bottom: 15px;
    position: relative;
}
 

.groupInput .input::placeholder {
    color: var(--placeholder-text-color);
    position: relative;
}

.groupInput label { 
    position: absolute;
    top: -8px;
    left: 8px;
    color: var(--muted-text-color);
    background-color: #fff;
    font-size: 12px;
    line-height: 1;
    padding: 0px 3px;
    z-index: 1;
}
 
.input {  
    line-height: 1.25rem;
    color: var(--text-color);
    font-size: 13px;
    padding: 0 10px; 
    border: 1px solid var(--border-color);
    border-radius: 5px;
    width: 100%;
    height: 35px;
    background-color: rgb(255 255 255); 
    outline-color: transparent;
    outline-offset: 2px;
    outline-style: dashed;
    outline-width: 1px; 
    transition-duration: .3s; 
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}

.input::placeholder {
    color: var(--placeholder-text-color);
    position: relative;
}

.input:focus-within {  
    outline-color: none; 
}
 
.btnPrimary {
    margin-top: 20px;
    margin-left: auto;
    display: block;
    text-align: center;
    padding: 7px 20px;
    cursor: pointer;
    line-height: 1.2;
    color: #fff;
    font-size: 14px; 
    font-feature-settings: "case" on;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    width: 100%;
    max-width: 200px; 
    background-color: var(--primary-color);
    transition-duration: .3s;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}
 

.errorInput input {
    border-color: rgb(225, 29, 72); 
}
 
.errorInput label {
    color: rgb(225, 29, 72); 
}

.errorMsg {
    color: rgb(225, 29, 72); 
    font-size: 10px;
    position: absolute;
    bottom: -3px;
    background: #fff;
    right: 11px;
    line-height: 1; 
}
 



.groupPrice { 
    display: flex;  
    padding: 4px 6px;
    margin: 0 -6px;
    border-radius: 8px; 
    transition: background-color 0.2s ease ;
}
 

.groupPrice .leftLabel { 
    width:  70%;
    font-size: 13px;
    display: flex;
    color: var(--text-color);
    align-content: center;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 8px;
    font-weight: 500;
}


.groupPrice .rightInput {
    width: 30%;
}



.dataContainer  { 
    display: block;
    position: relative; 
}

.dataContainer .input {
    padding-left: 30px;
    padding-right: 10px;
    background-repeat: no-repeat;
    background-size: 17px;
    background-position: 6px center;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="28" height="32" viewBox="0 0 14 16"><path fillRule="evenodd" fill="rgb(139 142 143)" d="M13 2h-1v1.5c0 .28-.22.5-.5.5h-2c-.28 0-.5-.22-.5-.5V2H6v1.5c0 .28-.22.5-.5.5h-2c-.28 0-.5-.22-.5-.5V2H2c-.55 0-1 .45-1 1v11c0 .55.45 1 1 1h11c.55 0 1-.45 1-1V3c0-.55-.45-1-1-1zm0 12H2V5h11v9zM5 3H4V1h1v2zm6 0h-1V1h1v2zM6 7H5V6h1v1zm2 0H7V6h1v1zm2 0H9V6h1v1zm2 0h-1V6h1v1zM4 9H3V8h1v1zm2 0H5V8h1v1zm2 0H7V8h1v1zm2 0H9V8h1v1zm2 0h-1V8h1v1zm-8 2H3v-1h1v1zm2 0H5v-1h1v1zm2 0H7v-1h1v1zm2 0H9v-1h1v1zm2 0h-1v-1h1v1zm-8 2H3v-1h1v1zm2 0H5v-1h1v1zm2 0H7v-1h1v1zm2 0H9v-1h1v1z"  /></svg>');
}

.dataContainer .errorVal .input {
    border-color: #d80228;
}

.dataContainer .reciveAnput {  
    padding-right: 5px;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="rgb(139 142 143)" d="m20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8l8-8z"/></svg>') !important;   
}

.dataContainer .arriveAnput {  
    padding-right: 5px; 
    background-image:  url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="rgb(139 142 143)" d="M6.4 18L5 16.6L14.6 7H6V5h12v12h-2V8.4L6.4 18Z"/></svg>') !important;   
}

 



.formCheck   { 
    margin-top: 1px; 
    margin-bottom: 1px; 
}
  
  
.formCheck input { 
    display: none; 
}
  
.formCheck label {
    position: relative;
    cursor: pointer; 
    color: var(--secondary-text-color);
    font-size: 13px; 
  }
  
  
.formCheck label span{
    font-weight: bold; 
}
  
.formCheck label:before {
    content: '';
    background-color: var(--secondary-background);
    border: 1px solid var(--border-color);
    box-shadow: none;
    padding: 8px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 2px;
}
  
.formCheck input:checked + label:after {
    content: '';
    display: block;
    position: absolute; 
    top: 2px;
    left: 6px;
    width: 6px;
    height: 12px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
  
  
.formCheck input:checked + label:before {
    content: '';
    background-color: #37b437;
    border: 1px solid #37b437;
    box-shadow: none;
    padding: 8px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 2px;
}
  


.badge { 
    border-radius: 50px;
    line-height: 1;
    font-weight: 500;
    padding: 2px 10px 2px 5px;
    display: flex;
    font-size: 12px;
    color: var(--text-color);
    background-color: #fff;
    border: 1px solid var(--border-color-table); 
    align-items: center;
}
 
 
.badge .cricle {  
    height: 10px;
    width: 10px;
    margin-right: 3px;
    color: var(--secondary-text-color);
    fill: var(--secondary-text-color);
    transition: color 0.2s ease ;
    border-radius: 16px;
}
 
.badge .icon {   
    margin-right: 3px; 
} 

.badge .icon svg {  
    height: 16px;
    width: 16px; 
    color: #333;
    fill: #333; 
}
 
.badge .icon.done svg {    
    color: #37b437;
    fill: #37b437; 
}
  


.inputTitle {
    display: flex;
    align-items: center;
    color: var(--text-color);
    padding: 4px 10px;
    margin: 0;
    font-weight: 600;
    font-size: 1.125rem;
    font-feature-settings: "case" on;
    border-radius: 10px;
    width: calc(100% - 115px);
}
 
 .errorInput {
   background-color: #d8022929; 
}
.contentItem {
    border: 1px solid var(--border-color);
    border-radius: 5px;
    padding: 10px;
    margin-top: 5px;
}


.contentItem img{
    width: 100%;
}