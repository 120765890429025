
 
 
.groupPrice { 
    flex: 1 1;
    position: relative;
    display: flex;
    box-sizing: border-box;
    margin-top: 3px;
    margin-bottom: 0;
    flex-direction: row;
    align-content: center;
    align-items: center;
}
  
.groupPrice .input::placeholder {
    color: var(--placeholder-text-color);
    position: relative;
}

.groupPrice label {    
    color: var(--muted-text-color);
    font-size: 12px;
    font-weight: bold;
}
 
.input {   
    line-height: 1.25rem;
    color: var(--text-color);
    font-size: 13px;
    padding: 0 5px 0 5px; 
    border: 1px solid var(--border-color);
    border-radius: 5px;
    width: 100%;
    height: 26px;
    background-color: rgb(255 255 255); 
    outline-color: transparent;
    outline-offset: 2px;
    outline-style: dashed;
    outline-width: 1px; 
    transition-duration: .3s; 
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    margin-right: 5px;
}

.input::placeholder {
    color: var(--placeholder-text-color);
    position: relative;
}

.input[disabled] {
    cursor: not-allowed;
 }

.input:focus-within {  
    border-color: var(--border-color); 
}

.groupPrice {
    position: relative;
}

.groupPrice .input { 
    padding-inline-start: 26px;
    padding-inline-end: 5px;
    padding-inline-start: 5px;
    font-weight: 600;
    width: 49px;
    text-align: center;
    height: 22px;
}

 
.groupPrice .crmIcon {
    position: absolute;
    top: 0;
    color: #444;
    display: flex; 
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 28px;
    transition-duration: .3s; 
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}


.groupPrice.dollar .input { 
    padding-inline-start: 5px;
    font-weight: 600; 
}

.groupPrice.dollar .crmIcon {
    color: var(--text-color);
    display: flex;
    width: 17px;
    justify-content: flex-end;
}
 

.groupPrice .crmIcon svg { 
    display: inline-block;
    vertical-align: middle;
    margin-left: px;
    height: 16px;
    width: 16px;
}
   
.groupPrice.red { 
    color: #d80228;
    font-weight: bold;
    border-color: #d80228;
    background-color: #fff;
} 

.groupPrice.red .containerName { 
    color: #d80228;    
    background-color: #fff;
} 

 

.groupPrice.yellow .crmIcon svg{
    color: #df8805;
} 
.groupPrice.yellow input{ 
    color: #df8805;
    font-weight: bold;
    border-color: #df8805;
    background-color: #fff;
} 

.groupPrice.yellow label { 
    color: #df8805;     
} 
 
.groupPrice.blue input{ 
    color: #1254e7;
    font-weight: bold;
    border-color: #1254e7;
    background-color: #fff;
} 

.groupPrice.blue label { 
    color: #1254e7;     
} 



.groupPrice.red .crmIcon svg{
    color: #d80228;
} 
.groupPrice.red input{ 
    color: #d80228;
    font-weight: bold;
    border-color: #d80228;
    background-color: #fff;
} 

.groupPrice.red label { 
    color: #d80228;     
} 

 

.groupPrice.yellow input{ 
    color: #df8805;
    font-weight: bold;
    border-color: #df8805;
    background-color: #fff;
} 

.groupPrice.yellow label { 
    color: #df8805;  
} 
 
  
.YesBtn {
    display: block;
    padding: 2px 10px;
    text-align: center;
    line-height: 1.25rem;
    font-size: 12px;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    background-color: #d1e7dd;
    border: 1px solid rgba(25, 135, 84);
    color: rgba(25, 135, 84);
    cursor: pointer;
}
  
.NoBtn {
    display: block;
    padding: 2px 10px;
    text-align: center;
    line-height: 1.25rem;
    font-size: 12px;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    background-color: #ffeef1;
    border: 1px solid #d80228;
    color: #d80228;
    cursor: pointer;
}

 
.errorInput .input {
    border-color: rgb(225, 29, 72); 
}
 
.errorInput label {
    color: rgb(225, 29, 72); 
}

.errorMsg {
    color: rgb(225, 29, 72); 
    font-size: 10px;
    position: absolute;
    bottom: -3px;
    background: #fff;
    right: 11px;
    line-height: 1; 
}