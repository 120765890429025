.boxUpload { 
    position: relative;
    min-height: 440px;
    border-radius: 5px;
    background-color: var(--layout-background);
    width: 100%;
    height: 100%;
    padding: 15px 15px;
}

.title {
    font-feature-settings: "case" on;
    color: var(--secondary-text-color);
    font-size: 13px;
    padding: 0;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 7px;
    margin-bottom: 10px;
    position: relative;
    z-index: 4;
}
 
.clickDiv {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; 
    cursor: pointer;
}


.message {
    position: absolute;
    top: calc(50% - 18px);
    z-index: 1;
    color: var(--muted-text-color);
    text-align: center;
    font-size: 16px; 
    font-style: italic;
    opacity: .5;
    width: 100%;
    cursor: pointer;

}

.container {   
    width: 100%;
    gap: 8px;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    position: relative;
    z-index: 1;
}

.container .file {   
    width: 100%;
    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.container .file .dlt {   
    color: #dc2626;
    background-color: rgb(220 38 38 / 10%);
    border-radius: 99.375rem;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 600;
    font-size: 13px;
    line-height: 1.25
}

  

.container .file .dlt svg {   
    height: 16px;
    width: 16px;
}

  

.loadingoverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure the overlay is on top of everything */
  }
  
  .loadingspinner {
    border: 4px solid rgba(255, 255, 255, 0.3); /* White border with transparency for the spinner */
    border-top: 4px solid #3498db; /* Blue border for the spinner */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite; /* Animation for the spinner */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }