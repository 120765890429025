
.exelBtn {  
  color: var(--secondary-text-color);
  border: 1px solid var(--border-color);
  background-color: #fff;
  border-radius: 5px;
  padding: 0 10px 0 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}


.exelBtn span.icon {  
  max-width: 28px;
  max-height: 28px;
  min-width: 28px;
  min-height: 28px;
  align-items: center;
  justify-content: center;
  margin-right: 2px; 
  display: flex;
}

.exelBtn span.icon svg {  
  max-width: 18px;
  max-height: 18px;
  min-width: 18px;
  min-height: 18px; 
}

 

.modalHead {
    padding: 1.5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
 
.modalHead h3 {
    display: flex;
    align-items: center;
    color: var(--text-color);
    padding: 0;
    margin: 0;
    font-weight: bold;
    font-size: 1.125rem; 
    font-feature-settings: "case" on; 
}
 
.modalHead h3 span{
    height: 30px;
    width: 30px;
    border-radius: 999px;
    color:rgb(100 116 139);
    background-color: rgb(241 245 249);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}
 
.modalHead h3 span svg{
    height: 18px;
    width: 18px; 
}


.validationMessage {
  color: red; /* Example styling */
  margin: 10px 0; /* Adjust margins as needed */
  /* Additional styling as desired */
}




.formCheck   { 
  margin-top: 1px; 
  margin-bottom: 1px; 
}


.formCheck input { 
  display: none; 
}

.formCheck label {
  position: relative;
  cursor: pointer; 
  color: var(--secondary-text-color);
  font-size: 13px; 
}


.formCheck label span{
  font-weight: bold; 
}

.formCheck label:before {
  content: '';
  background-color: var(--secondary-background);
  border: 1px solid var(--border-color);
  box-shadow: none;
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 2px;
}

.formCheck input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 2px;
  left: 6px;
  width: 6px;
  height: 12px;
  border: solid var(--secondary-text-color);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}


.dataContainer {
  width: 100%;
}

.dataContainer .input { 
  padding-inline-start: 30px;
  padding-inline-end: 10px;
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 6px center;
  border-radius: 5px;
  border: 1px solid var(--border-color);
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="28" height="32" viewBox="0 0 14 16"><path  fill="rgba(0, 0, 0, 0.5)" fillRule="evenodd" d="M13 2h-1v1.5c0 .28-.22.5-.5.5h-2c-.28 0-.5-.22-.5-.5V2H6v1.5c0 .28-.22.5-.5.5h-2c-.28 0-.5-.22-.5-.5V2H2c-.55 0-1 .45-1 1v11c0 .55.45 1 1 1h11c.55 0 1-.45 1-1V3c0-.55-.45-1-1-1zm0 12H2V5h11v9zM5 3H4V1h1v2zm6 0h-1V1h1v2zM6 7H5V6h1v1zm2 0H7V6h1v1zm2 0H9V6h1v1zm2 0h-1V6h1v1zM4 9H3V8h1v1zm2 0H5V8h1v1zm2 0H7V8h1v1zm2 0H9V8h1v1zm2 0h-1V8h1v1zm-8 2H3v-1h1v1zm2 0H5v-1h1v1zm2 0H7v-1h1v1zm2 0H9v-1h1v1zm2 0h-1v-1h1v1zm-8 2H3v-1h1v1zm2 0H5v-1h1v1zm2 0H7v-1h1v1zm2 0H9v-1h1v1z"/></svg>');
}


.input {  
  line-height: 1.25rem;
  color: var(--text-color);
  font-size: 13px;
  padding: 0.5rem; 
  border:  none;
  border-radius: 5px;
  width: 100%;
  height: 35px;
  background-color: transparent; 
  outline-color: transparent;
  outline-offset: 2px;
  outline-style: dashed;
  outline-width: 1px; 
  transition-duration: .3s; 
  transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
}

.input:focus-within {  
  outline-color: transparent; 
}
 
.row {
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: space-between; 
}

.row .col {
  width: 100%;
  position: relative; 
}


.btnPrimary {
  margin-top: 20px;
  display: block;
  text-align: center;
  padding:  7px 20px;
  cursor: pointer;
  line-height: 1.25rem;
  color: #fff;
  font-size: .875rem; 
  font-weight: 600; 
  border: none;
  border-radius: 0.3rem;
  width: 100%;
  max-width: 200px;
  /* height: 2.5rem; */
  font-feature-settings: "case" on;
  background-color: var(--primary-color);
  transition-duration: .3s;
  transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
}


 
@media (max-width: 700px) {
   
  .exelBtn { 
      padding: 0px; 
  }

  .exelBtn span.icon {   
      margin-right: 0;
  }
  
  .exelBtn span.text { 
  display: none;
  }
}


@media (max-width: 426px) {
   
  .exelBtn { 
      display: none;
  } 

}