.head{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    align-content: center;
    gap: 10px;
    align-items: stretch;
    justify-content: space-between;
}

.headMain {
    display: flex;
    height: 4rem;
    gap: 0.5rem;
    position: relative;
    align-items: center;
    justify-content: space-between;
}

.headMain h1 {
   color: rgb(30 41 59);
   line-height: 1.5;
   font-weight: 300;
   font-size: 18px; 
   font-feature-settings: "case" on;
}
 
.pad0 {
    padding: 0 !important;
}
 
.btntbl {
    padding: 0 10px !important;
    width: 25px !important; 
}

.id {
    width: 25px !important;
    text-align: center !important;
}
 
.btns {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
}


.modalHead {
    padding: 1.5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
 
.modalHead h3 {
    display: flex;
    align-items: center;
    color: rgb(100 116 139);
    padding: 0;
    margin: 0;
    font-weight: 300;
    font-size: 1.125rem; 
    font-feature-settings: "case" on; 
}
 
.modalHead h3 span{
    height: 30px;
    width: 30px;
    border-radius: 999px;
    color:rgb(100 116 139);
    background-color: rgb(241 245 249);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}
 
.modalHead h3 span svg{
    height: 18px;
    width: 18px; 
}
 
.datebaseBtn {
    cursor: pointer;
    text-decoration: underline;
}
  
.precode {
    display: flex;
    gap: 20px;
}

.precode pre {
    background-color: rgba(226, 232, 240, 0.512);
    width: 50%;
    border-radius: 7px;
    padding: 15px;
    border: 1px solid #6e6e6e52;
} 

.logBtn { 
    text-align: center;
    border-radius: 50px;
    line-height: 1.25;
    font-weight: 400; 
    padding: 1px 6px;
    font-size: 12px;
    color: rgb(100 116 139); 
    background-color: rgb(226 232 240);
    cursor: pointer;
    transition: background-color 0.2s ease, border-color 0.2s ease;
}

.editBtn:hover { 
    background-color: rgb(226 232 240);
}


.searchBox {
    position: relative; 
    border: 1px solid var(--border-color);
    background-color: #fff;
    border-radius: 5px; 
    display: flex;
    align-items: stretch;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%; 
    justify-content: space-between;
    margin-bottom: 25px;
    z-index: 99;
}


.col {
    flex: 1;
    border-right: 1px solid var(--border-color);
}

.col:last-child {
    flex: 1;
    border-right: none;
}

.inBox {
   display: flex;
   position: relative;
}

.inBox > div {
   width: 100%;
}




.input {  
    line-height: 1.25rem;
    color: var(--text-color);
    font-size: 13px;
    padding: 0.5rem; 
    border:  none;
    border-radius: 5px;
    width: 100%;
    height: 35px;
    background-color: transparent; 
    outline-color: transparent;
    outline-offset: 2px;
    outline-style: dashed;
    font-feature-settings: "case" on;
    outline-width: 1px; 
    transition-duration: .3s; 
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}

.input:focus-within {  
    outline-color: transparent; 
}