 
 .rowsBtn {  
  color: var(--secondary-text-color);
  display: flex;
  background-color: rgb(255 255 255);
  border: 1px solid var(--border-color);
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  height: 30px;
  width: 30px;
  align-items: center;
  justify-content: center;

}
 
 
 .rowsBtn svg {   
  height: 18px; 
}
 
 

.modalHead {
    padding: 1.5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
 
.modalHead h3 {
    display: flex;
    align-items: center;
    color: var(--text-color);
    padding: 0;
    margin: 0;
    font-weight: bold;
    font-size: 1.125rem; 
    font-feature-settings: "case" on; 
}
 
.modalHead h3 span{
    height: 30px;
    width: 30px;
    border-radius: 999px;
    color:rgb(100 116 139);
    background-color: rgb(241 245 249);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}
 
.modalHead h3 span svg{
    height: 18px;
    width: 18px; 
}







.formCheck   { 
  margin-top: 1px; 
  margin-bottom: 1px; 
}


.formCheck input { 
  display: none; 
}

.formCheck label {
  position: relative;
  cursor: pointer; 
  color: var(--secondary-text-color);
  font-size: 13px; 
}


.formCheck label span{
  font-weight: bold; 
}

.formCheck label:before {
  content: '';
  background-color: var(--secondary-background);
  border: 1px solid var(--border-color);
  box-shadow: none;
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 2px;
}

.formCheck input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 2px;
  left: 6px;
  width: 6px;
  height: 12px;
  border: solid var(--secondary-text-color);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}