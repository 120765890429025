.modalHead {
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0px;
}
 
.modalHead h3 {
    display: flex;
    align-items: center;
    color: var(--text-color);
    padding: 0;
    margin: 0;
    font-weight: 600;
    font-size: 18px; 
    font-feature-settings: "case" on; 
}
 
.modalHead h3 span{
    height: 30px;
    width: 30px;
    border-radius: 999px;
    color: var(--secondary-text-color);
    background-color: var(--secondary-background);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}
 
.modalHead h3 span svg{
    height: 18px;
    width: 18px; 
}

  
.input {  
    line-height: 1.25rem;
    color: var(--text-color);
    font-size: 12px;
    padding: 0.5rem; 
    border: 1px solid var(--border-color);
    border-radius: 5px;
    width: 100%;
    height: 35px;
    background-color: rgb(255 255 255); 
    outline-color: transparent;
    outline-offset: 2px;
    outline-style: dashed;
    outline-width: 1px; 
    transition-duration: .3s; 
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}

.input::placeholder {
    color: var(--placeholder-text-color);
    position: relative;
}

.input:focus-within {  
    outline-color: var(--border-color);
} 
   
.groupInput {     
    margin-bottom: 10px;
}
 

.groupInput label { 
    width: 100%;
    font-size: 13px;
    display: flex;
    font-weight: 500;
    color: var(--text-color);
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 5px;
}
 
.groupInput .inputDiv {
    width: 100%;
}
 
.btnPrimary {
    margin-top: 15px;
    margin-left: auto;
    display: block;
    text-align: center;
    padding: 3px 9px;
    cursor: pointer;
    line-height: 1.25rem;
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    width: 100%;
    /* max-width: 100px; */
    font-feature-settings: "case" on;
    background-color: var(--primary-color);
}

   
.linkinvoice {
    align-items: center;
    background: #d1e7dd;
    color: #198754;
    display: flex;
    font-style: italic;
    gap: 5px;
    justify-content: flex-end;
    margin-right: 4px;
    margin-left: 5px;
    padding: 3px;
    border-radius: 24px;
    text-decoration: underline;
}
  
.linkinvoice svg {
    height: 16px;
    width: 16px; 
}


.greenbtn { 
    background-color: #d1e7dd;
    color: rgba(25, 135, 84);
    cursor: pointer;
    border: 1px solid  #d1e7dd;

} 




.btnContainerTop { 
    border: 1px solid;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    cursor: pointer;
    align-items: center;
    font-feature-settings: "case" on;
    line-height: 1.25;
    font-weight: 500;
    padding: 0px;
    font-size: 13px;
    overflow: hidden;
    width: 100%;
    color: var(--secondary-text-color);
    border-color: var(--border-color);
}

 
.btnContainerTop span.icon { 
    max-width: 28px;
    max-height: 28px;
    min-width: 28px;
    min-height: 28px;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    display: flex;
    color: var(--secondary-text-color);
    background-color: var(--secondary-background);
}

.btnContainerTop span.icon svg {  
    max-width: 18px;
    max-height: 18px;
    min-width: 18px;
    min-height: 18px; 
}

.link2ed {
    color: 'var(--primary-color)';
    display: 'flex';
    align-items: 'center';
    gap: '5px';
    font-weight: 'bold';
    margin-top: '10px';
}

.link2ed svg {
    height: '20px';
    width: '20px';
}

.btnTxt { 
  
    text-overflow: ellipsis;
    overflow: hidden; 
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.BtnCargo {   
    display: flex;
    font-size: 12px;
    color: var(--text-color); 
    cursor: pointer;
    align-items: center;
    border-radius: 120px;
}
 
.BtnCargo svg {  
    height: 16px;
    width: 16px; 
    color: var(--secondary-text-color);
    fill: var(--secondary-text-color);
    transition: color 0.2s ease ;
}
 
.BtnCargo.greenbtn   {   
    color: green;
    background: #d1e7dd; 
}

.BtnCargo.greenbtn svg {   
    color: green;
    fill: #198754;   
}