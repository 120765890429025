 
.removeBtn { 
    color: #dc2626;
    background-color: rgb(220 38 38 / 10%);
    border-radius: 99.375rem;
    padding: 3px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 13px;
    line-height: 1;
}

.removeBtn { 
    border-radius: 50px;
    line-height: 1.25;
    font-weight: 400;
    padding: 2px 3px;
    font-size: 12px;
    color: rgb(100 116 139);
    display: flex;
    background-color: rgb(241 245 249);
    cursor: pointer;
    transition: background-color 0.2s ease, border-color 0.2s ease;
}

.removeBtn:hover {
    cursor: pointer;
    color: #dc2626;
    background-color: rgb(220 38 38 / 10%); 
}

.removeBtn:hover svg{ 
    color: #dc2626; 
}
 
.removeBtn svg {  
    height: 16px;
    width: 16px;
    margin-right: 3px;
    color: rgb(100 116 139);
    fill: rgb(100 116 139);
    transition: color 0.2s ease ;
}


 
 
.removeBtnYes { 
    color: #dc2626;
    background-color: rgb(220 38 38 / 10%);
    border-radius: 99.375rem;
    padding: 8px 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 12px;
    line-height: 1.25; 
    transition: background-color 0.2s ease, border-color 0.2s ease;
}
 
.removeBtnYes:hover {
    cursor: pointer;
    color: #dc2626;
    background-color: rgb(220 38 38 / 10%); 
}
  
 
.message {
    font-size: 13px;
    color: rgb(100 116 139);
    line-height: 1.25;
    margin-bottom: 20px;
    text-align: center;
}

.buttons { 
    line-height: 1.25;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
 

.modalHead {
    padding: 1.5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
 
.modalHead h3 {
    display: flex;
    align-items: center;
    color: rgb(100 116 139);
    padding: 0;
    margin: 0;
    font-weight: 300;
    font-size: 1.125rem; 
    font-feature-settings: "case" on; 
}
 
.modalHead h3 span{
    height: 30px;
    width: 30px;
    border-radius: 999px;
    color:rgb(100 116 139);
    background-color: rgb(241 245 249);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}
 
.modalHead h3 span svg{
    height: 18px;
    width: 18px; 
}