
.container  {
    position: relative;
    display: block;
    max-width: 1450px;
    margin-right: auto;
    margin-left: auto;
}

.main {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    max-width: 100%; 
    width: 100%;
    position: relative; 
    background-color: #f6f7f7;
    min-height: 100vh; 
} 

.main.containerBig {  
    margin-inline-start: 200px;
    max-width: calc(100% - 200px);
    
}
  

.head {
    padding: 15px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
 
.head .h1 {
    display: flex;
    align-items: center;
    color: var(--secondary-text-color);
    padding: 0;
    margin: 0;
    font-weight: 300;
    font-size: 1.125rem; 
    font-feature-settings: "case" on; 
}
 
.head .h1 span{
    height: 30px;
    width: 30px;
    border-radius: 999px;
    color:var(--muted-text-color);
    background-color:var(--secondary-background);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}
 
.head .h1 span svg{
    height: 18px;
    width: 18px; 
}

 
.title {
    font-feature-settings: "case" on;
    color: var(--secondary-text-color);
    padding: 0; 
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    padding-bottom: 10px;
    margin-bottom: 15px;
    position: relative;
    z-index: 4;
    font-size: 14px;
    font-weight: 500;
}



.threeBox { 
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-right: -0.5rem;
    margin-left: -0.5rem;
}

.col {
    position: relative;
    flex-shrink: 0;
    flex: 0 0 auto;
    width: 33.33333333%;
    max-width: 100%;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}
 

.colBox {
    width: 100%;
    min-height: 450px;
    height: 100%;
    background-color: white;
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    display: inline-block;
}
 


.containerPhoto {   
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}
  
.containerPhoto .image{ 
    position: relative;
    flex-shrink: 0;
    flex: 0 0 auto;
    width: 33.33333333%;
    max-width: 100%;
    height: 100px;
    margin-bottom: 10px;
    padding-right: 5px;
    padding-left: 5px;
}
 
.containerPhoto  .image img{ 
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
    cursor: pointer;
}




.downBTN {
    border-radius: 5px;
    line-height: 1.25;
    padding: 2px 6px;
    font-feature-settings: "case" on;
    font-size: 14px;
    font-weight: 500;
    right: 30px;
    top: 20px;
    position: absolute;
    z-index: 5;
    background-color: #e8e8e8;
    cursor: pointer;
    display: flex;
    transition: background-color 0.2s ease, border-color 0.2s ease;
    align-items: center;
}
 

 

.downBTN svg{
   width: 15px;
   height: 15px;
   margin-right: 5px;
}
 

.noImage {  
    margin-top: 110px;
    color: #8b8e8f;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    font-style: italic;
    opacity: .5;
    width: 100%; 
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .modalContent {
    position: relative;  
    text-align: center;
  }
  
  .modalImage {
    max-width: 90vw;
    max-height: 90vh;
  }
  
  .modalClose {
    position: absolute;
    top: 20px;
    right: 20px; 
    border-radius: 50px;  
    cursor: pointer; 
    display: flex;
    align-items: center;
    justify-content: center;
  }

    .modalClose svg {
        color: #fff;
        height: 28px;
        opacity: .6;
        width: 28px;
        transition: opacity 0.2s ease;
    }

    .modalClose:hover svg { 
        opacity: 1; 
    }

    .modalNav {
        position: absolute;
        top: 50%;
        background-color: transparent;
        transform: translateY(-50%);
        cursor: pointer;
        color: #fff;
        width: 65px;
        display: flex;
        padding: 0;
        height: 100%;
        align-items: center;
    }

    .modalNav svg {
         color: #fff;
         width: 50px;
         height: 50px;
         filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .3));
    }

    .modalNavPrev {
        left: 0;
    }

    .modalNavNext {
        right: 0;
 
    }
 

    .modalNavPrev svg {
        margin-left: 20px;  

    }

    .modalNavNext svg {
        margin-right: 20px; 
        transform: rotate(180deg);

    }
 
    .logo svg {
        height: 35px;
        display: flex;
    }
  

@media (max-width: 1325px) { 
     
    .col { 
        margin-bottom: 20px;
        width: 100%; 
    }
 
    .main {
        padding-left: 10px;
        padding-right: 10px; 
    }


    .head {
        padding: 12px 0; 
    }

}