.TotalPrice {
    font-weight: bold;
    color: var(--text-color);
    font-size: 16px;
}

.TotalPrice .curr {
    font-weight: bold;
    color: var(--text-color);
    font-size: 12px;
    margin-right: 10px;
}

.row {
    gap: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-end;
}



.input {  
    line-height: 1.25rem;
    color: var(--text-color);
    font-size: 14px;
    padding: 0.5rem; 
    border: 1px solid var(--border-color);
    border-radius: 0.3rem;
    width: 100%;
    height: 32px;
    background-color: rgb(255 255 255); 
    outline-color: transparent;
    outline-offset: 2px;
    outline-style: dashed;
    outline-width: 1px;  
}

.input:focus-within {  
    outline-color: rgb(203 213 225/1); 
}

 

.input::placeholder {
    color: var(--placeholder-text-color);
    position: relative;
}



.priceInput {
    display: flex; 
    line-height: 1.25rem;
    color:  var(--text-color);
    font-size: 11px;
    padding: 0;
    border: 1px solid var(--border-color);
    border-radius: 0.3rem;
    width: 100%;
    height: 30px;
    background-color: rgb(255 255 255);
    outline-color: transparent;
    outline-offset: 2px;
    outline-style: dashed;
    outline-width: 1px;
    transition-duration: .3s;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    align-items: center;
    flex-direction: row;
    overflow: hidden;

}

.priceInput .priceCurrency {  
    font-size: 11px;
    font-weight: 600;
    min-width: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--muted-text-color);
    background-color: var(--secondary-background);
    height: 100%;
}

.priceInput .input {
    background-color: transparent;
    border-color: transparent;
    width: 100%;
    height: 100%;
    text-align: center;
    outline: none !important;
    border-radius: 0;
    font-weight: 600;
    border-left: 1px solid  var(--border-color);
}


.priceInput .input:visited,
.priceInput .input:active,
.priceInput .input:focus,
.priceInput .input:focus-within {  
    outline: 0; 
    
}

.priceInput .butPlusMinus { 
    width: 70px;
    height: 100%;
    display: flex;
    border-radius: 0;
    border-left: 1px solid  var(--border-color);
    flex-direction: column;
    align-items: stretch;
}

.priceInput .butPlusMinus .button { 
    cursor: pointer;
    height: 50%;
    display: flex;
    background-color: rgb(241 245 249);
    color: var(--secondary-text-color);
    align-items: center;
    justify-content: center;
}

.priceInput .butPlusMinus .button:first-child { 
    border-bottom: 1px solid  var(--border-color);
}

.priceInput .hundred { 
    width: 28px;
    padding: 3px 5px;
    height: 100%;
    display: flex;
    border-radius: 0;
    border-left: 1px solid var(--border-color);
    background-color: var(--secondary-background);
    color: var(--muted-text-color);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}
 


.uploadDiv {
    cursor: pointer;
    color: var(--text-color);
    border: 1px solid var(--border-color);
    border-radius: 0.3rem;
    width: 100%;
    height: 32px;
    background-color: var(--secondary-background);
}


.uploadDiv input {
    cursor: pointer;
    color: var(--text-color);
    width: 100%;
    height: 100%;
    padding: 4px;
}

.uploadDiv input::file-selector-button:hover {
    background-color: var(--secondary-background);
  }
  
.uploadDiv input::file-selector-button:active {
    background-color: var(--secondary-background);
  }
  

.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}
.inputfile + label {
    font-size: 1.25em;
    font-weight: 700;
    color: white;
    background-color: black;
    display: inline-block;
    cursor: pointer;
}

.inputfile:focus + label,
.inputfile + label:hover {
    background-color: red;
}

.inputfile:focus + label {
	outline: 1px dotted #000;
	outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
	pointer-events: none;
}


.uploadDiv svg {
    opacity: 0.5;
    width: 100%;
    height: 100%;
    fill: rgb(100 116 139);
    color: rgb(100 116 139);
}

.addBox {
    display: flex;
    margin-top: 7px;
    justify-content: flex-end;
}

.addBox .addBtn { 
    cursor: pointer;
    line-height: 1;
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    text-align: center;
    min-width: 140px;
    padding: 7px 8px;
    border: none;
    border-radius: 0.3rem;
    font-feature-settings: "case" on;
    background-color: var(--primary-color);
    transition-duration: .3s;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);

}

.textCenter {
    text-align: center;
}

.textLeft {
    text-align: start;
}

.textEnd {
    text-align: end;
}


.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
}

.table td, .table th {
    padding: 5px;
    vertical-align: center;
    font-weight: normal;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}



.table2 {
    margin-top: 20px;
    width: 100%;
    margin-left: auto;
    max-width: 330px; 
    border-radius: 8px;
    position: relative; 
}

.table2 table tbody tr td {
   padding: 0 10px;
}
 


 


.formField {  
    width: 100%;
    max-width: 330px;
    padding: 17px 14px 17px 13px;
    border: 1px solid;
    border-color: var(--border-color);
    background-color: #fff;
    border-radius: 8px;
    position: relative;
    height: 100%;
    margin: 0;
    margin-top: 30px;
    margin-left: auto;
}


.formField legend {
    position: absolute;
    top: -14px;
    left: 10px ; 
    border-radius: 17px;
    line-height: 1.25;
    font-weight: 600;
    padding: 4px 10px 4px 10px;
    font-feature-settings: "case" on;
    font-size: 13px;
    color: var(--text-color);
    display: flex;
    background-color: #f2f2f2;
    border: 0px solid var(--border-color);
}



.pad0 {
    padding: 0 !important;
}
 
.btntbl {
    padding: 0 10px !important;
    width: 25px !important; 
}

.id {
    width: 25px !important;
    text-align: center !important;
}
 
.btns {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
}
 

 
.removeBtn { 
    
    border-radius: 5px;
    line-height: 1.25;
    font-weight: 400;
    padding: 2px 3px;
    font-size: 12px;
    color: var(--text-color);
    display: flex;
    align-items: center;
    border: 1px solid var(--border-color);
    justify-content: center;
    background-color: var(--secondary-background);
    cursor: pointer;
    transition: background-color 0.2s ease, border-color 0.2s ease;
}

.removeBtn:hover {
    background-color: var(--third-background);
}
 
 
.removeBtn svg {  
    height: 16px;
    width: 16px;
    margin-right: 3px;
    color: var(--secondary-text-color);
    fill:var(--secondary-text-color);
    transition: color 0.2s ease ;
}


.pricein {  
    color: rgb(49 57 69);
    font-size: 13px;
    font-weight: bold;
}

.modalHead {
    padding: 1.5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.modalHead h3 {
    display: flex;
    align-items: center;
    color: var(--secondary-text-color);
    padding: 0;
    margin: 0;
    font-weight: 300;
    font-size: 1.125rem; 
    font-feature-settings: "case" on; 
}
 
.modalHead h3 span{
    height: 30px;
    width: 30px;
    border-radius: 999px;
    color:var(--secondary-text-color);
    background-color: var(--secondary-background);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}
 
.modalHead h3 span svg{
    height: 18px;
    width: 18px; 
}







.formCheck input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.formCheck label {
    position: relative;
    cursor: pointer; 
    color: var(--secondary-text-color);
    font-size: 13px; 
}


.formCheck label span{
    font-weight: bold; 
}

.formCheck label:before {
    content: '';
    background-color: var(--secondary-background);
    border: 1px solid var(--border-color);
    box-shadow: none;
    padding: 8px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 0;
    border-radius: 2px;
}

.formCheck input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 6px;
    width: 6px;
    height: 12px;
    border: solid var(--secondary-text-color);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}



.dataContainer {
    width: 100%;
}

.dataContainer .input {
    padding-inline-start: 30px;
    padding-inline-end: 10px;
    background-repeat: no-repeat;
    background-size: 17px;
    background-position: 6px center;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="28" height="32" viewBox="0 0 14 16"><path fillRule="evenodd" fill="rgb(139 142 143)" d="M13 2h-1v1.5c0 .28-.22.5-.5.5h-2c-.28 0-.5-.22-.5-.5V2H6v1.5c0 .28-.22.5-.5.5h-2c-.28 0-.5-.22-.5-.5V2H2c-.55 0-1 .45-1 1v11c0 .55.45 1 1 1h11c.55 0 1-.45 1-1V3c0-.55-.45-1-1-1zm0 12H2V5h11v9zM5 3H4V1h1v2zm6 0h-1V1h1v2zM6 7H5V6h1v1zm2 0H7V6h1v1zm2 0H9V6h1v1zm2 0h-1V6h1v1zM4 9H3V8h1v1zm2 0H5V8h1v1zm2 0H7V8h1v1zm2 0H9V8h1v1zm2 0h-1V8h1v1zm-8 2H3v-1h1v1zm2 0H5v-1h1v1zm2 0H7v-1h1v1zm2 0H9v-1h1v1zm2 0h-1v-1h1v1zm-8 2H3v-1h1v1zm2 0H5v-1h1v1zm2 0H7v-1h1v1zm2 0H9v-1h1v1z"  /></svg>');
}

.dataContainer .errorVal .input {
    border-color: #d80228;
}