 
.modalHead {
    padding: 1.5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
 
.modalHead h3 {
    display: flex;
    align-items: center;
    color: var(--text-color);
    padding: 0;
    margin: 0;
    font-weight: 700;
    font-size: 1.125rem;
    font-feature-settings: "case" on;
}
 
.modalHead h3 span{
    height: 34px;
    width: 34px;
    border-radius: 999px;
    color: var(--secondary-text-color);
    border: 1px solid  var(--border-color);
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}
 
.modalHead h3 span svg{
    height: 18px;
    width: 18px; 
}
 
.head3item {   
    position: relative; 
    display: flex;
    flex-wrap: wrap;
    margin-right: -0.5rem;
    margin-left: -0.5rem;
}

.head3item .cols {  
    flex-shrink: 0;  
    flex: 0 0 auto; 
    width: 33.33333333%; 
    max-width: 100%;   
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}
 
.inp2colCar {
    margin-bottom: 20px;
    display: flex;
    gap: 10px;
}

.inp2colCar > * {
    width: 100%;
}

.bigtran {
    position: relative;
}

.inputDownPort {  
    line-height: 1.2;
    color: var(--text-color); 
    font-size: 13px;
    font-weight: 500;
    padding: 0.5rem; 
    border: 1px solid var(--border-color);
    border-radius: 5px;
    width: 100%;
    height: 35px;
    background-color: rgb(255 255 255); 
    outline-color: transparent;
    outline-offset: 2px;
    outline-style: dashed;
    outline-width: 1px; 
    transition-duration: .3s; 
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}
 
.horTitle { 
    position: absolute;
    font-size: 11px;
    font-weight: 500;
    color: var(--muted-text-color);
    line-height: 1;
    margin-bottom: 4px;
    top: -6px;
    z-index: 1;
    left: 8px;
    padding: 0 3px;
    background: #fff;
}

.colBox {
    background-color: #fff;
    padding: 20px;
    border: 1px solid  var(--border-color);
    border-radius: 5px;
}

.transPriceBottom { 

    margin-top: 10px;
    background: #f2f2f2;
    padding: 6px 0px;
    border-radius: 0px 0px 6px 6px;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: -20px;
}


.transPrice {
    display: flex;
    gap: 14px;
    align-items: center;
    justify-content: space-between;
    padding: 4px 20px;
}


.transPrice{
    border-bottom: 1px solid rgb(0 0 0 / 8%);
}

.transPrice:last-child{
    border-bottom: 1px solid transparent;
}

.transPrice .transText { 
    font-weight: 500;
    font-size: 13px;
    font-feature-settings: "case" on;
}

.transPrice span { 
    font-weight: 700;
    font-size: 17px;
    text-align: end; 
}