.headMain {
  padding-top: 8px;
  padding-bottom: 0;
  display: flex; 
  gap: 0.5rem;
  position: relative;
  align-items: center;
  justify-content: space-between;
}

.headMain h1 {
  color: var(--text-color);
  line-height: 1;
  font-weight: bold;
  font-size: 22px;
  font-feature-settings: "case" on;
  display: flex;
  align-items: center;
}


.cardContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start; /* Aligns items to the left */ 
}

.card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 16px;
  width: calc(25% - 20px); /* Adjusts the width for 4 cards per row, taking into account the gap */
}

.unpaidCard {
  border-color: rgba(255, 86, 48, 0.35);
  background-color: rgba(255, 86, 48, 0.16);
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.cardBody h3 {
  margin-top: 0;
  color: var(--text-color);
}

.cardBody p {
  margin: 4px 0;
}

.cardContainer {
  margin-bottom: 20px; /* Provides spacing between cards and pagination controls */
}

.paginationContainer {
  text-align: center; /* Centers the pagination controls if you prefer */
  padding: 20px 0; /* Adds padding around the pagination controls */
} 