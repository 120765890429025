 
 
.groupInput { 
    flex: 1;
    position: relative;  
    box-sizing: border-box;
}

.groupInput > * {
    width: 100%;
}
  
.groupInput .input::placeholder {
    color: var(--placeholder-text-color);
    position: relative;
}
 
.input {   
    border: 1px solid var(--border-color);
    border-radius: 2px;
    width: 110px;
    height: 20px; 
    outline-color: transparent;
    outline-offset: 2px;
    outline-style: dashed;
    outline-width: 1px;  
 
    border: 1px solid var(--border-color);
    border-radius: 2px;
    text-align: center;
    font-weight: 600;
    color: var(--secondary-text-color);
}

.input::placeholder {
    color: var(--placeholder-text-color);
    position: relative;
}

.input[disabled] {
    cursor: not-allowed;
 }

.input:focus-within {  
    border-color: var(--border-color); 
}
  
.dataContainer {
    width: 100%;
}

.dataContainer .input { 
    background-repeat: no-repeat;
    background-size: 14px;
    background-position: 6px center;
}