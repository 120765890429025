.head{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    align-content: center;
    gap: 10px;
    align-items: stretch;
    justify-content: space-between;
}

.headMain {
    padding-top: 8px;
    padding-bottom: 0;
    display: flex; 
    gap: 0.5rem;
    position: relative;
    align-items: center;
    justify-content: space-between;
}

.headMain h1 {
    color: var(--text-color);
    line-height: 1;
    font-weight: bold;
    font-size: 22px;
    font-feature-settings: "case" on;
    display: flex;
    align-items: center;
}
 
 
 
.pad0 {
    padding: 0 !important;
}
.btntbl {
    padding: 0 10px !important;
    width: 25px !important; 
}

.id {
    width: 25px !important;
    text-align: center !important;
}
 
.btns {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
}


.searchBox {
    max-width: 400px;
    position: relative;
    z-index: 9;
    border: 1px solid var(--border-color);
    background-color: #fff;
    border-radius: 6px;
    display: flex;
    align-items: stretch;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    /* max-width: 919px; */
    justify-content: space-between;
    margin-bottom: 10px;
    margin-left: auto;
}

.col {
    width: 100%;
    max-width: 100%;
}



.inBox {
    display: flex;
}

.inBox > div {
    width: 100%;
}

.inBoxDate {
    position: relative;
    display: flex;
    border-left: 0px solid var(--border-color); 
}

.inBoxDate .dateGroup {
    position: relative;
    display: flex;
    border-right: 0px solid var(--border-color);  
} 

.inBoxDate .dateGroup .place {
    position: absolute;
    right: 5px;
    top: 7px;
    border-radius: 50px;
    line-height: 1.25;
    font-weight: 400;
    padding: 2px 5px;
    font-size: 12px;
    color: rgb(71 85 105 / 60%);
    display: flex;
    background-color: rgb(241 245 249);
}



.dataContainer {
    width: 100%;
}

.dataContainer .input {
    padding-inline-start: 30px;
    padding-inline-end: 10px;
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: 6px center;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="28" height="32" viewBox="0 0 14 16"><path  fill="rgba(0, 0, 0, 0.5)" fillRule="evenodd" d="M13 2h-1v1.5c0 .28-.22.5-.5.5h-2c-.28 0-.5-.22-.5-.5V2H6v1.5c0 .28-.22.5-.5.5h-2c-.28 0-.5-.22-.5-.5V2H2c-.55 0-1 .45-1 1v11c0 .55.45 1 1 1h11c.55 0 1-.45 1-1V3c0-.55-.45-1-1-1zm0 12H2V5h11v9zM5 3H4V1h1v2zm6 0h-1V1h1v2zM6 7H5V6h1v1zm2 0H7V6h1v1zm2 0H9V6h1v1zm2 0h-1V6h1v1zM4 9H3V8h1v1zm2 0H5V8h1v1zm2 0H7V8h1v1zm2 0H9V8h1v1zm2 0h-1V8h1v1zm-8 2H3v-1h1v1zm2 0H5v-1h1v1zm2 0H7v-1h1v1zm2 0H9v-1h1v1zm2 0h-1v-1h1v1zm-8 2H3v-1h1v1zm2 0H5v-1h1v1zm2 0H7v-1h1v1zm2 0H9v-1h1v1z"/></svg>');
}

.dataContainer .reciveAnput {  
    padding-inline-end: 5px;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="rgba(0, 0, 0, 0.1)" d="m20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8l8-8z"/></svg>') !important;   

}

.dataContainer .arriveAnput {  
    padding-inline-end: 5px; 
    background-image:  url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="rgba(0, 0, 0, 0.1)" d="M6.4 18L5 16.6L14.6 7H6V5h12v12h-2V8.4L6.4 18Z"/></svg>') !important;   
}




.input {  
    line-height: 1.25rem;
    color: var(--text-color);
    font-size: 13px;
    padding: 0.5rem; 
    border:  none;
    border-radius: 5px;
    width: 100%;
    height: 35px;
    background-color: transparent; 
    outline-color: transparent;
    outline-offset: 2px;
    outline-style: dashed;
    outline-width: 1px; 
    transition-duration: .3s; 
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}

.input:focus-within {  
    outline-color: transparent; 
}



.exelBtn {  
    color: var(--secondary-text-color);
    border: 1px solid var(--border-color);
    background-color: #fff;
    border-radius: 5px;
    padding: 0 10px 0 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-feature-settings: "case" on;
    font-weight: 600;
}

 
.exelBtn span.icon {  
    max-width: 28px;
    max-height: 28px;
    min-width: 28px;
    min-height: 28px;
    align-items: center;
    justify-content: center;
    margin-right: 2px; 
    display: flex;
}

.exelBtn span.icon svg {  
    max-width: 18px;
    max-height: 18px;
    min-width: 18px;
    min-height: 18px; 
}


@media (max-width: 1025px) {

    .headMain h1 {  
        font-size: 16px;  
    }
}


.Btn { 
    border: 1px solid;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    cursor: pointer;
    align-items: center;
    font-feature-settings: "case" on;
    line-height: 1.25;
    font-weight: 600;
    padding: 0px 7px 0px 0px;
    font-size: 13px;
    overflow: hidden;
    color: var(--secondary-text-color);
    border-color: var(--border-color);
}

 
.Btn span.icon { 
    max-width: 28px;
    max-height: 28px;
    min-width: 28px;
    min-height: 28px;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
    display: flex;
    color: var(--secondary-text-color);
    background-color: var(--secondary-background);
}

.Btn span.icon svg {  
    max-width: 18px;
    max-height: 18px;
    min-width: 18px;
    min-height: 18px; 
}
.AddBtn { 
    border-radius: 5px;
    display: flex;
    cursor: pointer;
    align-items: center;
    font-feature-settings: "case" on;
    line-height: 1.25;
    font-weight: 600;
    font-size: 13px;
    overflow: hidden;
    color: var(--secondary-text-color);
    border-color: var(--border-color);
    margin: auto;
}

 
.AddBtn span.icon { 
    max-width: 28px;
    max-height: 28px;
    min-width: 28px;
    min-height: 28px;
    align-items: center;
    justify-content: center;
    display: flex;
    color: var(--secondary-text-color);
    background-color: var(--secondary-background);
    border-radius: 5px;
    margin: auto;
}

.AddBtn span.icon svg {  
    max-width: 18px;
    max-height: 18px;
    min-width: 18px;
    min-height: 18px; 
}
 
.modalHead {
    padding: 1.5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
 
.modalHead h3 {
    display: flex;
    align-items: center;
    color: var(--text-color);
    padding: 0;
    margin: 0;
    font-weight: 600;
    font-size: 1.125rem; 
    font-feature-settings: "case" on; 
}
 
.modalHead h3 span{
    height: 30px;
    width: 30px;
    border-radius: 999px;
    color:var(--secondary-text-color);
    background-color:var(--secondary-background);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}

.modalHead h3 span svg{
    height: 18px;
    width: 18px; 
}
  
.groupInput {
    margin-bottom: 15px;
    position: relative;
}
 

.groupInput .input::placeholder {
    color: var(--placeholder-text-color);
    position: relative;
}

.groupInput label { 
    position: absolute;
    top: -8px;
    left: 8px;
    color: var(--muted-text-color);
    background-color: #fff;
    font-size: 12px;
    line-height: 1;
    padding: 0px 3px;
}
 
.input {  
    line-height: 1.25rem;
    color: var(--text-color);
    font-size: 13px;
    padding: 0 10px; 
    border: 1px solid var(--border-color);
    border-radius: 5px;
    width: 100%;
    height: 35px;
    background-color: rgb(255 255 255); 
    outline-color: transparent;
    outline-offset: 2px;
    outline-style: dashed;
    outline-width: 1px; 
    transition-duration: .3s; 
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}

.input::placeholder {
    color: var(--placeholder-text-color);
    position: relative;
}

.input:focus-within {  
    outline-color: none; 
}
 
.btnPrimary {
    margin-top: 20px;
    margin-left: auto;
    display: block;
    text-align: center;
    padding: 7px 20px;
    cursor: pointer;
    line-height: 1.2;
    color: #fff;
    font-size: 14px; 
    font-feature-settings: "case" on;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    width: 100%;
    max-width: 200px; 
    background-color: var(--primary-color);
    transition-duration: .3s;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}
 
.errorInput {
    border-color: rgb(225, 29, 72); 
}

.errorMsg {
    color: rgb(225, 29, 72); 
    font-size: 10px;
    position: absolute;
    bottom: -3px;
    background: #fff;
    right: 11px;
    line-height: 1; 
}


.groupPrice { 
    display: flex;  
    padding: 4px 6px;
    margin: 0 -6px;
    border-radius: 8px; 
    transition: background-color 0.2s ease ;
}
 

.groupPrice .leftLabel { 
    width:  70%;
    font-size: 13px;
    display: flex;
    color: var(--text-color);
    align-content: center;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 8px;
    font-weight: 500;
}


.groupPrice .rightInput {
    width: 30%;
}
