.removeBtn { 
    border-radius: 5px;
    line-height: 1.25;
    font-weight: 500;
    padding: 2px 3px;
    display: flex;
    width: 100%;
    font-size: 12px;
    color: var(--text-color);
    background-color: var(--secondary-background);
    border: 1px solid var(--border-color);
    cursor: pointer;
    transition: background-color 0.2s ease, border-color 0.2s ease;
    align-items: center;
}

.removeBtn:hover { 
    background-color: var(--third-background);
}
 
.removeBtn svg {  
    height: 16px;
    width: 16px;
    margin-right: 3px;
    color: var(--secondary-text-color);
    fill: var(--secondary-text-color);
    transition: color 0.2s ease ;
}


 
.removeBtnYes { 
    color: #dc2626;
    background-color: rgb(220 38 38 / 10%);
    border-radius: 99.375rem;
    padding: 8px 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 600;
    font-size: 13px;
    line-height: 1.25;
}
 
 
 
.message {
    font-size: 14px;
    color: var(--text-color);
    line-height: 1.25;
    margin-bottom: 20px;
    text-align: center;
}

.buttons { 
    line-height: 1.25;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
 

.modalHead {
    padding: 1.5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
 
.modalHead h3 {
    display: flex;
    align-items: center;
    color: var(--text-color);
    padding: 0;
    margin: 0;
    font-weight: 600;
    font-size: 18px; 
    font-feature-settings: "case" on; 
}
 
.modalHead h3 span{
    height: 30px;
    width: 30px;
    border-radius: 999px;
    color: var(--secondary-text-color);
    background-color: var(--secondary-background);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}
 
.modalHead h3 span svg{
    height: 18px;
    width: 18px; 
}
