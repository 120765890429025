.searchBox {
  border: 1px solid var(--border-color);
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  align-items: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
}

.col {
  width: 100%;
  max-width: 100%;
  border-right: 1px solid var(--border-color);
}

.colText {
  max-width: 180px;
  min-width: 180px;
  position: relative;
}

.inBox {
  display: flex;
}

.inBox > div {
  width: 100%;
}

.inBoxDate {
  position: relative;
  display: flex;
  border-left: 1px solid var(--border-color);
}

.inBoxDate .dateGroup {
  position: relative;
  display: flex;
  border-right: 1px solid var(--border-color);
}

.inBoxDate .dateGroup .place {
  position: absolute;
  right: 5px;
  top: 7px;
  border-radius: 50px;
  line-height: 1.25;
  font-weight: 400;
  padding: 2px 5px;
  font-size: 12px;
  color: rgb(71 85 105 / 60%);
  display: flex;
  background-color: rgb(241 245 249);
}

.dataContainer {
  width: 100%;
}

.dataContainer .input {
  padding-inline-start: 30px;
  padding-inline-end: 10px;
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 6px center;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="28" height="32" viewBox="0 0 14 16"><path  fill="rgba(0, 0, 0, 0.5)" fillRule="evenodd" d="M13 2h-1v1.5c0 .28-.22.5-.5.5h-2c-.28 0-.5-.22-.5-.5V2H6v1.5c0 .28-.22.5-.5.5h-2c-.28 0-.5-.22-.5-.5V2H2c-.55 0-1 .45-1 1v11c0 .55.45 1 1 1h11c.55 0 1-.45 1-1V3c0-.55-.45-1-1-1zm0 12H2V5h11v9zM5 3H4V1h1v2zm6 0h-1V1h1v2zM6 7H5V6h1v1zm2 0H7V6h1v1zm2 0H9V6h1v1zm2 0h-1V6h1v1zM4 9H3V8h1v1zm2 0H5V8h1v1zm2 0H7V8h1v1zm2 0H9V8h1v1zm2 0h-1V8h1v1zm-8 2H3v-1h1v1zm2 0H5v-1h1v1zm2 0H7v-1h1v1zm2 0H9v-1h1v1zm2 0h-1v-1h1v1zm-8 2H3v-1h1v1zm2 0H5v-1h1v1zm2 0H7v-1h1v1zm2 0H9v-1h1v1z"/></svg>');
}

.dataContainer .reciveAnput {
  padding-inline-end: 5px;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="rgba(0, 0, 0, 0.1)" d="m20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8l8-8z"/></svg>') !important;
}

.dataContainer .arriveAnput {
  padding-inline-end: 5px;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="rgba(0, 0, 0, 0.1)" d="M6.4 18L5 16.6L14.6 7H6V5h12v12h-2V8.4L6.4 18Z"/></svg>') !important;
}

.groupInput {
  width: 100%;
  position: relative;
}

.input::placeholder {
  color: var(--placeholder-text-color);
  position: relative;
}

.groupInput .input::placeholder {
  color: var(--placeholder-text-color);
  position: relative;
}

.groupInput label {
  color: rgb(148 163 184);
  color: var(--secondary-text-color);
  font-size: 0.825rem;
}

.input {
  line-height: 1.25rem;
  color: var(--text-color);
  font-size: 13px;
  padding: 0.5rem;
  border: none;
  border-radius: 5px;
  width: 100%;
  height: 35px;
  background-color: transparent;
  outline-color: transparent;
  outline-offset: 2px;
  outline-style: dashed;
  outline-width: 1px;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.input:focus-within {
  outline-color: transparent;
}

.groupInput .groupInputIcon {
  position: relative;
}

.groupInput .groupInputIcon .input {
  padding-inline-start: 28px;
  padding-inline-end: 10px;
}

.groupInput .groupInputIcon .crmIcon {
  position: absolute;
  top: 0;
  color: var(--secondary-text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 28px;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.groupInput .groupInputIcon:focus-within .crmIcon {
  --tw-text-opacity: 1;
  color: var(--primary-color);
}

.groupInput .groupInputIcon .crmIcon svg {
  display: inline-block;
  vertical-align: middle;
  height: 17px;
  width: 17px;
  color: var(--secondary-text-color);
  fill: var(--secondary-text-color);
}

.id {
  width: 25px !important;
  text-align: center !important;
}

.pad0 {
  padding: 0 !important;
}

.formCheck input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.formCheck label {
  position: relative;
  cursor: pointer;
  color: rgb(100 116 139);
  font-size: 13px;
}

.formCheck label span {
  font-weight: bold;
}

.formCheck label:before {
  content: "";
  background-color: transparent;
  border: 1px solid rgb(100 116 139);
  box-shadow: none;
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 0;
  border-radius: 3px;
}

.formCheck input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 5px;
  width: 6px;
  height: 11px;
  border: solid rgb(100 116 139);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

table tr td.stickyCol {
  position: sticky;
  top: 0 !important;
  left: 0 !important;
  z-index: 1;
}

table tr td.stickyColSecond {
  position: sticky;
  left: 32px !important;
  top: 0 !important;
  z-index: 1;
}

table tr td.stickyColLast {
  position: sticky;
  right: 0 !important;
  top: 0 !important;
  z-index: 1;
}

.searchBTN {
  border: 1px solid;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  cursor: pointer;
  align-items: center;
  font-feature-settings: "case" on;
  line-height: 1.25;
  font-weight: 500;
  padding: 0px 7px 0px 0px;
  font-size: 13px;
  overflow: hidden;
  color: var(--secondary-text-color);
  border-color: var(--border-color);
  display: none;
}

.searchBTN span.icon {
  max-width: 28px;
  max-height: 28px;
  min-width: 28px;
  min-height: 28px;
  align-items: center;
  justify-content: center;
  margin-right: 7px;
  display: flex;
  color: var(--secondary-text-color);
  background-color: var(--secondary-background);
}

.searchBTN span.icon svg {
  max-width: 18px;
  max-height: 18px;
  min-width: 18px;
  min-height: 18px;
}

.overlayBG {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 0.7);
  z-index: -1;
  transition: opacity 0.2s;
  opacity: 0;
}

.overlayBG.active {
  display: block;
  opacity: 1;
  z-index: 97;
}
.searchBox.active {
  right: 0;
  opacity: 1;
  display: block;
}

.colSmall {
  max-width: 120px;
  min-width: 120px;
  position: relative;
}

@media (max-width: 1168px) {
  .searchBox {
    position: fixed;
    height: 100vh;
    width: 300px;
    right: -300px;
    top: 0;
    z-index: 999;
    transition: right 0.2s, opacity 0.3s;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border: unset;
    padding-top: 20px;
  }

  .col {
    border-bottom: 1px solid var(--border-color);
    border-right: unset;
    margin-top: 5px;
    padding-bottom: 5px;
  }

  .colText {
    max-width: unset;
    min-width: unset;
  }

  .colSmall {
    max-width: unset;
    min-width: unset;
  }

  .searchBTN {
    display: flex;
  }
}
