.editBtn { 
    border-radius: 50px;
    line-height: 1.25;
    font-weight: 400;
    padding: 2px 3px;
    font-size: 12px;
    color: rgb(100 116 139);
    display: flex;
    background-color: rgb(241 245 249);
    cursor: pointer;
    transition: background-color 0.2s ease, border-color 0.2s ease;
}

.editBtn:hover { 
    background-color: rgb(226 232 240);
}
 
.editBtn svg {  
    height: 16px;
    width: 16px;
    margin-right: 3px;
    color: rgb(100 116 139);
    fill: rgb(100 116 139);
    transition: color 0.2s ease ;
}


.groupInput {
    margin-bottom: 12px;
    position: relative;
}

.input::placeholder {
    color: rgb(71 85 105 / 60%);
    position: relative;
}

.groupInput .input::placeholder {
    color: rgb(71 85 105 / 60%);
    position: relative;
}

.groupInput label { 
    color: rgb(148 163 184);
    font-size: .825rem;
}

.input {  
    line-height: 1.25rem;
    color: rgb(71 85 105);
    font-size: 12px;
    padding: 0.5rem; 
    border: 1px solid rgb(203 213 225);
    border-radius: 5px;
    width: 100%;
    height: 35px;
    background-color: rgb(255 255 255); 
    outline-color: transparent;
    outline-offset: 2px;
    outline-style: dashed;
    outline-width: 1px; 
    transition-duration: .3s; 
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}

.input:focus-within {  
    outline-color: rgb(203 213 225/1); 
}

.groupInput .groupInputIcon {
    position: relative;
}

.groupInput .groupInputIcon .input { 
    padding-inline-start: 28px;
    padding-inline-end: 10px;
}

.groupInput .groupInputIcon .crmIcon {
    position: absolute;
    top: 0;
    color: rgb(148 163 184);
    display: flex; 
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 28px;
    transition-duration: .3s; 
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}

.groupInput .groupInputIcon:focus-within .crmIcon {
    --tw-text-opacity: 1;
    color: #235e82; 
}

.groupInput .groupInputIcon .crmIcon svg { 
    display: inline-block;
    vertical-align: middle;
    height: 17px;
    width: 17px;
    color: rgb(100 116 139);
    fill: rgb(100 116 139); 
}
 
 

.inp2col { 
    display: flex;
    gap: 10px;
}

.inp2col > * {
    width: 100%;
}
 
.modalHead {
    padding: 1.5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
 
.modalHead h3 {
    display: flex;
    align-items: center;
    color: rgb(100 116 139);
    padding: 0;
    margin: 0;
    font-weight: 300;
    font-size: 1.125rem; 
    font-feature-settings: "case" on; 
}
 
.modalHead h3 span{
    height: 30px;
    width: 30px;
    border-radius: 999px;
    color:rgb(100 116 139);
    background-color: rgb(241 245 249);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}
 
.modalHead h3 span svg{
    height: 18px;
    width: 18px; 
}
 
 

.btnPrimary {
    margin-top: 20px;
    margin-left: auto;
    display: block;
    text-align: center;
    padding:  7px 20px;
    cursor: pointer;
    line-height: 1.25rem;
    color: #fff;
    font-size: .875rem; 
    border: none;
    border-radius: 0.3rem;
    width: 100%;
    max-width: 200px;
    /* height: 2.5rem; */ 
    font-feature-settings: "case" on;
    background-color: rgb(34 95 130);
    transition-duration: .3s;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}

  
.errorInput {
    border-color: rgb(225, 29, 72); 
}

.errorMsg {
    color: rgb(225, 29, 72); 
    font-size: 10px;
    position: absolute;
    bottom: -3px;
    background: #fff;
    right: 11px;
    line-height: 1; 
}


 

.formCheck {
    display: block; 
    margin-left: auto;
}
  
.formCheck input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}
  
  .formCheck label {
    position: relative;
    cursor: pointer; 
    color: rgb(100 116 139);
    font-size: .75rem; 
  }
  
  .formCheck label:before {
    content:''; 
    background-color: transparent;
    border: 1px solid rgb(100 116 139);
    box-shadow: none;
    padding: 7px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 3px;
  }
  
  .formCheck input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 1px;
    left: 5px;
    width: 6px;
    height: 11px;
    border: solid rgb(100 116 139);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }