.popoverBox {
    position: absolute; 
    padding: 10px;
    border: 1px solid var(--border-color); 
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
 
  }
   
 
  .popoverTop {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 10px; 
  }
  .popoverTop::after {
    content: ''; 
    border: 7px solid transparent;
    border-bottom: none;
    border-top-color: #ffffff;
    bottom: -6px; 
    filter: drop-shadow(0 1.5px 0 var(--border-color));
    height: 0;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    transform: scaleX(-.7);
    width: 0; 
  } 


  
  .popoverBottom {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 10px;
  }

  .popoverBottom::after {
    content: ''; 
    border: 7px solid transparent;
    border-bottom: none;
    border-top-color: #ffffff;
    top: -6px; 
    filter: drop-shadow(0 1.5px 0 var(--border-color));
    height: 0;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    transform: scaleX(-0.9) rotate(180deg);
    width: 0;
  }


  
  .popoverRight {
    transform: translate(0%, 50%);
    bottom: 100%;
    left: 100%;
    margin-bottom: -10px;
    margin-left: 10px;
  }
  
  .popoverRight::after {
    content: '';
    border: 7px solid transparent;
    border-bottom: none;
    border-top-color: #ffffff;
    filter: drop-shadow(0 1.5px 0 var(--border-color));
    height: 0;
    position: absolute;
    right: unset;
    left: -9px;
    top: 0;
    bottom: 0;
    margin: auto;
    transform: scaleX(-0.9) rotate(-90deg);
    width: 0;
   }
   
  .popoverLeft {
    transform: translate(-100%, -50%);
    margin-left: -10px;
    top: 50%;
    left: 0;
  }
  
  .popoverLeft::after {
    content: '';
    border: 7px solid transparent;
    border-bottom: none;
    border-top-color: #ffffff;
    filter: drop-shadow(0 1.5px 0 var(--border-color));
    height: 0;
    position: absolute;
    left: unset;
    right: -9px;
    top: 0;
    bottom: 0;
    margin: auto;
    transform: scaleX(-0.9) rotate(90deg);
    width: 0;
  }
  

.editBtn { 
    border-radius: 5px;
    line-height: 1.25;
    font-weight: 500;
    padding: 2px 3px;
    display: flex;
    font-size: 12px;
    color: var(--text-color);
    background-color: var(--secondary-background);
    border: 1px solid var(--border-color);
    cursor: pointer;
    transition: background-color 0.2s ease, border-color 0.2s ease;
    align-items: center;
}

.editBtn:hover { 
    background-color: var(--third-background);
}
 
.editBtn svg {  
    height: 16px;
    width: 16px;
    margin-right: 3px;
    color: var(--secondary-text-color);
    fill: var(--secondary-text-color);
    transition: color 0.2s ease ;
}

.dInline {
  display: inline;
}

.dBlock {
  display: block;
}